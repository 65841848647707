export const palette = {
  jewelRed: '#C92117',
  jewelRed15: '#FCE1DF',
  jewelRed50: '#F3BABA',
  jewelRed70: '#EB352A',
  jewelRed110: '#A71108',
  jewelRed120: '#850800',

  sand: '#E8E4DA',
  sand70: '#FBF8F5',
  sand80: '#F4F2EE',
  sand90: '#EEE9E3',
  sand110: '#D1CDC1',
  sand115: '#D3CDBC',
  sand120: '#8D887B',

  shadow: '#1B1C19',
  shadow5: '#FAFAFA',
  shadow10: '#F4F4F3',
  shadow15: '#EDEDED',
  shadow20: '#DDDFDB',
  shadow25: '#D3D3D3',
  shadow30: '#C7C9C3',
  shadow40: '#9A9E92',
  shadow45: '#9C9C9C',
  shadow50: '#6C7164',
  shadow65: '#666666',
  shadow70: '#55584F',
  shadow80: '#3E4039',
  shadow90: '#333333',
  shadowGray: '#EEEEEE',

  warmGray50: '#d9d5ce',

  teal: '#5BE9DC',
  teal120: '#0B6060',

  // WARNING: do not use hydrowGreen outside of the hydrow pages.
  // if doing so, please consult with UX Design.
  hydrowGreen: '#D1EF3D',
  hydrowGreen90: '#E4F495',

  sunshine: '#F9FF0D',
  sunshine60: '#FDFFAE',

  green: '#05944F',
  yellow: '#FFC043',

  white: '#FFFFFF',
  black: '#000000',

  blue: '#5897FB',

  outletRed: '#FE001A',

  // Yitty standalone colors required for non-Yitty support
  deepBrown: '#332213',
  lightDeepBrown: '#5C4E42',
  yittyPurple: '#8031a7',

  backgroundOverlay: 'rgba(250, 250, 250, 0.75)',
  modalOverlay: 'rgba(250, 250, 250, 0.75)',

  // EqualWeb colors
  adaButtonMain: '#1c4bb6',
};

export const colorTokens = {
  accountBoxContentBorder: palette.shadow15,
  accountBoxDivider: palette.shadow15,
  accountBoxWrapper: palette.shadow15,
  accountOrderDetailsBorder: palette.shadow15,
  accountOrderDetailsLineItem: palette.shadow90,
  mobileAccountPhoneNumberDisclaimerBorder: palette.shadow25,
  mobileAccountPhoneNumberDisclaimer: palette.shadow45,
  accountPhoneNumberDisclaimer: palette.shadow70,
  activateTrialSelectWrapper: palette.shadow25,
  arrowIcon: palette.black,
  backgroundTableRow: palette.shadow25,
  backgroundTableRowLight: palette.shadow10,
  backgroundCallout: palette.shadow65,
  backgroundCalloutAlternate: palette.shadow65,
  backgroundConfirmation: palette.white,
  backgroundDark: palette.shadow,
  backgroundAccounts: palette.shadow5,
  backgroundAccountsLight: palette.shadow5,
  backgroundVision: palette.shadow5,
  backgroundCheckout: palette.shadow5,
  backgroundDarkAlternate: palette.black,
  backgroundDefault: palette.black,
  backgroundDrawer: palette.shadow5,
  backgroundLight: palette.white,
  backgroundLightAlternate: palette.shadow25,
  backgroundDisabledLight: palette.shadow15,
  backgroundEmphasized: palette.black,
  backgroundEmphasizedLight: palette.shadow10,
  backgroundDeemphasizedLight: palette.shadow15,
  backgroundEmphasizedLightAlternate: palette.shadow15,
  backgroundOverlay: 'rgba(250, 250, 250, 0.75)',
  basketPricePromoClearance: palette.jewelRed110,
  billMeNowBorder: palette.shadow25,
  billMeNowConfAmount: palette.shadow25,
  bopsCancelBtn: palette.shadow,
  bopsChangeStoreBtn: palette.shadow,
  bopsHeaderBg: palette.shadow25,
  bopsListItemCounter: palette.shadow,
  borderDark: palette.black,
  border: palette.shadow25,
  borderLine: palette.shadow15,
  backgroundModalOverlay: 'rgba(0, 0, 0, 0.4)',
  borderLight: palette.shadow25,
  borderLightAlternate: palette.shadow45,
  borderShadow: palette.shadow20,
  builderAvailablePointsBorderInactive: palette.shadow25,
  builderAvailablePointsProgressCircle: palette.shadow25,
  builderBubbleImageWithLabelBorder: palette.shadow25,
  buttonPrimaryDisabledBg: palette.gray,
  buttonSecondaryDisabledBorder: palette.shadow65,
  buttonTertiaryDisabledFg: palette.shadow45,
  buttonTertiaryDisabledBg: palette.gray,
  buttonTertiaryBg: palette.gray,
  careGuideSectionBg: palette.shadow10,
  careGuideTitle: palette.black,
  ccPaymentFormWrapper: palette.shadow25,
  checkboxDisabled: palette.shadow10,
  compressionGuideSectionBg: palette.shadow10,
  compressionGuideOverlayButtonBg: palette.shadow10,
  compressionGuideOverlaySectionBg: palette.shadow10,
  compressionGuideOverlayCarouselBg: palette.shadow10,
  defaultRegFieldBorder: palette.shadow25,
  dropdownButtonBg: palette.shadow5,
  emphasizedNavItemLabel: palette.jewelRed70,
  empTooltipBg: palette.shadow25,
  enhancedNavTabBorder: palette.shadow,
  error: palette.jewelRed70,
  fabricCardBg: palette.shadow10,
  faqAccordionBg: palette.shadow25,
  findStoreBtn: palette.shadow,
  fitGuideDefault: palette.shadow80,
  focusColor: palette.shadow65,
  ftvModalBackground: palette.white,
  genderTabBorder: palette.shadow,
  genericSelectorInputSize: palette.shadow25,
  genericSelectorInputSizeSoldOut: palette.shadow25,
  genericSelectorItemSoldOutBg: palette.shadow25,
  gridSwatchInputCheckedFocus: palette.blue,
  gridSwatchesLoadingSkeletonBorder: palette.shadow15,
  gridSwatchesLoadingSkeletonBg15: palette.shadow15,
  gridSwatchesLoadingSkeletonBg25: palette.shadow25,
  gridPrimarySwatchRadioButtonBorder: palette.shadow90,
  headNavFlyoutBackground: palette.white,
  headNavFlyoutBorder: palette.shadow15,
  headNavTabActive: palette.black,
  headNavTabInactive: palette.black,
  headNavTextDefault: palette.black,
  headNavTabActiveBackgroundMobile: palette.black,
  headNavTabActiveForegroundMobile: palette.white,
  headNavTabActiveBorderMobile: palette.black,
  headNavTabInactiveBackgroundMobile: palette.white,
  headNavTabInactiveForegroundMobile: palette.black,
  headNavTabInactiveBorderMobile: palette.shadow15,
  headNavTabContentBackgroundMobile: palette.white,
  headNavBorder: palette.shadow25,
  iconColor: palette.shadow,
  iconColorAlternate: palette.shadow65,
  iconColorCartCount: palette.jewelRed,
  iconColorDeemphasized: palette.shadow15,
  iconColorRemoveFromCart: palette.shadow45,
  iconColorWishlist: palette.jewelRed,
  idMeVerifiedIcon: palette.shadow5,
  initiateReturnContainerBorder: palette.shadow25,
  learnMoreLink: palette.shadow,
  linkText: palette.shadow65,
  linkTextAlternate: palette.shadow65,
  linkTextDeemphasized: palette.shadow45,
  linkTextDeemphasizedAlternate: palette.shadow5,
  liveChatSurveyBorder: palette.shadow25,
  loyaltyActionCta: palette.sunshine,
  loyaltyActionCardBadge: palette.sunshine,
  loyaltyActionCardPoints: palette.sunshine,
  loyaltyActionCardBorder: palette.sand120,
  loyaltyHomepageActionCardBorder: palette.sand120,
  loyaltyLegacyBonusCardBorder: palette.sand120,
  loyaltyLegacyActionCardBadge: palette.sunshine,
  loyaltyLegacyActionCardPoints: palette.sunshine,
  loyaltyRewardProgressPillBackground: palette.sunshine,
  loyaltyProgressTrackerPillBackground: palette.sunshine,
  loyaltyActivePrimaryButton: palette.sunshine,
  loyaltyDisablePrimaryButton: palette.sunshine60,
  loyaltyTierBadgeBg: '#1B1C19',
  loyaltyTierBadgeBorder: '#918E8C',
  loyaltyTierBadgeCompletedBorder: palette.shadow80,
  loyaltyVideoBannerSubtitle: palette.shadow80,
  loyaltyTooltipBg: palette.shadow25,
  loyaltyHomepageActionActionCardBorder: palette.sand,
  loyaltyHomepageActionActionCardCompletedBorder: palette.shadow80,
  loyaltyLegacyActionCardBg: palette.sand,
  loyaltyLegacyActionCardCompletedBg: palette.shadow80,
  loyaltyLegacyActionCardTitle: palette.shadow80,
  loyaltyRewardProgressTrackerBarBg: palette.sand,
  loyaltyProgressTrackerBarCompletedBg: palette.shadow80,
  loyaltyRewardProgressTrackerBarCompletedBg: palette.shadow80,
  loyaltyActionVideoWrapperBackground: palette.shadow25,
  loyaltyProgressTrackerBarBg: palette.shadow25,
  loyaltyProgressTrackerNotation: palette.shadow80,
  loyaltyActionProgressCollapsibleIcon: palette.shadow50,
  loyaltyActionCardBadgeBorder: palette.shadow80,
  loyaltyActionCardTitle: palette.shadow80,
  loyaltyActionCardSubtitle: palette.shadow80,
  loyaltyActionCardSelectedBackground: palette.shadow90,
  memberBoxBorder: palette.shadow20,
  memberBoxBoxShadow: palette.shadowGray,
  memberCreditsM1Bg: palette.shadow10,
  memberInfoBackground: palette.sand70,
  memberInfoBorder: palette.sand90,
  memberInfoGreetingBorder: palette.shadow65,
  memberInfoLeadBackground: palette.sand70,
  memberInfoTrialDays: palette.shadow65,
  membershipBalanceWrapper: palette.shadow15,
  membershipBalanceAmountDivider: palette.shadow15,
  membershipBalanceTooltipBorder: palette.shadow15,
  membershipBalanceTooltipShadow: palette.shadow25,
  membershipContactChat: 'transparent',
  membershipCommunicationPreference: palette.shadow65,
  membershipContactChatText: palette.white,
  membershipContactPhone: palette.white,
  membershipContactPhoneText: palette.black,
  membershipContactTextActive: palette.black,
  membershipContactBoxBg: palette.shadow10,
  membershipOptInBorder: palette.shadow25,
  membershipOptInRadioButtonBlockSelectedBg: palette.sand,
  membershipTierElite: palette.jewelRed,
  membershipTierVIP: palette.shadow65,
  membershipFreeTrial: palette.jewelRed,
  metaNavBorder: '#EAEAEA',
  metaNavText: palette.shadow,
  metaNavListItemBg: palette.shadow,
  metaNavTextDeemphasized: palette.shadow45,
  metaNavResources: '#858583',
  mobileMetaNavListItemBorder: palette.sand90,
  metaNavListItemBorder: palette.shadow15,
  mobileMetaNavListItemBg: palette.shadow90,
  miniCartBorder: palette.shadow15,
  miniCartBundle: palette.shadow25,
  miniCartFocus: palette.shadow5,
  modialRelaySelectorClosestPointBorder: palette.shadow25,
  modialRelaySelectorClosestPointBg: palette.shadow25,
  modialRelayClosestPointBg: palette.sand,
  navMemberPanelStatusIsVip: palette.sunshine,
  navMemberPanelStatusNotVip: palette.shadow,
  offerButtonBg: palette.shadow,
  outfitDetailsLabel: palette.black,
  paymentOptionBorder: palette.shadow25,
  pdpBopsStoreFinderBg: palette.shadow25,
  pdpCalloutBgBrand: palette.purple,
  pdpCalloutYittyCollection: palette.yittyPurple,
  pdpCalloutBg: palette.black,
  pdpCalloutText: palette.white,
  pdpColorInputCheckedFocus: palette.blue,
  pdpColorSelectorInputBorder: palette.shadow25,
  pdpExtendedSizeCalloutBg: palette.shadow5,
  pdpOutfitSectionBg: palette.shadow5,
  pdpQuantitySelectorBorder: palette.shadow25,
  pdpShippingInfo: palette.shadow,
  philanthropyBorder: palette.shadow25,
  platinumExclusiveBackground: palette.shadow,
  postRegTime: palette.shadow25,
  postRegTimeWrapperBorder: palette.shadow25,
  preOrderCalloutBackground: palette.shadow,
  preorderRadioBorder: palette.shadow25,
  preorderRadioButtonBlockSelectedBg: palette.sand,
  promoText: palette.jewelRed,
  questionMarkToolTipShadow: palette.shadow25,
  radioButtonDisabled: palette.shadow10,
  returnInStoreBox: palette.shadow5,
  reviewBrowserSubmit: palette.shadow25,
  reviewsColorWrapper: palette.shadow5,
  reviewsDrawerFilterCount: palette.shadow25,
  saleText: palette.jewelRed,
  scrubsGroupOrdersLabel: palette.shadow25,
  scrubsGroupOrdersRadio: palette.shadow25,
  searchFieldWrapperBorder: palette.shadow15,
  searchPaneFieldContentColor: palette.shadow90,
  selectedFilterColor: palette.jewelRed70,
  selectBorder: palette.shadow25,
  quizAnswerBackgroundLight: palette.shadow15,
  quizAnswerBackgroundDark: palette.shadow15,
  quizAnswerBackgroundHover: palette.shadow15,
  quizAnswerColorHover: palette.black,
  quizButtonBackground: palette.black,
  quizDropdownBorder: palette.shadow20,
  quizDropdownOptionBackground: palette.shadow10,
  quizEdit: palette.black,
  quizModalBackground: palette.white,
  quizSizingBorder: palette.shadow20,
  sizeSelectionSizingBorder: palette.shadow20,
  quizTextDark: palette.black,
  sizeSelectionDark: palette.black,
  quizTextLight: palette.shadow65,
  scarcityCallout: palette.jewelRed,
  separator: palette.shadow15,
  success: palette.green,
  searchInputClearBtn: palette.shadow10,
  searchInputField: palette.shadow10,
  sectionNodeFontColor: palette.shadow,
  shopNavPanelBorder: palette.shadow15,
  shopNavLinkBorder: palette.shadow15,
  skipToolTipBorder: palette.shadow15,
  skipToolTipShadow: palette.shadow25,
  mobileSkinnyBannerTermsButtonText: palette.white,
  sortDropdownBtnListBorder: palette.shadow15,
  sortDropdownListBorder: palette.shadow15,
  sortDropdownListItemBgHover: palette.sand80,
  speedySignupFormDropdownBg: palette.shadow10,
  stickyBannerBackground: palette.white,
  stickyCountdownTrackBar: palette.teal,
  stillHaveQuestionsFormWrapperBg: palette.shadow5,
  stillHaveQuestionsWrapperDisabledBg: palette.sand,
  skipTheMonthProfileHeaderBg: palette.sand,
  skipTheMonthHeadingWrapperBg: palette.sand70,
  skipTheMonthKhloeProfileHeaderBg: palette.sand70,
  skipTheMonthKhloeCtaBg: palette.sand70,
  storeLocationButtonWrapper: palette.shadow5,
  storeLocationDetailsWrapper: palette.shadow5,
  storeLocationRetailStoreHours: palette.shadow5,
  tabBorder: palette.black,
  tabBackground: palette.shadow25,
  tabSelected: palette.black,
  tabSelectedText: palette.white,
  tabDeselected: palette.white,
  tabDeselectedText: palette.black,
  taxShippingMessage: palette.shadow,
  textActionDeemphasized: palette.shadow,
  textConfirmation: palette.shadow65,
  textDeemphasized: palette.shadow45,
  textDeemphasizedAlternate: palette.shadow65,
  textDefault: palette.black,
  textFaded: palette.black,
  textEmphasized: palette.shadow,
  textEmphasizedAlternate: palette.shadow,
  textFadedAlternate: palette.shadow65,
  textHighlighted: palette.black,
  textHighlightedAlternate: palette.shadow,
  textLight: palette.white,
  textLightAlternate: palette.shadow65,
  textValue: palette.shadow65,
  thredUpBackground: '#eff0f2',
  toggleContainerBg: palette.shadow25,
  toggleEnabledBackground: palette.shadow15,
  toggleEnabledForeground: palette.shadow65,
  tooltipBorder: palette.shadow,
  topBarWrapperBorder: palette.shadow15,
  trialMembershipBoxBorder: palette.shadow25,
  referrerOptionDropdownFocus: palette.shadow20,
  ugcNodeWrapperBg: palette.shadow5,
  unsupportedMessageBg: palette.shadow,
  verifiedIconBg: palette.shadow,
  vipBadge: palette.shadow65,
  vipMembershipProgramWrapper: palette.teal120,
  warning: palette.yellow,
  styledHtmlAssetAnchor: palette.shadow50,
  inactivePaginator: palette.shadow50,
  accountPageMenuMobile: palette.shadow50,
  selectorText: palette.shadow50,
  returnConfirmationShipmentNote: palette.shadow50,
  initiateReturnOutfitLabel: palette.shadow50,
  leadMembershipAmount: palette.shadow50,
  idMeWhatIsAnchor: palette.shadow50,
  serviceDiscountTermsButton: palette.shadow50,
  navigationMoveLeftIcon: palette.shadow50,
  reviewsCount: palette.shadow50,
  productDetailCtaModalInfo: palette.shadow50,
  empAddToBagText: palette.shadow50,
  preorderCtaShippingInfo: palette.shadow50,
  productDetailProgressBar: palette.shadow50,
  progressBar: palette.shadow50,
  drawerReviewsFilters: palette.shadow50,
  drawerStyleResultCount: palette.shadow50,
  speedySignupReferrerDropdownLabel: palette.shadow50,
  empUpsellPrice: palette.shadow50,
  waitlistModalMessage: palette.shadow50,
  passwordFieldButton: palette.shadow50,
  upsellCarouselMessage: palette.shadow50,
  dropdownButton: palette.shadow50,
  dropdownListItem: palette.shadow50,
  compressionOverlayStickyHeader: palette.shadow50,
  compressionOverlayStickySubheader: palette.shadow50,
  fabricGuideHeader: palette.shadow50,
  womenFitGuideDisclosureText: palette.shadow50,
  checkboxInputBorder: palette.shadow50,
  radioButtonInputBorder: palette.shadow50,
  reviewsContentWrapper: palette.shadow15,
  offerSnoozeAcceptance: palette.shadow15,
  orderConfirmationCarousel: palette.shadow15,
  productListingFilter: palette.shadow15,
  outfitItemWrapper: palette.shadow15,
  outfitItemActions: palette.shadow15,
  outfitItemImage: palette.shadow15,
  productDetailSeparator: palette.shadow15,
  productDetailsOutfitMessage: palette.shadow15,
  productDetailsDrawerButtonBorder: palette.shadow15,
  mobileQuickViewHeader: palette.shadow15,
  mobileTopShadow: palette.shadow15,
  quickViewOutfitBorder: palette.shadow15,
  upsellCarouselBorder: palette.shadow15,
  wearItWithItemBorder: palette.shadow15,
  storeLocationDetailsSeparator: palette.shadow15,
  sizeGridTabBorder: palette.shadow15,
  womenFabricChartSeparator: palette.shadow15,
  careGuideSectionBorder: palette.shadow15,
  mobileHowItWorksMemberCredits: palette.shadow15,
  recommendedReviewBorder: palette.shadow15,
  recentReviewsBorder: palette.shadow15,
  recentReviewsWrapperBoxShadow: palette.shadowGray,
  accountOrderDetails: palette.shadow15,
  accountOrderDetailsContentBorder: palette.shadow15,
  accountOrderDetailsShippingInfoBorder: palette.shadow15,
  addressConfirmationRadioButtons: palette.shadow15,
  addressSubFormLabelBorder: palette.shadow15,
  addressListBorder: palette.shadow15,
  basketItemBorder: palette.shadow15,
  cardSubformLabel: palette.shadow15,
  cardFormGridFieldsetBorder: palette.shadow15,
  ccpaConfirmBackground: palette.shadow15,
  ccpaConfirmationNumberBorder: palette.shadow15,
  initiateReturnConfirmationCardBorder: palette.shadow15,
  productSizeSelectorInputBorder: palette.shadow15,
  productSizeSelectorSoldOutBackground: palette.shadow15,
  initiateReturnRmaCardBorder: palette.shadow15,
  initiateReturnRmaCardSpacer: palette.shadow15,
  initiateReturnInstructionsPanelBorder: palette.shadow15,
  initiateReturnConfirmationBorder: palette.shadow15,
  initiateReturnConfirmationItemCardTopBorder: palette.shadow15,
  initiateReturnHappyReturnCardBorder: palette.shadow15,
  initiateReturnHappyReturnCardBorderSelected: palette.black,
  initiateReturnPrintLabelCardBorder: palette.shadow15,
  initiateReturnSelectBorder: palette.shadow15,
  initiateReturnCancelButton: palette.shadow15,
  initiateReturnBackground: palette.shadow15,
  leadMembershipInfoBorder: palette.shadow15,
  membershipCreditsTableBorder: palette.shadow15,
  membershipCreditsRowBorder: palette.shadow15,
  vipPerksCirle: palette.sunshine,
  myVipPerksBorder: palette.shadow15,
  myVipListBackground: palette.shadow15,
  myVipContactBorder: palette.shadow15,
  myVipContactQuestionsBorder: palette.shadow15,
  myVipContactVariantBorder: palette.shadow15,
  myVipContactVariantQuestionsBorder: palette.shadow15,
  carouselProgressIndicatorBackground: palette.shadow15,
  dropdownListBorder: palette.shadow15,
  dropdownButtonBorder: palette.shadow15,
  dropdownListItemBorder: palette.shadow15,
  compressionGuideBreakBorder: palette.shadow15,
  referrerOptionDropdownButtonBackground: palette.shadow15,
  speedySignupDropdownListBorder: palette.shadow15,
  closeButtonBorder: palette.shadow15,
  bottomNavPanelBorder: palette.shadow15,
  searchFieldBorder: palette.shadow15,
  flytHeadStickyBorder: palette.shadow15,
  headNavMiniCartArrowBorder: palette.shadow15,
  headNavAccountMenuBorder: palette.shadow15,
  orderSummaryTotalsContent: palette.shadow15,
  orderSummaryTotalsBorder: palette.shadow15,
  orderSummaryBorder: palette.shadow15,
  orderSummaryInfoLabelBorder: palette.shadow90,
  orderSummaryDetailItemLabel: palette.shadow90,
  paymentMethodListItem: palette.shadow15,
  pointsHistoryTableBorder: palette.shadow15,
  pointsHistoryTableRowBorder: palette.shadow15,
  profileFormBorder: palette.shadow15,
  perksListBorder: palette.shadow15,
  sizePreferencesListItemBorder: palette.shadow15,
  swatchInputBorder: palette.shadow15,
  enhancedNavButtonGroupBorder: palette.shadow15,
  enhancedNavListBorder: palette.shadow15,

  howItWorksBackground: palette.shadow30,
  howItWorksMemberCredits: palette.shadow30,
  howItWorksBenefitsBackground: palette.shadow30,
  howItWorksFeaturedGridBackground: palette.shadow30,
  howItWorksFaqBackground: palette.shadow30,
  loginAssetBorder: palette.shadow30,
  storeLocationsLocatorBackground: palette.shadow30,
  noResultsHorizontalRuleBorder: palette.shadow30,
  basketItemImageBackground: palette.shadow30,
  basketItemProductImageBackground: palette.shadow30,
  billMeNowPaymentFormBackground: palette.shadow30,
  billMeNowChangePaymentBackground: palette.shadow30,
  billMeNowDialogBackground: palette.shadow30,
  returnConfirmationBackground: palette.shadow30,
  returnConfirmationBtnBackground: palette.shadow65,
  initiateReturnInStoreBorder: palette.shadow30,
  fitAppMindfulnessBackground: palette.shadow30,
  faqAccordionButton: palette.shadow30,
  marketingCarouselBackground: palette.shadow30,
  searchFieldBackground: palette.sand70,
  searchSuggestIsHighlightedBackground: palette.sand80,
  scrubsSizeMessageBorder: palette.shadow30,
  quizDropdownOptionBackgroundMobile: palette.shadow30,
  quizDropdownParentHoverColor: palette.black,
  embroideryProgressBarBackground: palette.shadow30,
  embroideryTextSelectionPreviewContainerBg: palette.shadow25,
  memberBoxBottomInfoBackground: palette.shadow30,
  haveQuestionsCancelBorder: palette.shadow30,
  reviewBrowserSubmitDisabled: palette.shadow30,
  skipTheMonthBorder: palette.shadow30,
  postregSelectorDivider: palette.shadow30,
  postregOfferBorder: palette.shadow30,
  checkoutHeaderBorder: palette.shadow30,
  favoritesPlaceholderImageBackground: palette.shadow30,
  styleGuideAssetWrapper: palette.shadow30,
  enhancedNavMenuScrollbarBackground: palette.shadow30,
  aboutLayoutCollectionsBackground: palette.shadow30,
  disabledDropdownButtonBackground: palette.shadow30,
  dropdownListItemBackground: palette.shadow30,
  inputCheckedColor: palette.shadow30,
  sortActiveSelection: palette.shadow90,
  searchSubmitButtonText: palette.shadow,
  returnInStoreViewMore: palette.shadow65,
  skipTheMonthConfSubHeader: palette.shadow65,
  activateTrialSelectBorder: palette.shadow65,
  footerSkinnyBannerWrapper: palette.shadow65,
  mobileSearchPanePlaceholder: palette.shadow65,
  pdpTooltipBorder: palette.shadow65,
  pdpTokenOnlyCallout: palette.shadow65,
  memberCreditCalloutToolipBorder: palette.shadow65,
  storeHoursInactive: palette.shadow65,
  fieldsetSelectBorder: palette.shadow65,
  fitAppPageBannerBg: palette.shadow65,
  storeLocationsDropdownArrow: palette.shadow65,
  storeLocationsDropdownLabel: palette.shadow65,

  orderConfirmationCarouselPromoPrice: palette.jewelRed70,
  loyaltyPrice: palette.jewelRed70,
  unavailableStockStatus: palette.jewelRed70,

  searchDivider: palette.shadow15,
  unfilledCompressionScaleBar: palette.shadow15,

  billMeNowSuccessOne: palette.shadow,
  loadingScreenBackground: palette.sand,
  errorPageBodyColor: palette.shadow,

  addPaymentIcon: palette.shadow45,
  placeholderImageBackground: palette.shadow45,
  soldOutSelectorItemSizeInput: palette.shadow45,
  soldOutSelectorItemSizeLabel: palette.shadow45,

  builderComparisonTableRowBackground: palette.shadow10,
  builderComparisonStyledDropdownBackground: palette.shadow10,
  storeLocationsMobileRegionLabel: palette.shadow10,

  headNavIcon: palette.shadow80,
  forgotPasswordControl: palette.shadow80,
  compressionGuideInfo: palette.shadow80,

  orderHistoryDetailsLink: palette.black,
  returnMessage: palette.black,
  ordersDataValue: palette.black,
  orderHistoryItemDetails: palette.black,
  onlineCancelFinalHeader: palette.black,
  onlineCancelFinalButton: palette.black,
  loyaltyLockIcon: palette.black,
  loyaltyActionCardIconColor: palette.shadow,
  loyaltyActionIconColor: palette.shadow,
  loyaltyActionProgressHeading: palette.shadow,
  loyaltyDeprecatedHomepageRewardsProgressHeading: palette.shadow,
  loyaltyDeprecatedRewardsProgressHeading: palette.shadow,
  loyaltyVideoBannerTitle: palette.shadow,
  rewardPromoCardTitle: palette.black,
  afterpayPlacement: palette.black,
  sizeChartBackgroundImage: palette.black,
  fitAppTextCopy: palette.black,
  sizeGridSizeTab: palette.black,
  defaultButtonOnHover: palette.black,

  backButton: palette.shadow50,
  liveChatCharactersRemaining: palette.shadow50,
  memberBoxBottomInfoClose: palette.shadow50,
  myVipContactCancelContactMethod: palette.shadow50,
  myVipContactAnytime: palette.shadow50,
  inputClearButton: palette.shadow50,
  inputShadow: palette.shadow50,
  speedySignupTermsWrapper: palette.shadow50,
  fitGuideLink: palette.shadow50,
  fitGuideExclamationIcon: palette.shadow50,
  fabricCardAssetPlaceholder: palette.shadow50,
  hydrowTestimonialHeading: palette.shadow50,
  hydrowTestimonialText: palette.shadow50,
  inputSizeSelectorSoldOut: palette.shadow50,
  swatchTagLabel: palette.shadow70,

  desktopProductListingFilter: palette.shadow15,
  swatchesMoreColorsLink: palette.shadow25,
  inactiveTextInput: palette.shadow45,
  flickityPageDots: palette.shadow45,
  quizDropdownLabel: palette.shadow45,
  quizDropdownOptionBg: palette.sand70,
  hdyhQuizDropdownNativeSelectBorder: palette.shadow25,
  birthdaySpan: palette.shadow45,
  zipCodeInputColor: palette.shadow45,
  registrationFieldsLabel: palette.shadow45,
  disabledSecondaryButton: palette.shadow45,
  textDisabled: palette.shadow50,
  priceOfferOr: palette.shadow70,

  alertRed: palette.jewelRed,
  unfocusedBackground: palette.shadow30,
  sublabelBlack: palette.shadow80,
  focusText: palette.shadow90,
  focusBackground: palette.shadow,
  focusBorder: palette.shadow20,
  unfocusedLabel: palette.shadow45,
  unfocusedSublabel: palette.shadow45,
  unfocusedAction: palette.shadow50,
  unfocusedBorder: palette.shadow25,
  demureBorder: palette.shadow15,
  mobileSkinnyBannerTermsButton: palette.shadow,
  membershipStatusBadge: palette.shadow,
  wearItWithTermsButton: palette.shadow,
  membershipBalancesTagValue: palette.shadow,
  productReviewCount: palette.shadow50,
  addressListButtonDisabled: palette.shadow50,
  promoPriceStrikethrough: palette.shadow50,
  activeBreadcrumb: palette.shadow,
  accountOrdersDataLabel: palette.shadow90,
  accountOrdersDataValue: palette.shadow90,
  doNotCancelVipLink: palette.black,
  dropdownMenuButton: palette.sand,
  dropdownMenuButtonBorder: palette.shadow25,
  canScrollGradient: palette.black,

  actionDisabled: palette.shadow65,
  actionEnabled: palette.black,
  fadedBackground: palette.sand,
  registrationPasswordField: palette.shadow80,
  passwordFieldFieldsetLabel: palette.shadow80,
  textFieldColor: palette.shadow80,
  textFieldFieldsetLabel: palette.shadow80,
  tableData: palette.shadow80,
  closeIcon: palette.shadow80,
  inactiveItem: palette.shadow45,
  navMemberSkip: palette.shadow45,
  closeButton: palette.shadow,
  loadingLineMessage: palette.shadow45,
  questionMarkTooltipAnchor: palette.shadow45,
  deselectFilterButton: palette.shadow45,
  builderSubtitle: palette.shadow45,
  shopNavBottomPanel: palette.shadow45,
  errorMessage: palette.jewelRed70,
  alertWarning: palette.yellow,
  returnRmaCartTitle: palette.jewelRed110,
  myVipContactQuestions: palette.sand,
  additionalText: palette.shadow70,
  faintBorder: palette.shadow15,
  faintBackground: palette.shadow15,

  filterPillUnselected: palette.sand,
  shoppableVideoProgressBackground: palette.shadow80,
  shoppableVideoDarkGray: palette.shadow65,
  shoppableVideoLightGray: palette.shadow45,
  shoppableVideoSilver: palette.shadow25,
  shoppableVideoFeaturedText: palette.shadow90,
  shoppableVideoFeaturedButton: palette.shadow,
  shoppableVideoLoaderBg: palette.shadow15,
  shoppableVideoCarouselTitle: palette.shadow,
  shoppableVideoTooltipBg: palette.sunshine,
  shoppableVideoSkeletonGradient20: '#9C9C9C33',
  shoppableVideoSkeletonGradient30: '#9C9C9C4D',
  shoppableVideoProductInfoOverlay: '#FFFFFFE6',
  shoppableVideoShadowShim0: '#00000000',
  shoppableVideoShadowShim16: '#0000002A',
  shoppableVideoShadowShim70: '#000000B3',
  shoppableVideoShadowShim90: '#000000E5',
  shoppableVideoProductCardOverlay: '#FFFFFF80',
  shoppableVideoCarouselDescription: palette.shadow,
  shoppableVideoCarouselFullWidthWrapperBg: palette.shadow10,
  shoppableVideoAuthorProfileImageBg: palette.shadow25,
  shoppableVideoIntroVideoTitle: palette.shadow90,
  shoppableVideoIntroVideoDescription: palette.shadow90,

  bouncebackEndowmentMiniCartPrompt: palette.jewelRed,
  bouncebackEndowmentHighlight: palette.jewelRed,

  promoPickerModalBackgroundTop: palette.sand115,
  promoPickerModalBackgroundBottom: palette.white,
  promoPickerGiftBoxColor: palette.sunshine,
  signupFormSmsDisclosure: palette.shadow65,
  promoPickerFormPanelsLink: palette.shadow70,

  outletSplashSubmitButtonFocus: palette.jewelRed120,
  outletSplashPrimaryColor: palette.outletRed,
  outletDisabledSplashSubmitButtonBg: palette.sand120,

  errorPageBackgroundColor: palette.sand,

  tieredListDropdownParentOptionBg: palette.sand70,

  searchCioSkeletonGradient15: palette.shadow15,
  searchCioSkeletonGradient25: palette.shadow25,
  searchCioBarLayoutBorder: palette.shadow20,
  searchCioOverlayNoResultsFoundTitle: palette.shadow70,
  searchCioDesktopInputPlaceholder: palette.shadow90,

  hamburgerMenuHeaderBannerBorder: palette.shadow15,

  pinVerificationLoginSectionHoverBg: palette.shadow20,
  pinVerificationFormDigitInputBorder: palette.shadow25,
  pinVerificationFormResendCodeText: palette.shadow45,
  pinVerificationLoginSectionBorder: palette.shadow25,
  pinVerificationMethodIcon: palette.shadow45,

  countryDropdownMenuBorder: palette.warmGray50,

  sendLinkToPhoneNumberInputBorder: palette.shadow25,
  sendLinkToPhoneNumberInputPlaceholder: palette.shadow90,

  sustainabilityCardWrapperBorder: palette.shadow25,

  loginAuthForgotPasswordControlButton: palette.gunmetal40,

  klarnaCreditPromotion: palette.shadow50,
};
