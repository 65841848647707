import { darken } from 'polished';

export const palette = {
  darkCherry: '#CA2525',
  sunsetBlush: '#FF8674',
  lightSunset: '#FF9E90',
  lighterSunset: '#FFB6AC',
  lightestSunset: '#FFCFC7',
  darkPurple: '#560973',
  purple: '#8031a7',
  lightPurple: '#995AB9',
  lighterPurple: '#B383CA',
  lightestPurple: '#CCADDC',
  featherLightPurple: '#E6D6ED',
  verticalGradient: 'linear-gradient(180deg, #8031A7 0%, #FF8674 100%)',
  horizontalGradientLight:
    'linear-gradient(127.87deg, #C39AD2 -1.53%, #E6B2C3 65.76%, #FABFBB 103.72%)',
  horizontalGradient:
    'linear-gradient(127.87deg, #8031A7 -1.53%, #FF8674 103.72%)',
  red: '#FA2B2B',
  brick: '#9D2B2B',

  // functional colors
  silver: '#C4C4C4',
  trueBlack: '#000000',
  black: '#201C1A',
  lightBlack: '#4D4948',
  lighterBlack: '#797776',
  lightestBlack: '#A6A4A3',
  featherLightBlack: '#E9E9E9',
  lightestMidnight: '#EFF0F0',
  deepBrown: '#332213',
  lightDeepBrown: '#5C4E42',
  lighterDeepBrown: '#857A71',
  lightestDeepBrown: '#ADA7A1',
  nakedRose: '#A4867C',
  lightNakedRose: '#B69E96',
  lighterNakedRose: '#C8B6B0',
  lightestNakedRose: '#DBCFCB',
  softSilk: '#D0C3BF',
  lightSoftSilk: '#D9CFCC',
  lighterSoftSilk: '#E3DBD9',
  lightestSoftSilk: '#ECE7E5',
  lightMineral: '#9D9D9C',
  gray: '#EAEAEA',
  mineral: '#858583',
  darkGray: '#717171',
  lightGrayAlt: '#565656',
  lighterGray: '#D2D2D1',
  white: '#FFFFFF',
  burntOrange: '#E04F39',
  green: '#05944F',
  // FL colors for meta nav
  darkMidnight: '#333333',
  midnight: '#666666',
  lightMidnight: '#9C9C9C',
  lighterMidnight: '#D3D3D3',
  featherLightMidnight: '#F3F3F3',
  hydrowGreen: '#D1EF3D',
  hydrowGreen90: '#E4F495',
  warmGray50: '#d9d5ce',

  brown35: '#E8DEDE',

  // unified colors from fabletics
  sand70: '#FBF8F5',
  sand80: '#F4F2EE',
  sand90: '#EEE9E3',
  sand110: '#D1CDC1',
  sand120: '#8D887B',

  shadow: '#1B1C19',
  shadow5: '#FAFAFA',
  shadow10: '#F4F4F3',
  shadow15: '#EDEDED',
  shadow20: '#DDDFDB',
  shadow25: '#D3D3D3',
  shadow30: '#C7C9C3',
  shadow40: '#9A9E92',
  shadow45: '#9C9C9C',
  shadow50: '#6C7164',
  shadow65: '#666666',
  shadow70: '#55584F',
  shadow80: '#3E4039',
  shadow90: '#333333',

  // legacy deprecated colors
  ocean: '#2D9AAC',
  salmon: '#F9423A',
  sea: '#147891',
  sun: '#FA6432',
  paleTurquoise: '#AFEEEE',
  lightOffWhite: '#f2f2f2',
  coral: '#f06b6a',
  focusColor: 'rgba(50, 120, 230, 0.8)',
  offWhite: '#E8E8E8',
  faintGray: '#e5e5e5',
  lightGray4: '#F8F8F8',
  doveGray: '#fafafa',
  sand: '#FAFAFA',
  charcoal: '#2c2d2e',
  lightestBurntOrange: '#F3B9B0',
  lightYellow: '#CC9933',
  sunset: '#FF906D',
  lightGray2: '#eaeaea',
  lightGray5: '#565656',
  darkGray2: '#595A5A',
  mediumGray: '#999999',
  mediumGray2: '#959595',
  errorRed: '#d0021b',
  brightPink: '#ec5a80',
  lightPink: '#fde0dc',
  promoPrice: '#2D9AA7',
  textDisabled: '#888888',
  darkness: '#222222',
  darkenGray: '#858583',
  shadowGray: '#eeeeee',
  lightGray: '#BFBFBF',

  // EqualWeb colors
  adaButtonMain: '#1c4bb6',
};

export const colorTokens = {
  accountBoxContentBorder: palette.offWhite,
  accountBoxDivider: palette.offWhite,
  accountBoxWrapper: palette.offWhite,
  accountOrderDetailsBorder: palette.gray,
  accountOrderDetailsLineItem: palette.black,
  mobileAccountPhoneNumberDisclaimerBorder: palette.shadow25,
  mobileAccountPhoneNumberDisclaimer: palette.shadow45,
  accountPhoneNumberDisclaimer: palette.shadow70,
  activateTrialSelectWrapper: palette.gray,
  arrowIcon: palette.lighterBlack,
  backgroundTableRow: palette.gray,
  backgroundTableRowLight: palette.lightGray,
  backgroundCallout: palette.horizontalGradient,
  backgroundCalloutAlternate: palette.purple,
  backgroundConfirmation: palette.horizontalGradient,
  backgroundDark: palette.purple,
  backgroundDarkAlternate: palette.lightGray,
  backgroundDefault: palette.white,
  backgroundLight: palette.white,
  backgroundLightAlternate: palette.lighterGray,
  backgroundAccounts: palette.lightGray4,
  backgroundAccountsLight: palette.white,
  backgroundCheckout: palette.sand,
  backgroundVision: palette.lightGray,
  backgroundDisabledLight: darken(0.1, palette.lightGray),
  backgroundDrawer: palette.gray,
  backgroundEmphasized: palette.black,
  backgroundEmphasizedLight: palette.featherLightPurple,
  backgroundEmphasizedLightAlternate: palette.featherLightPurple,
  backgroundDeemphasizedLight: palette.lightGray,
  backgroundFitGuideContent: palette.white,
  backgroundOverlay: 'rgba(234, 234, 234, 0.8)',
  basketPricePromoClearance: palette.coral,
  billMeNowBorder: palette.gray,
  billMeNowConfAmount: palette.lighterGray,
  bopsCancelBtn: palette.ocean,
  bopsChangeStoreBtn: palette.ocean,
  bopsHeaderBg: palette.gray,
  bopsListItemCounter: palette.ocean,
  borderDark: palette.black,
  border: palette.gray,
  borderLine: palette.gray,
  backgroundModalOverlay: 'rgba(0, 0, 0, 0.4)',
  borderLight: palette.shadowGray,
  borderLightAlternate: palette.lightMineral,
  borderShadow: 'rgba(0,0,0,0.15)',
  builderAvailablePointsBorderInactive: palette.gray,
  builderAvailablePointsProgressCircle: palette.gray,
  builderBubbleImageWithLabelBorder: palette.gray,
  buttonPrimaryDisabledBg: palette.gray,
  buttonSecondaryDisabledBorder: palette.midnight,
  buttonTertiaryDisabledFg: palette.lightestBlack,
  buttonTertiaryDisabledBg: palette.gray,
  buttonTertiaryBg: palette.gray,
  careGuideSectionBg: palette.lightGray4,
  careGuideTitleBg: palette.black,
  ccPaymentFormBorder: palette.gray,
  checkboxDisabled: palette.lightOffWhite,
  compressionGuideSectionBg: palette.lightGray4,
  compressionGuideOverlayButtonBg: palette.lightGray4,
  compressionGuideOverlaySectionBg: palette.lightGray4,
  compressionGuideOverlayCarouselBg: palette.lightGray4,
  dropdownButtonBg: palette.doveGray,
  defaultRegFieldBorder: palette.gray,
  emphasizedNavItemLabel: palette.brick,
  empTooltipBg: palette.gray,
  enhancedNavTabBorder: palette.ocean,
  error: palette.red,
  faqAccordionBg: palette.gray,
  faqAccordionBorder: palette.gray,
  fabricCardBg: palette.lightGray4,
  findStoreBtn: palette.ocean,
  fitGuideDefault: palette.lightBlack,
  focusColor: palette.purple,
  ftvModalBackground: palette.white,
  genderTabBorder: palette.ocean,
  genericSelectorInputSize: palette.gray,
  genericSelectorInputSizeSoldOut: palette.gray,
  genericSelectorItemSoldOutBg: palette.gray,
  gridSwatchInputCheckedFocus: palette.focusColor,
  gridSwatchesLoadingSkeletonBorder: palette.shadow15,
  gridSwatchesLoadingSkeletonBg15: palette.shadow15,
  gridSwatchesLoadingSkeletonBg25: palette.shadow25,
  gridPrimarySwatchRadioButtonBorder: palette.shadow90,
  headNavFlyoutBackground: palette.white,
  headNavFlyoutBorder: palette.lightestMidnight,
  headNavTabActive: palette.purple,
  headNavTabInactive: palette.black,
  headNavTextDefault: palette.trueBlack,
  headNavTabActiveBackgroundMobile: palette.deepBrown,
  headNavTabActiveForegroundMobile: palette.white,
  headNavTabActiveBorderMobile: palette.deepBrown,
  headNavTabInactiveBackgroundMobile: palette.white,
  headNavTabInactiveForegroundMobile: palette.black,
  headNavTabInactiveBorderMobile: palette.lighterSoftSilk,
  headNavTabContentBackgroundMobile: palette.white,
  headNavBorder: palette.gray,
  horizontalGradient:
    'linear-gradient(127.87deg, #8031A7 -1.53%, #FF8674 103.72%)',
  horizontalGradientLight:
    'linear-gradient(127.87deg, #C39AD2 -1.53%, #E6B2C3 65.76%, #FABFBB 103.72%)',
  idMeVerified: palette.doveGray,
  iconColorAlternate: palette.burntOrange,
  iconColor: palette.purple,
  iconColorCartCount: palette.purple,
  iconColorWishlist: palette.purple,
  iconColorDeemphasized: palette.gray,
  iconColorRemoveFromCart: palette.lightestBlack,
  initiateReturnContainerBorder: palette.gray,
  learnMoreLink: palette.purple,
  linkText: palette.purple,
  linkTextAlternate: palette.burntOrange,
  linkTextDeemphasized: palette.lighterBlack,
  loyaltyActionCta: palette.purple,
  loyaltyActionCardBadge: palette.purple,
  loyaltyActionCardPoints: palette.purple,
  loyaltyActionCardBorder: palette.sand120,
  loyaltyHomepageActionCardBorder: palette.sand120,
  loyaltyLegacyActionCardBadge: palette.purple,
  loyaltyLegacyActionCardPoints: palette.purple,
  loyaltyRewardProgressPillBackground: palette.purple,
  loyaltyProgressTrackerPillBackground: palette.purple,
  loyaltyActivePrimaryButton: palette.purple,
  loyaltyDisablePrimaryButton: palette.featherLightPurple,
  loyaltyTooltipBg: palette.gray,
  loyaltyHomepageActionActionCardBorder: palette.sand,
  loyaltyHomepageActionActionCardCompletedBorder: palette.shadow80,
  loyaltyVideoBannerSubtitle: palette.shadow80,
  loyaltyLegacyActionCardBg: palette.sand,
  loyaltyLegacyActionCardCompletedBg: palette.shadow80,
  loyaltyLegacyActionCardTitle: palette.shadow80,
  loyaltyRewardProgressTrackerBarBg: palette.sand,
  loyaltyRewardProgressTrackerBarCompletedBg: palette.shadow80,
  loyaltyActionVideoWrapperBackground: palette.shadow25,
  loyaltyProgressTrackerBarBg: palette.shadow25,
  loyaltyProgressTrackerBarCompletedBg: palette.shadow80,
  loyaltyActionProgressCollapsibleIcon: palette.shadow50,
  loyaltyProgressTrackerNotation: palette.shadow80,
  loyaltyActionCardBadgeBorder: palette.shadow80,
  loyaltyActionCardTitle: palette.shadow80,
  loyaltyActionCardSubtitle: palette.shadow80,
  loyaltyActionCardSelectedBackground: palette.shadow90,
  pdpBopsStoreFinderBg: palette.gray,
  pdpCalloutYittyCollection: palette.purple,
  pdpCalloutBg: palette.purple,
  pdpCalloutText: palette.white,
  pdpColorInputCheckedFocus: palette.focusColor,
  pdpColorSelectorInputBorder: palette.gray,
  pdpExtendedSizeCalloutBg: palette.doveGray,
  pdpOutfitSectionBg: palette.doveGray,
  pdpQuantitySelectorBorder: palette.gray,
  pdpShippingInfo: palette.ocean,
  philanthropyBorder: palette.gray,
  platinumExclusiveBackground: palette.featherLightPurple,
  postRegTime: palette.gray,
  postRegTimeWrapperBorder: palette.gray,
  preOrderCalloutBackground: palette.featherLightPurple,
  preorderRadioBorder: palette.gray,
  preorderRadioButtonBlockSelectedBg: palette.sand,
  promoText: palette.purple,
  questionMarkToolTipShadow: palette.gray,
  radioButtonDisabled: palette.lightOffWhite,
  returnInStoreBox: palette.doveGray,
  reviewBrowserSubmit: palette.gray,
  reviewsColorWrapper: palette.doveGray,
  reviewsDrawerFilterCount: palette.gray,
  saleText: palette.darkCherry,
  scrubsGroupOrdersLabel: palette.gray,
  scrubsGroupOrdersRadio: palette.gray,
  searchFieldWrapperBorder: palette.gray,
  searchPaneFieldContentColor: palette.black,
  selectedFilterColor: palette.purple,
  selectBorder: palette.gray,
  quizModalBackground: palette.softSilk,
  quizAnswerBackgroundLight: palette.white,
  quizAnswerBackgroundDark: palette.lighterNakedRose,
  quizAnswerBackgroundHover: palette.deepBrown,
  quizAnswerColorHover: palette.white,
  quizButtonBackground: palette.deepBrown,
  quizDropdownBorder: palette.gray,
  quizDropdownOptionBackground: palette.lightGray4,
  quizEdit: palette.purple,
  quizBorder: palette.purple,
  quizSizingBorder: palette.gray,
  sizeSelectionSizingBorder: palette.gray,
  quizTextDark: palette.deepBrown,
  sizeSelectionDark: palette.deepBrown,
  quizTextLight: palette.lighterBlack,
  separator: palette.lightGray,
  success: palette.green,
  linkTextDeemphasizedAlternate: palette.lightBlack,
  liveChatSurveyBorder: palette.gray,
  loyaltyTierBadgeBg: '#1B1C19',
  loyaltyTierBadgeBorder: '#918E8C',
  loyaltyTierBadgeCompletedBorder: palette.shadow80,
  memberBoxBorder: palette.shadow20,
  memberBoxBoxShadow: palette.shadowGray,
  memberCreditsM1Bg: palette.lightGray4,
  memberInfoBackground: palette.lightestSoftSilk,
  memberInfoBorder: palette.lightestNakedRose,
  memberInfoGreetingBorder: palette.shadow65,
  memberInfoLeadBackground: palette.deepBrown,
  memberInfoTrialDays: palette.shadow65,
  membershipBalanceWrapper: palette.faintGray,
  membershipBalanceAmountDivider: palette.faintGray,
  membershipBalanceTooltipBorder: palette.faintGray,
  membershipBalanceTooltipShadow: palette.gray,
  membershipCommunicationPreference: palette.horizontalGradient,
  membershipContactChat: palette.purple,
  membershipContactChatText: palette.white,
  membershipContactPhone: palette.purple,
  membershipContactPhoneText: palette.white,
  membershipContactTextActive: palette.black,
  membershipContactBoxBg: palette.shadow10,
  membershipOptInBorder: palette.gray,
  membershipOptInRadioButtonBlockSelectedBg: palette.sand,
  membershipTierElite: palette.horizontalGradient,
  membershipTierVIP: palette.purple,
  membershipFreeTrial: palette.purple,
  metaNavBorder: palette.gray,
  metaNavText: palette.darkMidnight,
  metaNavListItemBg: palette.shadow,
  metaNavTextDeemphasized: palette.lightMidnight,
  metaNavResources: '#858583',
  mobileMetaNavListItemBorder: palette.sand90,
  metaNavListItemBorder: palette.shadow15,
  mobileMetaNavListItemBg: palette.shadow90,
  miniCartBorder: palette.gray,
  miniCartBundle: palette.gray,
  miniCartFocus: palette.lightGray,
  modialRelaySelectorClosestPointBorder: palette.gray,
  modialRelaySelectorClosestPointBg: palette.lighterGray,
  modialRelayClosestPointBg: palette.sand,
  navMemberPanelStatusIsVip: palette.lightYellow,
  navMemberPanelStatusNotVip: palette.salmon,
  offerButtonBg: palette.sea,
  outfitDetailsLabel: palette.lighterBlack,
  paymentOptionBorder: palette.gray,
  searchInputClearBtn: palette.lightOffWhite,
  searchInputField: palette.lightOffWhite,
  scarcityCallout: palette.purple,
  sectionNodeFontColor: palette.darkMidnight,
  shopNavPanelBorder: palette.offWhite,
  shopNavLinkBorder: palette.offWhite,
  mobileSkinnyBannerTermsButtonText: palette.coral,
  skipToolTipBorder: palette.faintGray,
  skipToolTipShadow: palette.gray,
  speedySignupFormDropdownBg: palette.lightGray4,
  sortDropdownBtnListBorder: palette.faintGray,
  sortDropdownListBorder: palette.faintGray,
  sortDropdownListItemBgHover: palette.gray,
  stickyBannerBackground: palette.lightestSoftSilk,
  stickyCountdownTrackBar: palette.paleTurquoise,
  stillHaveQuestionsFormWrapperBg: palette.doveGray,
  stillHaveQuestionsWrapperDisabledBg: palette.sand,
  storeLocationDetailsWrapper: palette.doveGray,
  storeLocationButtonWrapper: palette.doveGray,
  storeLocationRetailStoreHours: palette.doveGray,
  tabBackground: palette.lightNakedRose,
  tabSelected: palette.black,
  tabSelectedText: palette.white,
  tabDeselected: palette.white,
  tabDeselectedText: palette.black,
  tabBorder: palette.black,
  textActionDeemphasized: palette.lightBlack,
  textConfirmation: palette.white,
  textDeemphasized: palette.darkGray,
  textDeemphasizedAlternate: palette.darkGray,
  textDefault: palette.black,
  textEmphasized: palette.purple,
  textEmphasizedAlternate: palette.burntOrange,
  textFaded: palette.lightBlack,
  textFadedAlternate: palette.lighterBlack,
  textHighlighted: palette.purple,
  textHighlightedAlternate: palette.black,
  textLight: palette.white,
  textLightAlternate: palette.lightGrayAlt,
  textValue: palette.black,
  thredUpBackground: '#eff0f2',
  toggleContainerBg: palette.lighterGray,
  toggleEnabledBackground: palette.lightestPurple,
  toggleEnabledForeground: palette.purple,
  tooltipBorder: palette.offWhite,
  topBarWrapperBorder: palette.faintGray,
  trialMembershipBoxBorder: palette.gray,
  referrerOptionDropdownFocus: palette.focusColor,
  ugcNodeWrapperBg: palette.doveGray,
  unsupportedMessageBg: palette.ocean,
  verticalGradient: 'linear-gradient(180deg, #8031A7 0%, #FF8674 100%)',
  verifiedIconBg: palette.ocean,
  vipBadge: palette.purple,
  vipMembershipProgramWrapper: palette.ocean,
  registrationPasswordField: palette.lightGray5,
  passwordFieldFieldsetLabel: palette.lightGray5,
  textFieldColor: palette.lightGray5,
  textFieldFieldsetLabel: palette.lightGray5,
  tableData: palette.darkGray2,
  closeIcon: palette.darkGray2,
  inactiveItem: palette.mediumGray,
  navMemberSkip: palette.mediumGray,
  closeButton: palette.mediumGray,
  loadingLineMessage: palette.mediumGray,
  questionMarkTooltipAnchor: palette.mediumGray,
  deselectFilterButton: palette.mediumGray,
  builderSubtitle: palette.mediumGray,
  shopNavBottomPanel: palette.mediumGray2,
  errorMessage: palette.errorRed,
  mobileSkinnyBannerTermsButton: palette.brightPink,
  membershipStatusBadge: palette.promoPrice,
  wearItWithTermsButton: palette.promoPrice,
  canScrollGradient: palette.trueBlack,
  membershipBalancesTagValue: palette.promoPrice,
  productReviewCount: palette.textDisabled,
  addressListButtonDisabled: palette.textDisabled,
  promoPriceStrikethrough: palette.textDisabled,
  activeBreadcrumb: palette.darkness,
  alertWarning: palette.burntOrange,
  returnRmaCartTitle: palette.burntOrange,
  myVipContactQuestions: palette.burntOrange,
  additionalText: palette.burntOrange,
  styledHtmlAssetAnchor: palette.darkenGray,
  inactivePaginator: palette.darkenGray,
  accountPageMenuMobile: palette.darkenGray,
  selectorText: palette.darkenGray,
  returnConfirmationShipmentNote: palette.darkenGray,
  initiateReturnOutfitLabel: palette.darkenGray,
  leadMembershipAmount: palette.darkenGray,
  idMeWhatIsAnchor: palette.darkenGray,
  serviceDiscountTermsButton: palette.darkenGray,
  navigationMoveLeftIcon: palette.darkenGray,
  reviewsCount: palette.darkenGray,
  productDetailCtaModalInfo: palette.darkenGray,
  empAddToBagText: palette.darkenGray,
  preorderCtaShippingInfo: palette.darkenGray,
  progressBar: palette.darkenGray,
  productDetailOtherModels: palette.darkenGray,
  drawerReviewsFilters: palette.darkenGray,
  drawerStyleResultCount: palette.darkenGray,
  speedySignupReferrerDropdownLabel: palette.darkenGray,
  empUpsellPrice: palette.darkenGray,
  waitlistModalMessage: palette.darkenGray,
  passwordFieldButton: palette.darkenGray,
  upsellCarouselMessage: palette.darkenGray,
  dropdownButton: palette.darkenGray,
  dropdownListItem: palette.darkenGray,
  compressionOverlayStickyHeader: palette.darkenGray,
  compressionOverlayStickySubheader: palette.darkenGray,
  fabricGuideHeader: palette.darkenGray,
  womenFitGuideDisclosureText: palette.darkenGray,
  checkboxInputBorder: palette.darkenGray,
  radioButtonInputBorder: palette.darkenGray,
  reviewsContentWrapper: palette.shadowGray,
  offerSnoozeAcceptance: palette.shadowGray,
  orderConfirmationCarousel: palette.shadowGray,
  productListingFilter: palette.shadowGray,
  outfitItemWrapper: palette.shadowGray,
  outfitItemActions: palette.shadowGray,
  outfitItemImage: palette.shadowGray,
  productDetailSeparator: palette.shadowGray,
  productDetailsOutfitMessage: palette.shadowGray,
  productDetailsDrawerButtonBorder: palette.shadowGray,
  mobileQuickViewHeader: palette.shadowGray,
  mobileTopShadow: palette.shadowGray,
  quickViewOutfitBorder: palette.shadowGray,
  upsellCarouselBorder: palette.shadowGray,
  wearItWithItemBorder: palette.shadowGray,
  storeLocationDetailsSeparator: palette.shadowGray,
  sizeGridTabBorder: palette.shadowGray,
  womenFabricChartSeparator: palette.shadowGray,
  swatchesMoreColorsLink: palette.lightMineral,
  inactiveTextInput: palette.lightMineral,
  flickityPageDots: palette.lightMineral,
  quizDropdownLabel: palette.lightMineral,
  quizDropdownOptionBg: palette.sand70,
  hdyhQuizDropdownNativeSelectBorder: palette.shadow25,
  birthdaySpan: palette.lightMineral,
  zipCodeInputColor: palette.lightMineral,
  registrationFieldsLabel: palette.lightMineral,
  disabledSecondaryButton: palette.lightMineral,
  priceOfferOr: palette.black,
  doNotCancelVipLink: palette.purple,
  dropdownMenuButton: palette.white,

  builderComparisonTableRowBackground: palette.featherLightMidnight,
  builderComparisonStyledDropdownBackground: palette.featherLightMidnight,
  storeLocationsMobileRegionLabel: palette.featherLightMidnight,

  headNavIcon: palette.lightBlack,
  forgotPasswordControl: palette.lightBlack,
  compressionGuideInfo: palette.lightBlack,

  orderConfirmationCarouselPromoPrice: palette.red,
  loyaltyPrice: palette.red,
  unavailableStockStatus: palette.red,

  searchDivider: palette.featherLightBlack,
  unfilledCompressionScaleBar: palette.featherLightBlack,

  billMeNowSuccessOne: palette.lightPurple,
  loadingScreenBackground: palette.softSilk,
  errorPageBodyColor: palette.black,

  addPaymentIcon: palette.lightestBlack,
  placeholderImageBackground: palette.lightestBlack,
  soldOutSelectorItemSizeInput: palette.lightestBlack,
  soldOutSelectorItemSizeLabel: palette.lightestBlack,

  orderHistoryDetailsLink: palette.trueBlack,
  returnMessage: palette.trueBlack,
  ordersDataValue: palette.trueBlack,
  orderHistoryItemDetails: palette.trueBlack,
  onlineCancelFinalHeader: palette.trueBlack,
  onlineCancelFinalButton: palette.trueBlack,
  loyaltyLockIcon: palette.trueBlack,
  loyaltyActionCardIconColor: palette.shadow,
  loyaltyActionIconColor: palette.shadow,
  loyaltyActionProgressHeading: palette.shadow,
  loyaltyDeprecatedHomepageRewardsProgressHeading: palette.shadow,
  loyaltyDeprecatedRewardsProgressHeading: palette.shadow,
  loyaltyVideoBannerTitle: palette.shadow,
  rewardPromoCardTitle: palette.trueBlack,
  afterpayPlacement: palette.trueBlack,
  sizeChartBackgroundImage: palette.trueBlack,
  fitAppTextCopy: palette.trueBlack,
  sizeGridSizeTab: palette.trueBlack,
  defaultButtonOnHover: palette.trueBlack,

  backButton: palette.lighterBlack,
  liveChatCharactersRemaining: palette.lighterBlack,
  memberBoxBottomInfoClose: palette.lighterBlack,
  myVipContactCancelContactMethod: palette.lighterBlack,
  myVipContactAnytime: palette.lighterBlack,
  inputClearButton: palette.lighterBlack,
  inputShadow: palette.lighterBlack,
  speedySignupTermsWrapper: palette.lighterBlack,
  fitGuideLink: palette.lighterBlack,
  fitGuideExclamationIcon: palette.lighterBlack,
  fabricCardAssetPlaceholder: palette.lighterBlack,
  hydrowTestimonialHeading: palette.lighterBlack,
  hydrowTestimonialText: palette.lighterBlack,
  inputSizeSelectorSoldOut: palette.lighterBlack,
  swatchTagLabel: palette.shadow70,

  howItWorksBackground: palette.lightGray,
  howItWorksMemberCredits: palette.lightGray,
  howItWorksBenefitsBackground: palette.lightGray,
  howItWorksFeaturedGridBackground: palette.lightGray,
  howItWorksFaqBackground: palette.lightGray,
  loginAssetBorder: palette.lightGray,
  storeLocationsLocatorBackground: palette.lightGray,
  noResultsHorizontalRuleBorder: palette.lightGray,
  basketItemImageBackground: palette.lightGray,
  basketItemProductImageBackground: palette.lightGray,
  billMeNowPaymentFormBackground: palette.lightGray,
  billMeNowChangePaymentBackground: palette.lightGray,
  billMeNowDialogBackground: palette.lightGray,
  returnConfirmationBackground: palette.lightGray,
  returnConfirmationBtnBackground: palette.midnight,
  initiateReturnInStoreBorder: palette.lightGray,
  fitAppMindfulnessBackground: palette.lightGray,
  faqAccordionButton: palette.lightGray,
  marketingCarouselBackground: palette.lightGray,
  searchFieldBackground: palette.lightestSoftSilk,
  searchSuggestIsHighlightedBackground: palette.featherLightPurple,
  scrubsSizeMessageBorder: palette.lightGray,
  quizDropdownOptionBackgroundMobile: palette.lightGray,
  embroideryProgressBarBackground: palette.lightGray,
  embroideryTextSelectionPreviewContainerBg: palette.shadow25,
  memberBoxBottomInfoBackground: palette.lightGray,
  haveQuestionsCancelBorder: palette.lightGray,
  reviewBrowserSubmitDisabled: palette.lightGray,
  skipTheMonthBorder: palette.lightGray,
  skipTheMonthProfileHeaderBg: palette.sand,
  skipTheMonthHeadingWrapperBg: palette.sand70,
  skipTheMonthKhloeProfileHeaderBg: palette.sand70,
  skipTheMonthKhloeCtaBg: palette.sand70,
  postregSelectorDivider: palette.lightGray,
  postregOfferBorder: palette.lightGray,
  checkoutHeaderBorder: palette.lightGray,
  favoritesPlaceholderImageBackground: palette.lightGray,
  styleGuideAssetWrapper: palette.lightGray,
  enhancedNavMenuScrollbarBackground: palette.lightGray,
  aboutLayoutCollectionsBackground: palette.lightGray,
  disabledDropdownButtonBackground: palette.lightGray,
  dropdownListItemBackground: palette.lightGray,
  inputCheckedColor: palette.lightGray,

  careGuideSectionBorder: palette.lightGray2,
  mobileHowItWorksMemberCredits: palette.lightGray2,
  recommendedReviewBorder: palette.lightGray2,
  recentReviewsBorder: palette.lightGray2,
  recentReviewsWrapperBoxShadow: palette.shadowGray,
  accountOrderDetails: palette.lightGray2,
  accountOrderDetailsContentBorder: palette.lightGray2,
  accountOrderDetailsShippingInfoBorder: palette.lightGray2,
  addressConfirmationRadioButtons: palette.lightGray2,
  addressSubFormLabelBorder: palette.lightGray2,
  addressListBorder: palette.lightGray2,
  basketItemBorder: palette.lightGray2,
  cardSubformLabel: palette.lightGray2,
  cardFormGridFieldsetBorder: palette.lightGray2,
  ccpaConfirmBackground: palette.lightGray2,
  ccpaConfirmationNumberBorder: palette.lightGray2,
  initiateReturnConfirmationCardBorder: palette.lightGray2,
  productSizeSelectorInputBorder: palette.lightGray2,
  productSizeSelectorSoldOutBackground: palette.lightGray2,
  initiateReturnRmaCardBorder: palette.lightGray2,
  initiateReturnRmaCardSpacer: palette.lightGray2,
  initiateReturnInstructionsPanelBorder: palette.lightGray2,
  initiateReturnConfirmationBorder: palette.lightGray2,
  initiateReturnConfirmationItemCardTopBorder: palette.lightGray2,
  initiateReturnHappyReturnCardBorder: palette.lightGray2,
  initiateReturnHappyReturnCardBorderSelected: palette.black,
  initiateReturnPrintLabelCardBorder: palette.lightGray2,
  initiateReturnSelectBorder: palette.lightGray2,
  initiateReturnCancelButton: palette.lightGray2,
  initiateReturnBackground: palette.lightGray2,
  leadMembershipInfoBorder: palette.lightGray2,
  membershipCreditsTableBorder: palette.lightGray2,
  membershipCreditsRowBorder: palette.lightGray2,
  vipPerksCirle: palette.purple,
  myVipPerksBorder: palette.lightGray2,
  myVipListBackground: palette.lightGray2,
  myVipContactBorder: palette.lightGray2,
  myVipContactQuestionsBorder: palette.lightGray2,
  myVipContactVariantBorder: palette.lightGray2,
  myVipContactVariantQuestionsBorder: palette.lightGray2,
  carouselProgressIndicatorBackground: palette.lightGray2,
  dropdownListBorder: palette.lightGray2,
  dropdownButtonBorder: palette.lightGray2,
  dropdownListItemBorder: palette.lightGray2,
  compressionGuideBreakBorder: palette.lightGray2,
  referrerOptionDropdownButtonBackground: palette.lightGray2,
  speedySignupDropdownListBorder: palette.lightGray2,
  closeButtonBorder: palette.lightGray2,
  bottomNavPanelBorder: palette.lightGray2,
  searchFieldBorder: palette.lightGray2,
  flytHeadStickyBorder: palette.lightGray2,
  headNavMiniCartArrowBorder: palette.lightGray2,
  headNavAccountMenuBorder: palette.lightGray2,
  orderSummaryTotalsContent: palette.lightGray2,
  orderSummaryTotalsBorder: palette.lightGray2,
  orderSummaryBorder: palette.lightGray2,
  orderSummaryInfoLabel: palette.darkGray,
  orderSummaryDetailItemLabel: palette.darkGray,
  paymentMethodListItem: palette.lightGray2,
  pointsHistoryTableBorder: palette.lightGray2,
  pointsHistoryTableRowBorder: palette.lightGray2,
  profileFormBorder: palette.lightGray2,
  perksListBorder: palette.lightGray2,
  sizePreferencesListItemBorder: palette.lightGray2,
  swatchInputBorder: palette.lightGray2,
  enhancedNavListBorder: palette.lightGray2,
  sortActiveSelection: palette.black,
  desktopProductListingFilter: palette.shadowGray,
  searchSubmitButtonText: palette.purple,
  returnInStoreViewMore: palette.midnight,
  skipTheMonthConfSubHeader: palette.midnight,
  activateTrialSelectBorder: palette.midnight,
  footerSkinnyBannerWrapper: palette.midnight,
  mobileSearchPanePlaceholder: palette.midnight,
  pdpTooltipBorder: palette.midnight,
  pdpTokenOnlyCallout: palette.midnight,
  memberCreditCalloutToolipBorder: palette.midnight,
  storeHoursInactive: palette.midnight,
  fieldsetSelectBorder: palette.midnight,
  fitAppPageBannerBg: palette.midnight,
  storeLocationsDropdownArrow: palette.midnight,
  storeLocationsDropdownLabel: palette.midnight,
  accountOrdersDataLabel: palette.midnight,
  accountOrdersDataValue: palette.trueBlack,
  productDetailProgressBar: palette.darkenGray,
  dropdownMenuButtonBorder: palette.gray,

  alertRed: palette.darkCherry,
  unfocusedBackground: palette.silver,
  sublabelBlack: palette.lightBlack,
  focusText: palette.darkMidnight,
  focusBackground: palette.charcoal,
  focusBorder: palette.darkGray,
  unfocusedLabel: palette.lightMidnight,
  unfocusedSublabel: palette.lightMineral,
  unfocusedAction: palette.mineral,
  unfocusedBorder: palette.lighterMidnight,
  demureBorder: palette.lightestMidnight,
  faintBorder: palette.gray,
  faintBackground: palette.gray,

  actionDisabled: palette.lightestBurntOrange,
  actionEnabled: palette.burntOrange,
  fadedBackground: palette.sunset,

  filterPillUnselected: palette.sand,
  shoppableVideoProgressBackground: palette.charcoal,
  shoppableVideoDarkGray: palette.darkGray2,
  shoppableVideoLightGray: palette.lightMineral,
  shoppableVideoSilver: palette.faintGray,
  shoppableVideoFeaturedText: palette.darkMidnight,
  shoppableVideoFeaturedButton: palette.black,
  shoppableVideoLoaderBg: palette.shadowGray,
  shoppableVideoCarouselTitle: palette.sea,
  shoppableVideoTooltipBg: palette.hydrowGreen,
  shoppableVideoSkeletonGradient20: '#9C9C9C33',
  shoppableVideoSkeletonGradient30: '#9C9C9C4D',
  shoppableVideoProductInfoOverlay: '#FFFFFFE6',
  shoppableVideoShadowShim0: '#00000000',
  shoppableVideoShadowShim16: '#0000002A',
  shoppableVideoShadowShim70: '#000000B3',
  shoppableVideoShadowShim90: '#000000E5',
  shoppableVideoProductCardOverlay: '#FFFFFF80',
  shoppableVideoCarouselDescription: palette.shadow,
  shoppableVideoCarouselFullWidthWrapperBg: palette.shadow10,
  shoppableVideoAuthorProfileImageBg: palette.shadow25,
  shoppableVideoIntroVideoTitle: palette.shadow90,
  shoppableVideoIntroVideoDescription: palette.shadow90,

  bouncebackEndowmentMiniCartPrompt: palette.purple,
  bouncebackEndowmentHighlight: palette.purple,

  promoPickerModalBackgroundTop: palette.lightSoftSilk,
  promoPickerModalBackgroundBottom: palette.white,
  promoPickerGiftBoxColor: palette.purple,
  signupFormSmsDisclosure: palette.shadow65,
  promoPickerFormPanelsLink: palette.shadow70,

  outletSplashSubmitButtonFocus: palette.brick,
  outletSplashPrimaryColor: palette.red,
  outletDisabledSplashSubmitButtonBg: palette.sand120,

  errorPageBackgroundColor: palette.sand,

  tieredListDropdownParentOptionBg: palette.sand70,

  searchCioSkeletonGradient15: palette.shadow15,
  searchCioSkeletonGradient25: palette.shadow25,
  searchCioBarLayoutBorder: palette.shadow20,
  searchCioOverlayNoResultsFoundTitle: palette.shadow70,
  searchCioDesktopInputPlaceholder: palette.shadow90,

  hamburgerMenuHeaderBannerBorder: palette.shadow15,

  pinVerificationLoginSectionHoverBg: palette.shadow20,
  pinVerificationFormDigitInputBorder: palette.shadow25,
  pinVerificationFormResendCodeText: palette.shadow45,
  pinVerificationLoginSectionBorder: palette.shadow25,
  pinVerificationMethodIcon: palette.shadow45,

  countryDropdownMenuBorder: palette.warmGray50,

  sendLinkToPhoneNumberInputBorder: palette.shadow25,
  sendLinkToPhoneNumberInputPlaceholder: palette.shadow90,

  sustainabilityCardWrapperBorder: palette.shadow25,

  loginAuthForgotPasswordControlButton: palette.shadow40,

  klarnaCreditPromotion: palette.shadow50,
};
