import { css } from 'styled-components';

import { mobile } from '../../../techstyle-shared/react-components';

import { fontStacks } from './fonts';

export const baseFontSize = 16;

const values = {
  display1: css`
    font-size: 72px;
    line-height: ${80 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  display2: css`
    font-size: 64px;
    line-height: ${72 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  heading1: css`
    font-size: 48px;
    line-height: ${56 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};

    ${mobile`
      font-size: 32px;
      line-height: ${40 / baseFontSize};
    `}
  `,
  heading1small: css`
    font-size: 32px;
    line-height: ${40 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  heading2: css`
    font-size: 40px;
    line-height: ${48 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};

    ${mobile`
      font-size: 28px;
      line-height: ${32 / baseFontSize};
    `}
  `,
  heading2small: css`
    font-size: 28px;
    line-height: ${32 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  heading3: css`
    font-size: 28px;
    line-height: ${32 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};

    ${mobile`
      font-size: 24px;
      line-height: ${32 / baseFontSize};
    `}
  `,
  heading3small: css`
    font-size: 24px;
    line-height: ${32 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  heading4: css`
    font-size: 28px;
    line-height: ${32 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};

    ${mobile`
      font-size: 20px;
      line-height: ${24 / baseFontSize};
    `}
  `,
  heading4small: css`
    font-size: 20px;
    line-height: ${24 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  heading5: css`
    font-size: 24px;
    line-height: ${32 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};

    ${mobile`
      font-size: 16px;
      line-height: ${24 / baseFontSize};
    `}
  `,
  heading5small: css`
    font-size: 16px;
    line-height: ${24 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  heading6: css`
    font-size: 20px;
    line-height: ${24 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  heading8: css`
    font-size: 12px;
    line-height: ${24 / baseFontSize};
    font-weight: bold;
    font-family: ${fontStacks.baseFamily};
  `,
  paragraph3: css`
    font-size: 18px;
    line-height: ${24 / baseFontSize};
    font-family: ${fontStacks.baseFamily};
  `,
  paragraph2: css`
    font-size: 16px;
    line-height: ${20 / baseFontSize};
    font-family: ${fontStacks.baseFamily};
  `,
  paragraph1: css`
    font-size: 14px;
    line-height: ${18 / baseFontSize};
    font-family: ${fontStacks.baseFamily};
  `,
  caption: css`
    font-size: 12px;
    line-height: ${24 / baseFontSize};
    font-family: ${fontStacks.baseFamily};
  `,
};

const variants = {
  accountConfirmationHeader: {
    textStyle: css`
      ${values.heading2}
      font-weight: bold;
    `,
  },
  accountConfirmationSubheader: {
    textStyle: values.paragraph2,
  },
  accountGreetingRewardsLabel: {
    textStyle: values.caption,
  },
  accountGreetingRewardsValue: {
    textStyle: css`
      font-weight: 600;
    `,
  },
  accountSectionHeader: {
    textStyle: values.heading6,
  },
  bodyMed: {
    textStyle: values.paragraph1,
  },
  bodyMedEmphasized: {
    textStyle: css`
      ${values.paragraph1};
      font-weight: 700;
    `,
  },
  errorText: {
    textStyle: css`
      ${values.paragraph1};
      font-weight: 700;
    `,
  },
  embroideryModalHeader: {
    textStyle: values.heading6,
  },
  embroideryModalCounter: {
    textStyle: values.paragraph1,
  },
  formLabelEmphasized: {
    textStyle: css`
      ${values.heading5small};
      letter-spacing: -0.02em;
      line-height: ${24 / 16};
    `,
  },
  cardHeader: {
    textStyle: values.heading6,
  },
  cardEmphasized: {
    textStyle: values.heading8,
  },
  cardBody: {
    textStyle: css`
      ${values.caption};
      font-weight: 400;
    `,
  },
  cardBodyMed: {
    textStyle: css`
      ${values.paragraph1};
      font-weight: 400;

      ${mobile`
        font-weight: 600;
      `}
    `,
  },
  cardBodyHeavy: {
    textStyle: css`
      ${values.caption};
      font-weight: 600;
    `,
  },
  cardBodyLg: {
    textStyle: css`
      ${values.paragraph3}
      font-weight: 600;
      line-height: ${23 / 18};

      ${mobile`
      ${values.paragraph2}
        font-weight: 700;
        line-height: ${24 / 16};
      `}
    `,
  },
  characterCountLabel: {
    textStyle: css`
      ${values.caption};
      font-weight: 600;
    `,
  },
  contactFormHeader: {
    textStyle: css`
      ${values.heading3}
      font-weight: 700;
    `,
  },
  contactFormOptIn: {
    textStyle: values.paragraph1,
  },
  gridTabOffer: {
    textStyle: css`
      ${values.heading2small};
      font-weight: 700;
      display: block;
      text-align: center;
      margin-bottom: 20px;
    `,
  },
  headlineLarge: {
    textStyle: css`
      ${values.heading1}
      font-weight: 700;

      ${mobile`
        font-size: 48px;
        line-height: ${60 / 48};
        font-weight: 600;
      `}
    `,
  },
  headlineMedium: {
    textStyle: values.heading3,
  },
  iconLabel: {
    textStyle: values.heading8,
  },
  inlineFigureTitle: {
    textStyle: css`
      ${values.heading3small}
      color: ${({ theme }) => theme.colors.textEmphasized};
    `,
  },
  inlineFigureBody: {
    textStyle: css`
      ${values.paragraph2}
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textEmphasized};
    `,
  },
  displayFieldLabel: {
    textStyle: css`
      ${values.paragraph2}
      color: ${({ theme }) => theme.colors.textDefault};
    `,
  },
  displayFieldValue: {
    textStyle: css`
      ${values.paragraph2}
      color: ${({ theme }) => theme.colors.textDefault};
      font-weight: 600;
    `,
  },
  embroideryNotification: {
    textStyle: css`
      ${values.caption}
    `,
  },
  labelUnstyled: {
    textStyle: css`
      ${values.heading5small}
      font-weight: 600;
    `,
  },
  linkDeemphasized: {
    textStyle: css`
      ${values.caption};
      font-weight: 600;
      color: ${({ theme }) => theme.colors.linkTextDeemphasized};
    `,
  },
  linkUnstyled: {
    textStyle: values.paragraph1,
  },
  linkEmphasized: {
    textStyle: css`
      ${values.heading5small}
      padding-bottom: 4px;
      font-weight: 600;
      border-bottom: 2px solid ${({ theme }) => theme.colors.textDefault};
    `,
  },
  listItemHeading: {
    textStyle: css`
      ${values.heading5small};
      margin-bottom: 8px;
    `,
  },

  miniCartPreOrderLabel: {
    textStyle: css`
      ${values.heading8};
      font-weight: 600;
      color: ${({ theme }) => theme.colors.white};
    `,
  },
  miniCartTitle: {
    textStyle: css`
      ${values.paragraph1}
      color: ${({ theme: { colors } }) => colors.textDefault};
      font-weight: 700;
    `,
  },
  miniCartEmptyMessage: {
    textStyle: css`
      ${values.heading5}
      font-weight: 700;
      color: ${({ theme }) => theme.colors.textDefault};
    `,
  },
  miniCartFreeShippingCallout: {
    textStyle: css`
      ${values.caption}
      font-weight: 600;
      color: ${({ theme }) => theme.colors.textDeemphasizedAlternate};
    `,
  },
  miniCartError: {
    textStyle: css`
      ${values.paragraph1}
      font-weight: 600;
    `,
  },
  miniCartRemoveItemError: {
    textStyle: css`
      ${values.paragraph1}
      font-weight: 600;
    `,
  },
  miniCartBundleToggle: {
    textStyle: css`
      ${values.paragraph1}
      font-weight: 600;
    `,
  },
  miniCartScarcityNotice: {
    textStyle: css`
      ${values.caption}
      font-weight: 600;
    `,
  },
  miniCartScarcityCallout: {
    textStyle: css`
      ${values.paragarph1}
      font-weight: 600;
    `,
  },
  miniCartQuality: {
    textStyle: css`
      ${values.caption}
      font-weight: 400;
    `,
  },

  miniCartTimerPromoTitle: {
    textStyle: css`
      ${values.paragraph2}
      font-weight: 700;
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  miniCartTimerPromoOffer: {
    textStyle: css`
      ${values.paragraph1}
      font-weight: 600;
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  miniCartTimerHeader: {
    textStyle: css`
      ${values.caption}
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  miniCartTimerWrapper: {
    textStyle: css`
      ${values.paragraph1}
      font-weight: 700;
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  modalCallout: {
    textStyle: values.heading5small,
  },
  modalHeader: {
    textStyle: css`
      ${values.heading3small}
      text-align: center;
    `,
  },
  modalSubheader: {
    textStyle: css`
      ${values.heading5small}
      font-weight: 600;
      text-align: center;
    `,
  },
  orderDetailsHeading: {
    textStyle: css`
      ${values.heading6}
      font-weight: 700;
    `,
  },
  orderVipSavings: {
    textStyle: css`
      ${values.paragraph1}
      font-weight: 700;
    `,
  },
  orderedListSectionSubtitle: {
    textStyle: css`
      ${values.heading3small}
      font-weight: 700;
    `,
  },
  orderedListSectionTitle: {
    textStyle: css`
      ${values.heading3}
      font-weight: 600;
      color: ${({ theme }) => theme.colors.textDeemphasizedAlternate};
    `,
  },
  postRegGridTab: {
    textStyle: values.paragraph2,
  },
  postRegTabCountDownTimer: {
    textStyle: css`
      ${values.heading6}
      font-weight: 600;
    `,
  },
  legacyPostregCountdownTimer: {
    textStyle: css``,
  },
  productRatingCount: {
    textStyle: css`
      ${values.paragraph1}
      color: ${({ theme }) => theme.colors.linkText};
    `,
  },
  productCellTitle: {
    textStyle: css`
      ${values.heading5small}
    `,
  },
  productCellBody: {
    textStyle: css`
      ${values.paragraph1}
    `,
  },
  productCellBodyEmphasized: {
    textStyle: css`
      ${values.paragraph1}
      font-weight: 600;
      line-height: ${18 / 14};
    `,
  },
  quizAnswer: {
    textStyle: css`
      ${values.paragraph3};
      line-height: 1.1;
      font-weight: 700;
    `,
  },
  quizSizeBlock: {
    textStyle: css`
      ${values.paragraph2};
      font-weight: 600;
    `,
  },
  quizPageHeader: {
    textStyle: values.heading6,
  },
  quizPageSubHeader: {
    textStyle: values.paragraph1,
  },
  quizSkippedText: {
    textStyle: values.paragraph2,
  },
  quizStepCounter: {
    textStyle: css`
      ${values.paragraph1};
      font-weight: 600;
    `,
  },
  sectionTitle: {
    textStyle: css`
      ${values.heading3small};
      text-align: center;
      color: ${({ theme }) => theme.colors.textEmphasized};
    `,
  },
  sectionHeader: {
    textStyle: css`
      ${values.heading3small};
      font-weight: 600;
      text-align: center;
    `,
  },
  sectionBody: {
    textStyle: css`
      ${values.paragraph3};
      text-align: center;
    `,
  },
  sectionText: {
    textStyle: values.paragraph2,
  },
  sustainabilityFeature: {
    textStyle: css`
      ${values.paragraph3}
      line-height: 28px;
    `,
  },
  sizeGuideHeader: {
    textStyle: values.heading5,
  },
  sizeGuideSubheader: {
    textStyle: values.heading5small,
  },
  sizeGuideText: {
    textStyle: values.paragraph1,
  },
  sizeGuideCaption: {
    textStyle: values.caption,
  },
  splashPageDate: {
    textStyle: css`
      ${values.heading2small}
      font-weight: normal;
    `,
  },
  splashPageHeader: {
    textStyle: css`
      ${values.heading3}
      font-weight: 600;
    `,
  },
  splashPageSubheader: {
    textStyle: values.paragraph3,
  },
  splashPageOptIn: {
    textStyle: values.paragraph1,
  },
  splashPageEarlyAccess: {
    textStyle: values.paragraph3,
  },
  splashPageThanks: {
    textStyle: css`
      ${values.heading1small}
      font-weight: 600;
    `,
  },
  splashPageEarlyAccessModalHeader: {
    textStyle: css`
      ${values.paragraph3}
      font-weight: 700;
    `,
  },
  termsText: {
    textStyle: css`
      ${values.caption}
    `,
  },
  default: {
    // WARNING: for deprecated `fontVariant` use only. Please do not use for other use cases.
    textStyle: css`
      font-family: ${fontStacks.standardGtPressuraFamily};
      text-transform: uppercase;
    `,
  },
};

export const typography = {
  values,
  variants,
};
