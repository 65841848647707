const plain = 'Plain';
const arizona = 'Arizona';

export const fonts = [
  {
    fontFamily: plain,
    fontWeight: 200,
    fontFilePath: '/static/fonts/Plain/PlainUltralight-Regular',
  },
  {
    fontFamily: plain,
    fontWeight: 300,
    fontFilePath: '/static/fonts/Plain/PlainLight-Regular',
  },
  {
    fontFamily: plain,
    fontWeight: 400,
    fontFilePath: '/static/fonts/Plain/PlainRegular-Regular',
  },
  {
    fontFamily: plain,
    fontWeight: 500,
    fontFilePath: '/static/fonts/Plain/PlainMedium-Regular',
  },
  {
    fontFamily: plain,
    fontWeight: 700,
    fontFilePath: '/static/fonts/Plain/PlainBold-Regular',
  },
  {
    fontFamily: plain,
    fontWeight: 900,
    fontFilePath: '/static/fonts/Plain/PlainSuper-Regular',
  },
  {
    fontFamily: arizona,
    fontWeight: 400,
    fontFilePath: '/static/fonts/Arizona/SavageXArizonaFlare-Regular',
  },
];

/**
 * stacks for savage:
 * - baseFamily: to maintain compatability with existing references throughout codebase and not break FL/YT
 * - textFamily: to maintain compatability with existing references throughout codebase and not break FL/YT
 * - tempFamily: to maintain compatability with existing references throughout codebase and not break FL/YT
 * - headings: all headings for SXF use Arizona
 * - subheadings: all subheadings for SXF use Plain
 * - copy: all copy for SXF use Plain
 */
const stacks = {
  baseFamily: `${plain}, 'Helvetica Neue', 'Helvetica', sans-serif`,
  textFamily: `${plain}, 'Helvetica Neue', 'Helvetica', sans-serif`,
  tempFamily: `sans-serif`,

  headings: `${arizona}, ${plain}, sans-serif`,
  subheadings: `${plain}, sans-serif`,
  copy: `${plain}, sans-serif`,
};

export const fontStacks = {
  ...stacks,
  alternateFamily: stacks.baseFamily,

  standardGtPressuraFamily: stacks.baseFamily,
  extendedGtPressuraFamily: stacks.baseFamily,
  tempFamily: stacks.baseFamily,

  navBarFamily: stacks.textFamily,
  vipHomepageFamily: stacks.baseFamily,
  condensedVipHomepageFamily: stacks.baseFamily,
};
