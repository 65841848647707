import { transparentize } from 'polished';
import { css } from 'styled-components';

import { desktop, mobile } from '../../../techstyle-shared/react-components';

import { sizes } from './sizes';

export const themeTokens = {
  accountConfirmation: {
    backgroundOpacity: '100%',
    backgroundUrl: `url('/static/LogoScroll.png')`,
    backgroundSize: 'auto',
    header: css`
      color: ${({ theme: { colors } }) => colors.textConfirmation};
    `,
    subheader: css`
      color: ${({ theme: { colors } }) => colors.textConfirmation};
      margin-top: ${({ theme: { sizes } }) => sizes.sm}px;
      text-align: center;
    `,
  },
  activatingMembershipLogo: css`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: auto;
      width: 98px;
      fill: ${({ theme }) => theme.colors.palette.white};
    }
  `,
  backgroundConfirmationOpacity: '100%',
  backgroundConfirmationUrl: `url('/static/LogoScroll.png')`,
  borderRadius: `${sizes.xs}px`,
  borderRadiusCard: `${sizes.sm}px`,
  borderRadiusCart: `${sizes.sm}px`,
  borderRadiusForm: `${sizes.sm}px`,
  borderRadiusInput: `${sizes.xxs}px`,
  borderRadiusInset: `${sizes.xs}px`,
  borderRadiusRound: '999999999px',
  borderRadiusTextArea: `${sizes.sm}px`,
  borderRadiusModal: '10px',
  displayTextTab: css`
    span {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      ${mobile`
        font-size: 32px;
        line-height: 40px;
      `}
      color: ${({ theme }) => theme.colors.palette.black};
      border-bottom: 4px solid transparent;
      &:hover {
        border-color: ${({ theme }) => theme.colors.palette.black};
      }
    }
    border: unset;
    border-radius: unset;
    padding: 6px 24px;
    ${mobile`
      padding: 4px 20px;
    `}
  `,
  displayTextTabSelected: css`
    span {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      ${mobile`
        font-size: 32px;
        line-height: 40px;
      `}
      color: ${({ theme }) => theme.colors.alertRed};
      border-bottom: 4px solid ${({ theme }) => theme.colors.alertRed};
    }
    border: unset;
    border-radius: unset;
    padding: 6px 24px;
    ${mobile`
      padding: 4px 20px;
    `}
  `,
  quizSizingBorderRadius: '16px',
  formInputFields: css`
    border: 3px solid ${({ theme }) => theme.colors.borderLight};
    border-radius: ${({ theme }) => theme.sizes.sm}px;
    width: 100%;

    & > div:first-of-type fieldset {
      border-top-right-radius: ${({ theme }) => theme.sizes.xs}px;
      border-top-left-radius: ${({ theme }) => theme.sizes.xs}px;
    }

    & > div:last-of-type fieldset {
      border-bottom-right-radius: ${({ theme }) => theme.sizes.xs}px;
      border-bottom-left-radius: ${({ theme }) => theme.sizes.xs}px;
    }
  `,
  heroNodeMemberBox: css`
    margin-top: -32px;
  `,
  miniCartOverflowBoxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.1)',
  memberBox: {
    sectionWrapper: css``,
    wrapper: css`
      background: ${({ theme }) => theme.colors.white};
      border-top-left-radius: ${({ theme }) => theme.sizes.sm}px;
      border-top-right-radius: ${({ theme }) => theme.sizes.sm}px;
      z-index: 2;
      position: relative;
      ${desktop`
        display: flex;
        height: 55px;
        align-items: center;
      `}
      ${mobile`
        width: 100%;
      `}
    `,
    cta: {
      text: css`
        padding-top: 7px;
        font-size: 12px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.white};
      `,
      wrapper: css`
        border-width: 1px;
      `,
    },
    tidbit: {
      label: css`
        font-weight: 600;
        font-size: 10px;
        text-transform: uppercase;
      `,
      value: css`
        font-size: 12px;
      `,
    },
  },
  fullWidthCarousel: css`
    background: ${({ theme }) => theme.colors.palette.black};
  `,
  carouselButtonAccessibilityStyles: css`
    &:focus {
      box-shadow: 0 0 0 5px #19f;
    }
    @supports not (selector(:focus-visible)) {
      &:focus {
        box-shadow: 0 0 0 5px #19f;
      }
    }

    &:focus-visible {
      box-shadow: 0 0 0 5px #19f;
    }
  `,
  memberInfo: {
    desktop: {
      header: css`
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 5px;
      `,
      subheader: css`
        font-weight: 450;
      `,
      credits: css`
        font-weight: 450;
      `,
      cta: css`
        padding: 6px 14px;
      `,
      trial: {
        cta: css`
          padding: 6px 9px;
          & span {
            font-weight: 600;
          }
        `,
      },
      wrapper: css`
        padding: 8px 6px 8px 16px;
      `,
    },
    mobile: {
      vipContentSpacer: css`
        margin: 7px 0 0 0;
      `,
      greeting: css`
        font-size: 16px;
      `,
      credits: css`
        font-weight: 450;
      `,
      header: css`
        font-weight: 700;
      `,
      cta: css`
        border-radius: 12px;
        span {
          font-weight: 500;
        }
      `,
      lead: {
        text: css`
          color: ${({ theme: { colors } }) => colors.white};
        `,
        cta: css`
          background: ${({ theme: { colors } }) => colors.lightestSoftSilk};
          span {
            font-weight: 700;
            color: ${({ theme: { colors } }) => colors.black};
          }
        `,
      },
    },
  },
  navTabsListWithFlyoutMenu: {
    label: {
      selected: css`
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      `,
      unselected: css`
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      `,
    },
  },
  profileSectionBorderRadius: '16px',
  accountShipping: {
    modalContentBorderRadius: css`
      ${mobile`
        border-radius: 10px 10px 0 0;
      `}
    `,
    buttonResize: css`
      width: calc(50% - ${({ theme }) => theme.space('xs', true)});
      margin: 0;
      ${mobile`
        width: calc(100% - 48px);
        margin: 0 24px;
      `}
    `,
    addressModalButtonResize: css``,
  },
  backToTopButton: css`
    color: ${({ theme }) => theme.colors.textLight};
    background-color: ${({ theme }) =>
      transparentize(0.05, theme.colors.sublabelBlack)};
    ${desktop`
    background-color: ${({ theme }) =>
      transparentize(0.05, theme.colors.palette.black)};
  `}
  `,
  profileGreetingBox: {
    borderBottom: css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.palette.lightGray2};
      width: 50%;
      margin: 0 auto;
      ${mobile`
        width: 100%;
      `}
    `,
    memberSince: {
      text: css`
        color: ${({ theme }) => theme.colors.textDeemphasized};
      `,
      startDate: css`
        color: ${({ theme }) => theme.colors.textDefault};
      `,
    },
  },
  pdpStyles: {
    swatchSelector: {
      noOptionsWrapper: css`
        font-family: ${({ theme }) => theme.fonts.primary};
        font-size: 14px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.palette.shadow45};
        border: 1px solid ${({ theme }) => theme.colors.palette.shadow15};
        border-radius: 4px;
        padding: 7px 16px;
      `,
      swatchLabelOOSMessage: css`
        color: ${({ theme }) => theme.colors.palette.errorRed};
        text-transform: none;
        font-weight: 600;
        letter-spacing: normal;
      `,
    },
    promoMessage: {
      wrapper: css``,
      copy: css`
        color: ${({ theme }) => theme.colors.palette.purple};
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.02em;
        font-size: 16px;
      `,
    },
    wishlist: {
      ctaWrapper: css`
        border: 1px solid ${({ theme }) => theme.colors.unfocusedSublabel};
        border-radius: 4px;
        padding: 12px 22px;
      `,
    },
    detailsSection: {
      accordionLabel: css`
        font-size: 14px;
      `,
    },
    fitGuarantee: {
      header: css`
        font-weight: bold;
      `,
      copy: css`
        color: ${({ theme }) => theme.colors.darkGray};
      `,
      strikethroughCopy: css``,
    },
    quantitySelectors: {
      indicator: {
        label: css`
          font-family: ${({ theme }) => theme.fontStacks.textFamily};
        `,
        value: css`
          color: ${({ theme }) => theme.colors.palette.lightBlack};
        `,
      },
      baseButton: css``,
    },
    afterpayStyles: css`
      afterpay-placement {
        ${({ theme }) => theme.typography.values.Header14}
        --logo-badge-width: 85px;
        color: ${({ theme }) => theme.colors.palette.lighterBlack};
        font-family: ${({ theme }) => theme.fontStacks.textFamily};
        margin: 10px 0 0 0;
      }
    `,
    ctaWrapper: css`
      ${mobile`
        padding: 0 25px;
      `}
    `,
    vipCtaWrapper: css`
      padding: 16px 0;
      display: flex;
      flex-direction: column;
    `,
    vipAddToBag: {
      button: css`
        background-color: ${({ theme }) => theme.colors.palette.white};
        border: 3px solid ${({ theme }) => theme.colors.palette.black};
        color: ${({ theme }) => theme.colors.palette.black};
      `,
      label: {
        promoPrice: css`
          font-weight: 600;
          font-size: 18px;
          color: inherit;
        `,
      },
    },
    empAddToBag: {
      button: css``,
    },
    guestAddToBag: {
      label: css`
        font-weight: 600;
        font-size: 18px;
        color: inherit;
      `,
      button: css`
        background-color: ${({ theme }) => theme.colors.palette.white};
        border: 3px solid ${({ theme }) => theme.colors.palette.black};
        color: ${({ theme }) => theme.colors.palette.black};
      `,
    },
    guestCtaWrapper: css`
      font-weight: 700;
      padding: 16px 0;
    `,
    priceWrapper: css`
      ${mobile`
      padding: 16px 0 8px 24px;
      `};
    `,
    productNameMargin: css`
      margin-top: 24px;
    `,
    upsellPromos: css`
      color: ${({ theme }) => theme.colors.textEmphasized};
      font-weight: 400;
    `,
    promoMessageStyles: css`
      font-size: 14px;
    `,
    upsellCarousel: {
      carouselHeading: css`
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: ${({ theme }) => theme.sizes.m};
        letter-spacing: 0.0025em;
        color: ${({ theme }) => theme.colors.trueBlack};
        padding-bottom: 0;

        text-align: center;
      `,
      content: css`
        display: grid;
        grid-template-areas: 'image carousel carousel carousel';
        grid-template-columns: repeat(4, 1fr);

        ${mobile`
          display: flex;
          flex-direction: column;
          max-height: unset;
          gap: 16px;
        `}
      `,
      fullWidthCarousel: css`
        margin-bottom: 0;
      `,
      productsWrapper: css`
        grid-area: carousel;

        ${mobile`
          grid-area: grid;
          display: grid;
          grid-gap: ${({ theme }) => theme.spacing.sizes.pixels.s}px;
          margin: 0 ${({ theme }) => theme.spacing.sizes.pixels.s}px;
          grid-template-columns: repeat(2, 1fr);
        `}
      `,
      imageWrapper: css`
        grid-area: image;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-top: 20px;
      `,
      carouselCellStyles: css`
        margin-right: ${({ theme }) => theme.spacing.sizes.pixels.s}px;
        width: 165px;
      `,
      imageCaption: css`
        font-family: ${({ theme }) => theme.fontStacks.baseFamily};
        font-style: normal;
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSizes.xs}px;
        line-height: ${({ theme }) => theme.sizes.m};
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.midnight};
        margin-bottom: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
      `,
      mobileCaption: css`
        font-family: ${({ theme }) => theme.fontStacks.baseFamily};
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.28;
        text-align: center;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme.colors.trueBlack};
        margin-bottom: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
      `,
    },
    mobileHeaderStyle: css`
      display: block;
    `,
    mobileHeaderTitle: css``,
    mobileHeaderCallout: css``,
    enhancedMemberModel: {
      button: {
        base: css`
          height: 28px;
          width: 144px;
          padding: 6px 10px 6px 15px;
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
          border-radius: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          cursor: pointer;
        `,
        chevron: css`
          width: 14px;
          height: 8px;
          color: ${({ theme }) => theme.colors.palette.trueBlack};
          transform: rotate(
            ${({ $direction }) => ($direction === 'UP' ? 180 : 0)}deg
          );
        `,
        labelWrapper: css`
          color: ${({ theme }) => theme.colors.palette.trueBlack};
          font-weight: 500;
          font-size: ${({ theme }) => theme.fontSizes.sm}px;
          line-height: calc(16 / 14);
          font-family: ${({ theme }) => theme.fontStacks.baseFamily};
          font-style: normal;
        `,
        wrapper: css`
          cursor: pointer;
          position: absolute;
          top: 11px;
          left: 8px;
          z-index: 1;

          ${mobile`
            bottom: 42px;
            left: 30%;
            top: unset;
         `}
        `,
      },
      content: {
        dropdown: {
          base: css`
            position: relative;
            width: 100%;
          `,
          list: css`
            top: 40px;
            position: absolute;
          `,
        },
        wrapper: css`
          position: relative;
          max-height: 650px;
          width: 428px;
          overflow-y: auto;
          scroll-behvaior: smooth;
          z-index: 3;
          border-radius: 8px;
          box-shadow: 0px 2px 8px rgba(8, 90, 103, 0.08),
            0px 8px 16px rgba(0, 0, 0, 0.08);
          background-color: ${({ theme }) => theme.colors.white};

          ${mobile`
            width: 100%;
            max-height: unset;
          `}
        `,
        selectionLabel: {
          wrapper: css`
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            width: 100%;
            height: 42px;
            position: sticky;
            top: 0;

            background: ${({ theme }) => theme.colors.palette.white};
            border-bottom: 1px solid
              ${({ theme }) => theme.colors.palette.lightGray};
          `,
          label: css`
            font-weight: 600;
            font-size: ${({ theme }) => theme.fontSizes.sm}px;
            line-height: calc(18 / 14);
          `,
        },
        modal: {
          wrapper: css`
            top: auto;
          `,
          dialog: css`
            @media (max-width: ${({ theme }) =>
                theme.breakpoints.tablet.maxWidth}px) {
              width: 100%;
              height: 60vh;
            }
          `,
        },
      },
      item: {
        wrapper: css`
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;

          padding: 24px 37px 16px 51px;
          gap: 12px;

          border-bottom: 1px solid
            ${({ theme }) => theme.colors.palette.lightGray};
          background: ${({ $isActive, theme }) =>
            theme.colors.palette[$isActive ? 'lightestSoftSilk' : 'white']};

          ${mobile`
            padding: 16px 37px 16px 29px;
          `}
        `,
        dimensions: css`
          display: grid;
          margin-top: 8px;
          grid-template-columns: repeat(2, minmax(100px, 1fr));
          grid-template-rows: repeat(2, 1fr);
          grid-gap: 4px 18px;
          font-size: ${({ theme }) => theme.fontSizes.sm}px;

          ${mobile`
            margin-top: 12px;
          `}
        `,
        imageWrapper: css`
          picture,
          picture img {
            height: 100%;
            width: 100%;
          }
          && {
            width: 75px;
            height: 112px;
          }
          box-sizing: content-box;
          border: ${({ $isActive, theme }) =>
            $isActive && `2px solid ${theme.colors.palette.purple}`};
          margin-bottom: 0;
        `,
        heightLabel: css`
          display: flex;
          flex-direction: row;
        `,
        info: {
          wrapper: css`
            display: flex;
            flex-direction: column;
            gap: 2px;
          `,
          nameLabel: css`
            display: flex;
            flex-direction: row;
            gap: 8px;
          `,
          activeIcon: css`
            color: ${({ theme }) => theme.colors.palette.purple};
            font-size: 16px;
          `,
        },
      },
      indicatorWrapper: css`
        width: 100%;
        height: 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        padding-top: 12px;

        ${mobile`
          margin-bottom: 12px;
        `}
      `,
    },
  },
  selectorStyles: {
    itemSoldOutStyle: css`
      background: ${({ theme }) => theme.colors.palette.gray};
    `,
    itemSizeLabelStyle: css`
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      position: absolute;
      right: 0;
      left: 0;
      text-transform: uppercase;
      ${({ isChecked }) =>
        isChecked &&
        css`
          color: ${({ theme }) => theme.colors.palette.white};
        `}
      ${({ isSoldOut }) =>
        isSoldOut &&
        css`
          color: ${({ theme }) => theme.colors.palette.lightestBlack};
        `}
    `,
    inputSizeStyle: css`
      border-radius: 8px;
      border: 3px solid ${({ theme }) => theme.colors.palette.gray};
      background: ${({ theme }) => theme.colors.palette.white};
      margin: 0px;
      width: 100%;
      height: 40px;

      ${({ isSoldOut }) =>
        isSoldOut &&
        css`
          && {
            border-color: ${({ theme }) => theme.colors.palette.lightestBlack};
            background: ${({ theme }) => theme.colors.palette.gray};
            color: ${({ theme }) => theme.colors.palette.lighterBlack};
            overflow: hidden;
            position: relative;
            &:before {
              background: linear-gradient(
                to right bottom,
                ${({ theme }) => theme.colors.palette.gray},
                ${({ theme }) => theme.colors.palette.gray} 48%,
                ${({ theme }) => theme.colors.palette.lightestBlack} 49%,
                ${({ theme }) => theme.colors.palette.lightestBlack} 51%,
                ${({ theme }) => theme.colors.palette.gray} 52%,
                ${({ theme }) => theme.colors.palette.gray}
              );
            }
          }
        `}

      input:checked + && {
        background-color: ${({ theme }) => theme.colors.palette.black};
        &:before {
          background: linear-gradient(
            to right bottom,
            ${({ theme }) => theme.colors.palette.black},
            ${({ theme }) => theme.colors.palette.black} 48%,
            ${({ theme }) => theme.colors.palette.lightestBlack} 49%,
            ${({ theme }) => theme.colors.palette.lightestBlack} 51%,
            ${({ theme }) => theme.colors.palette.black} 52%,
            ${({ theme }) => theme.colors.palette.black}
          );
        }
        border-color: ${({ theme }) => theme.colors.palette.black};
      }
    `,
    selectedLabelTextStyle: css`
      color: ${({ theme }) => theme.colors.textDefault};
      text-transform: uppercase;
    `,
    selectorLabelStyle: css`
      font-size: 14px;
      color: ${({ theme }) => theme.colors.sublabelBlack};
      font-weight: 600;
    `,
    fitSelectorValue: css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.palette.black};
    `,
    sizeGuideMargin: '0 auto',
  },
  reviewSummaryStyleOverrides: {
    cellStyle: css`
      border: 1px solid ${({ theme }) => theme.colors.palette.shadowGray};
      box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.palette.shadowGray},
        inset 0 1px 0 0 ${({ theme }) => theme.colors.palette.shadowGray},
        inset 1px 0 0 0 ${({ theme }) => theme.colors.palette.shadowGray},
        inset -1px 0 0 0 ${({ theme }) => theme.colors.palette.shadowGray};
    `,
    recommendPercentageStyle: css`
      font-family: ${({ theme }) => theme.fontStacks.textFamily};
      color: ${({ theme }) => theme.colors.sublabelBlack};
      ${desktop`
        font-family: ${({ theme }) => theme.fontStacks.baseFamily};
        color: ${({ theme }) => theme.colors.textDefault};
      `}
    `,
    percentageValueStyle: css`
      font-weight: bold;
      color: ${({ theme }) => theme.colors.textEmphasized};
      ${desktop`
        color: ${({ theme }) => theme.colors.textDefault};
      `}
    `,
    overallFitStyle: css`
      color: ${({ theme }) => theme.colors.textDefault};
      font-weight: 600;
      font-size: 14px;
    `,
    fitTypeStyle: css`
      font-family: ${({ theme }) => theme.fontStacks.textFamily};
      color: ${({ theme, shouldHighlight }) =>
        shouldHighlight
          ? theme.colors.palette.black
          : theme.colors.palette.lighterBlack};
    `,
    reviewTitleStyle: css`
      font-size: 20px !important;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.sublabelBlack};
      font-family: ${({ theme }) => theme.fontStacks.textFamily};
      font-weight: 400;
      ${desktop`
          font-family: ${({ theme }) => theme.fontStacks.baseFamily};
          color: ${({ theme }) => theme.colors.textDefault};
          font-weight: 700;
      `}
    `,
    averageReviewStyle: css`
      color: ${({ theme }) => theme.colors.sublabelBlack};
      ${desktop`
          color: ${({ theme }) => theme.colors.textDefault};
      `}
    `,
  },
  reviewsSummaryWrapper: css`
    background-color: ${({ theme }) => theme.colors.palette.lightGray};
    box-shadow: inset 0 1px 0 0
      ${({ theme }) => theme.colors.palette.shadowGray};
    margin: 32px auto 0;
    padding: 48px 0 32px;
    ${mobile`
      margin: 0 auto;
      background-color: ${({ theme }) => theme.colors.palette.white};
    `}
  `,
  moreReviewsButton: css`
    width: 343px;
    max-width: 95%;
    margin: 16px 0 16px 0;
    align-self: center;

    ${desktop`
      margin: 32px 0 32px 0;
    `}
  `,
  recentReviewsStyle: {
    blockStyle: css`
      &:first-of-type {
        border-radius: 3px 3px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 3px 3px;
      }
      // review title
      > div {
        &:last-of-type {
          p {
            font-size: 18px;
            font-weight: 400;
            ${desktop`
              font-size: 14px;
              font-weight: 600;
            `}
          }
        }
      }
    `,
    productInfoStyle: css`
      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.sublabelBlack};
        &:last-child {
          font-weight: 600;
        }
      }
    `,
    reviewStarsStyle: css`
      color: ${({ theme }) => theme.colors.iconColor};
    `,
    dateStyle: css`
      span {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.palette.lighterBlack};
        font-family: ${({ theme }) => theme.fontStacks.textFamily};
        ${desktop`
          font-size: 12px;
          font-family: ${({ theme }) => theme.fontStacks.baseFamily};
          color: ${({ theme }) => theme.colors.sublabelBlack};
        `}
      }
    `,
    contentStyle: css`
      font-size: 14px;
      line-height: 18px;
      font-family: ${({ theme }) => theme.fontStacks.textFamily};
      color: ${({ theme }) => theme.colors.sublabelBlack};
      ${desktop`
        color: ${({ theme }) => theme.colors.textDefault};
      `}
    `,
    reviewerNameStyle: css`
      font-size: 12px;
      ${desktop`
        font-size: 14px;
      `}
    `,
  },
  reviewsFiltersStyles: {
    applyButtonStyle: css`
      width: 100%;
    `,
    clearAllButtonStyle: css`
      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.textDefault};
      }
    `,
    filterAndSortButton: css`
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.5;
      border: 0;
      margin: 0;
      padding: 0;
      background: transparent;
      color: inherit;
      display: flex;
      align-items: center;
      height: 24px;
      font-family: ${({ theme }) => theme.fontStacks.baseFamily};
    `,
  },
  overallRatingStyles: {
    barFillStyle: css`
      // fix fill overflow
      max-width: 100%;
      background-color: ${({ theme, $isActive }) =>
        $isActive
          ? theme.colors.selectedFilterColor
          : theme.colors.sublabelBlack};
    `,
    barStyle: css`
      background-color: ${({ theme }) => theme.colors.palette.gray};
    `,
    ratingCountStyle: css`
      color: ${({ theme }) => theme.colors.textDefault};
      letter-spacing: -0.35px;
    `,
    ratingNumberStyle: css`
      color: ${({ theme }) => theme.colors.sublabelBlack};
    `,
    starIconStyle: css`
      color: ${({ theme, $isActive }) =>
        $isActive ? theme.colors.selectedFilterColor : theme.colors.iconColor};
    `,
  },
  headNavLogo: css`
    max-width: ${({ width }) => width || '52px'};
    width: 100%;

    ${mobile`
      max-width: 65px;
  `}
  `,
  anniversaryLogo: css``,
  favorites: {
    gridWrapper: css`
      border: 1px solid ${({ theme }) => theme.colors.palette.gray};
      border-radius: ${({ theme }) => `${theme.sizes.sm}px`};
    `,
    addToCartCta: css`
      & > span {
        ${({ theme }) => theme.typography.values.Body14};
        font-weight: 600;
      }
    `,
  },
  reviewsModal: {
    header: {
      count: css`
        font-size: 14px;
      `,
      text: css`
        font-size: 14px;
      `,
    },
  },
  reviewStyles: {
    radioBarContainerStyle: `
      column-gap: 8px;
    `,
    radioBarStyle: css`
      height: 16px;
      border-radius: ${({ theme }) => theme.borderRadius};
    `,
    radioLabelStyle: css`
      ${({ theme }) => theme.typography.values.Header14med};

      ${({ isSelected }) =>
        isSelected &&
        css`
          color: ${({ theme }) => theme.colors.textEmphasized};
        `}
    `,
    ctaButtonStyle: css`
      flex: 0 0 30%;

      ${mobile`
        flex-basis: 100%;
      `}
    `,
    ctaButtonContainerStyle: css`
      justify-content: center;

      ${mobile`
        flex-direction: column;
        gap: ${({ theme }) => theme.sizes.sm}px;
      `}
    `,
  },
  account: {
    pageHeadingSize: '22px',
    myVipStyles: {
      bannerBackground: css`
        background: ${({ theme }) => theme.colors.backgroundCallout};
      `,
      rewardSummaryStyle: `
        border-radius: 16.5px;
      `,
      contactChatStyle: css`
        color: ${({ theme }) => theme.colors.palette.lighterBlack};
      `,
      itemContentTextStyle: css`
        color: ${({ theme }) => theme.colors.textDeemphasized};
      `,
      itemContentTextEmphasizedStyle: css`
        color: ${({ theme }) => theme.colors.textEmphasized};
      `,
      startChatButtonStyle: css`
        padding: ${({ theme }) => `${theme.sizes.xs}px ${theme.sizes.xxl}px`};
        ${({ theme }) => theme.typography.values.Header14med}
      `,

      vipTreatment: {
        mainContainer: css`
          padding-bottom: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.palette.gray};
          font-family: ${({ theme }) => theme.fontStacks.baseFamily};
          text-transform: capitalize;
          width: 235px;
          cursor: pointer;
          display: flex;
          ${mobile`
            width: 100%;
            padding: 15px 25px;
          `}
        `,
        sections: css`
          padding: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.palette.gray};
          font-family: ${({ theme }) => theme.fontStacks.baseFamily};
          display: flex;
          ${mobile`
          margin: 0 15px;
          `}
        `,
        subTitle: css`
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          ${mobile`
            text-decoration: underline;
          `}
        `,
        title: css`
          font-weight: 700;
          font-size: 14px;
        `,
        sectionTitle: css`
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
          font-family: neue-haas-grotesk-display;
        `,
        sectionDescription: css`
          font-size: 12px;
          font-weight: 450;
        `,
        learnMoreButton: css`
          span {
            font-weight: 500;
            font-size: 16px;
          }
          border-radius: 16px;
          margin: 12px 30px;
          padding: 14px 40px;
          height: 48px;
          min-width: 160px;
          ${mobile`
            margin: 12px 25px;
            background-color: #fff;
            border: 3px solid ${({ theme }) => theme.colors.palette.black};
            height: 68px;
            min-width: 311px;
            span {
              color: ${({ theme }) => theme.colors.palette.black};
              font-weight: 600;
            }
          }
          `}
        `,
      },
    },
    myVipModalStyles: {
      modalContentBorderRadius: css`
        ${mobile`
          border-radius: 0 0 0 0;
        `}
      `,
      buttonResize: css`
        width: calc(100% - 5px);
        margin: 0 auto;
        height: 45px;
      `,
      OrdersModalButtonResize: css`
        padding: 10px;
        flex: 0 0 max(110px, 25%);
      `,
      modalContentWidth: css`
        width: 60%;
        margin: 0 auto;
        ${mobile`

      `}
      `,
      overlayColor: css`
        overlaycolor: ${({ theme }) => theme.colors.palette.black60};
      `,
      closeButton: css`
        cursor: pointer;
        position: absolute;
        display: flex;
        width: 56px;
        height: 56px;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        padding: 8px;
        border-width: 0;
        color: ${({ theme }) => theme.colors.palette.darkenGray};
        line-height: 0;
        ${desktop`
        top: 14px;
        right: 14px;
      `}
      `,
    },
    payments: {
      cardHoldersName: css`
        font-size: 16px;
      `,
      paymentMethod: {
        infoLabel: css``,
      },
    },
  },
  footerStyles: {
    wrapperStyle: css`
      border-top: 3px solid ${({ theme }) => theme.colors.palette.gray};
    `,
    dividerStyle: css`
      width: 100%;
      margin: 50px auto 0;
      border: none;
      border-top: 3px solid ${({ theme }) => theme.colors.palette.gray};
      ${mobile`display:none;`}
    `,
    freeShippingDetailsStyle: css`
      color: ${({ theme }) => theme.colors.palette.mediumGray};
    `,
    sectionMenuLinkStyle: css`
      display: inline-block;

      ${({ theme }) => theme.typography.values.Body14};

      ${({ $isLinkHeader }) =>
        $isLinkHeader &&
        css`
          &:link:hover [data-nav-item-label] {
            text-decoration: underline;
          }
        `}

      ${desktop`
        &:link:hover [data-nav-item-label] {
          text-decoration: underline;
        }
      `}

      ${mobile`
        ${({ theme }) => theme.typography.values.Body12med};

        :link {
          display: flex;
          align-items: center;
        }
      `}
      line-height: 18px;
    `,
    adaWidgetButtonCopy: css`
      & > * {
        font-weight: inherit;
      }

      ${desktop`
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      `}

      ${mobile`
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
      `}
    `,
    sectionStyle: css`
      &:first-child {
        margin-top: 0;
      }

      ${mobile`
        border-bottom: 1px solid ${({ theme }) =>
          transparentize(0.6, theme.colors.palette.white)};
      `};
    `,
    sectionTitleStyle: css`
      text-transform: uppercase;
      margin: 0;
      padding: 6px 0 ${({ theme }) => theme.sizes.sm}px;

      ${mobile`
        padding-top: 1.25em;
        padding-bottom: 1.25em;
      `}

      ${({ theme }) => theme.typography.values.Header14med}

      &&& > * {
        ${({ theme }) => theme.typography.values.Header14med}
      }
    `,
    socialIconWrapperStyle: css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.palette.gray};
      padding-top: ${({ theme }) => theme.sizes.md}px;
      padding-bottom: ${({ theme }) => theme.sizes.md}px;
    `,
  },
  gridCellStyles: {
    defaultPriceStyle: css`
      ${({ theme }) => theme.typography.values.Header16med}
      color: ${({ theme }) => theme.colors.textDefault};
      font-size: 14px;
    `,
    priceStyles: css`
      font-size: 14px;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.sublabelBlack} !important;
    `,
    vipPriceStyles: css`
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.sublabelBlack};
    `,
    defaultPromoPriceStyle: css`
      color: ${({ theme }) => theme.colors.textEmphasized};
      font-weight: 700;
      font-size: 14px;
    `,
    moreColorsLink: css`
      font-size: 12px;
      color: ${({ theme }) => theme.colors.palette.mediumGray};
      margin-top: 4px;
      font-weight: 600;
    `,
    priceContent: css`
      font-size: 14px;
      letter-spacing: 0.5px;
    `,
    productCellWrapper: css`
      position: relative;
      font-size: ${({ theme }) => `${theme.fontSizes.xs}px`};
      text-align: left;
      min-width: 100%;
    `,
    productCalloutWrapper: css`
      font-weight: 600;
    `,
    productName: css`
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.sublabelBlack};
      margin-bottom: 6px;
      line-height: 1.4;
      ${mobile`
          font-weight: 500;
      `};
    `,
    promoMessage: css`
      ${({ theme }) => theme.typography.values.paragraph1};
      hyphens: auto;
      overflow-wrap: break-word;
      font-weight: 700;
      margin-bottom: 6px;
      display: inline-block;
      color: ${({ theme }) => theme.colors.palette.nakedRose};
    `,
    gridColorSwatchWrapper: css`
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 3px;
    `,
    gridSwatchInputStyle: css`
      width: 26px;
      height: 26px;
    `,
    calloutText: css`
      color: ${({ theme }) => theme.colors.palette.burntOrange};
    `,
    upsellCarousel: {
      productImageWrapper: css`
        margin-bottom: 5px;
      `,
      productName: css`
        margin-bottom: 5px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.sublabelBlack};
      `,
      promoMessage: css`
        font-size: 14px;
      `,
      promoPrice: css`
        color: ${({ theme }) => theme.colors.promoText};
        font-size: 14px;
        font-weight: 700;
      `,
    },
    swatches: {
      swatchLabel: css`
        color: ${({ theme }) => theme.colors.palette.trueBlack};
        font-weight: 600;
        line-height: 1.2857;
        margin: 8px 0px;
      `,
      swatchColorLabel: css`
        font-weight: 700;
        color: ${({ theme }) => theme.colors.palette.trueBlack};
      `,
    },
  },
  gridFilterStyles: {
    listingFilterStyle: css`
      font-family: neue-haas-grotesk-text;
    `,
    nestedHeadingStyle: css`
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.palette.lighterBlack};
      font-family: neue-haas-grotesk-display;
    `,
    filterFieldLabelStyle: css`
      font-weight: 400;
      color: ${({ theme }) => theme.colors.palette.black};
    `,
    filterCountStyle: css`
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.palette.lighterBlack};
    `,
    inputStyle: css`
      border: 3px solid ${({ theme }) => theme.colors.palette.lightestBlack};
      border-radius: 4px;
    `,
    inputCheckedStyle: css`
      border-color: ${({ theme }) => theme.colors.palette.purple};
      background: ${({ theme }) => theme.colors.palette.purple};
    `,
    tagStyle: css`
      border-radius: 36px;
      background-color: ${({ theme }) => theme.colors.palette.gray};
      font-weight: 400;
      color: ${({ theme }) => theme.colors.palette.purple};
    `,
    deselectIconStyle: css`
      color: ${({ theme }) => theme.colors.palette.purple};
    `,
    circleIconStyle: css`
      border: solid 1px ${({ theme }) => theme.colors.palette.trueBlack};
    `,
    noFiltersAppliedStyle: css`
      font-weight: 500;
      color: ${({ theme }) => theme.colors.sublabelBlack};
      font-family: neue-haas-grotesk-display;
    `,
    headerStyle: css`
      font-weight: 500;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.sublabelBlack};
      font-family: neue-haas-grotesk-display;
    `,
    gridLabelStyle: css`
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.palette.black};
      font-family: neue-haas-grotesk-display;
    `,
    gridInputStyle: css`
      border-radius: 8px;
      border: 3px solid ${({ theme }) => theme.colors.palette.gray};
    `,
    swatchInputStyle: css`
      background-color: ${({ theme }) => theme.colors.palette.gray};
    `,
    applyFiltersButtonStyle: css`
      background-color: ${({ theme }) => theme.colors.palette.purple};
      border-radius: 16px;
      min-height: 56px;
    `,
    clearAllButtonStyle: css`
      font-family: neue-haas-grotesk-text;
      color: ${({ theme }) => theme.colors.palette.purple};
    `,
    filterFieldButtonSvgStyle: css`
      font-size: 24px;
    `,
  },
  gridStyles: {
    categoryDropdownLinkStyle: css`
      font-size: 16px;
      text-align: center;
      color: ${({ theme }) => theme.colors.textDefault};
      box-shadow: none;
      padding: 19px;
      padding-left: 8px;

      &[data-active-category='true'] {
        border-radius: 4px;
        background-color: #dcf1f4;
        color: inherit;
      }
    `,
    activeCategoryItemStyle: css`
      color: ${({ theme }) => theme.colors.textActionDeemphasized};
    `,
    categoryItemStyle: css`
      ${({ theme }) => theme.typography.values.Body14};
      color: ${({ theme }) => theme.colors.palette.lighterBlack};
      margin-bottom: 16px;
    `,
    categoryTree: css`
      color: ${({ theme }) => theme.colors.palette.lighterBlack};
      margin-bottom: 32px;
    `,
    topLevelCategoryHeading: css`
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.sublabelBlack};
      margin-bottom: 13px;
    `,
    gridCtaButtonStyle: css`
      ${({ theme: { colors, sizes } }) => `
        background: ${colors.palette.black};
        color: ${colors.palette.white};
        padding: ${sizes.sm}px 56px;
        border-radius: ${sizes.xxs}px;
      `}

      ${({ theme: { colors, fontStacks, sizes }, $isCarouselGrid }) =>
        $isCarouselGrid &&
        `
          background: ${colors.palette.white};
          color: ${colors.palette.black};
          padding: ${sizes.xxs}px 46px;
          border-radius: ${sizes.xxxs}px;
          border: 1px solid ${colors.palette.black};

          font-family: ${fontStacks.baseFamily};
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        `}
    `,
    gridCarouselLabelStyle: css`
      text-transform: uppercase;
      font-weight: 800;
      font-size: ${({ theme: { sizes } }) => sizes.lg}px;
      line-height: ${({ theme: { sizes } }) => sizes.xxl}px;
      color: ${({ theme }) => theme.colors.textDefault};

      ${mobile`
        font-style: normal;
        font-size: 24px;
        margin: ${({ theme: { sizes } }) => sizes.sm}px;
        display: block;
      `}
    `,
    gridCarouselViewAllCta: css`
      border: none;
      font-weight: 700;
      font-size: ${({ theme: { sizes } }) => sizes.sm}px;
      color: ${({ theme }) => theme.colors.textDefault};
      line-height: 16px;
      text-underline-offset: ${({ theme: { sizes } }) => sizes.xxxs}px;
      text-decoration: underline;
      letter-spacing: 0.02em;
      background: transparent;
    `,
    sortLabel: css``,
    filterDrawerButton: css``,
  },
  gridHeaderStyle: css`
    font-size: 24px;
    letter-spacing: 0;
    font-weight: bold;
    flex-grow: 1;
    ${mobile`
      display: none
    `};
  `,
  mobileGridHeaderStyle: css``,
  mobileProductListingFilterStyles: {
    gridInput: {
      border: '3px solid #eaeaea',
    },
  },
  billMeNow: {
    BorderRadius: css`
      border-radius: 8px;
    `,
    paymentListRadioStyle: css`
      input:checked + && {
        background: ${({ theme }) => theme.colors.palette.purple};
        color: ${({ theme }) => theme.colors.textLight};
        border: 0.88px solid ${({ theme }) => theme.colors.palette.purple};
      }
    `,
    confirmationWrapper: css`
      padding: ${({ theme }) => theme.spacing.sizes.pixels.m}px
        ${({ theme }) => theme.spacing.sizes.pixels.s}px;
      ${mobile`
        padding: 36px 15px;
      `}
    `,
    confirmationPayment: {
      titleBoldness: css`
        font-weight: 900;
      `,
      amntDivPadding: css`
        padding: 11px;
        width: 100%;
        border-radius: 0 16px 16px 0;
      `,
      bmnBtn: css`
        padding: 0;
      `,
    },
    modal: {
      title: css``,
    },
  },
  onlineCancelStyling: {
    ribbonContainer: css`
      background: ${({ theme }) => theme.colors.palette.white};
    `,
    pauseBanner: css`
      background: ${({ theme }) => theme.colors.palette.white};
    `,
    offerCallOut: css`
      background: ${({ theme }) => theme.colors.backgroundCallout};
      color: ${({ theme }) => theme.colors.textLight};
    `,
    offerCta: css`
      text-transform: uppercase;
      font-size: 14px;
      background: ${({ theme }) => theme.colors.palette.white};
      color: ${({ theme }) => theme.colors.textDefault};
      border: none;
      border-radius: 100px;
      padding: 10px 30px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        filter: brightness(0.9) contrast(1.1);
      }
    `,
    areYouSureHeaderStyling: css`
      padding-top: 12px;
      padding-bottom: 10px;
    `,
    cancelConfirmBtnStyle: css`
      background: ${({ theme }) => theme.colors.palette.black};
      width: 50%;
      font-size: 14px;
    `,
    ctaNeutral: css`
      background: ${({ theme }) => theme.colors.backgroundDark};
      color: ${({ theme }) => theme.colors.backgroundLight};
      font-size: 12px;
    `,
    ctaActive: css`
      background: ${({ theme }) => theme.colors.backgroundDark};
      color: ${({ theme }) => theme.colors.backgroundLight};
      opacity: 1;
      &:hover {
        background: ${({ theme }) => theme.colors.backgroundDark};
        > span {
          color: ${({ theme }) => theme.colors.backgroundLight};
        }
      }
    `,
    ctaDisable: css`
      background: ${({ theme }) => theme.colors.backgroundEmphasizedLight};
      > span {
        color: ${({ theme }) => theme.colors.backgroundLight};
      }
    `,
    ctaDisableHover: css`
      background: ${({ theme }) => theme.colors.backgroundDark};
      > span {
        color: ${({ theme }) => theme.colors.backgroundLight};
      }
    `,
    wrapperActive: css`
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
      border: 1px solid ${({ theme }) => theme.colors.backgroundDark};
    `,
    wrapperNeutral: css`
      color: ${({ theme }) => theme.colors.backgroundDark};
    `,
    borderRadiusSize: css`
      border-radius: 16px;
    `,
    iconDisable: css`
      color: ${({ theme }) => theme.colors.backgroundEmphasizedLight};
    `,
    iconActive: css`
      color: ${({ theme }) => theme.colors.backgroundDark};
    `,
    checkBoxImportant: css`
      background: ${({ theme }) => theme.colors.palette.black} !important;
      color: ${({ theme }) => theme.colors.textLight} !important;
    `,
    stillHaveQuestions: {
      contactButtons: css`
        color: ${({ theme }) => theme.colors.palette.black};
        background-color: ${({ theme }) => theme.colors.palette.white};
        border: 1px solid ${({ theme }) => theme.colors.backgroundLight};

        &:hover {
          background-color: ${({ theme }) =>
            theme.colors.palette.featherLightPurple};
          border: 1px solid
            ${({ theme }) => theme.colors.backgroundEmphasizedLight};
        }
      `,
    },
  },
  activateTrial: {
    submitButton: css``,
  },
  quiz: {
    answers: css`
      ${desktop`
        max-width: 1200px;
      `}
      ${mobile`
        width: 100%;
        max-width: 360px;
      `}
      @media (max-width: 1240px) {
        width: 600px;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    `,
    answerButton: css`
      border-radius: 16px;
      ${mobile`
        width: 100%;
        `}
      ${desktop`
        min-width: 250px;
        transform: scale(1);
        &:active {
          transform: scale(0.9);
        }
      `}
    `,
    answerFocused: css``,
    answerButtonWrapper: css`
      border-radius: 16px;
      ${desktop`
        width: 100%;
        max-width: fit-content;
        flex: 0 1 calc( 100% / ${({ $columns }) => $columns} );
        align-self: flex-end;
        margin: 0 ${({ theme }) => theme.sizes.sm}px;
        margin-top: ${({ theme }) => theme.sizes.lg}px;
      `}
      @media (max-width: 1240px) {
        width: 100%;
        flex: 0.75 1 50%;
      }
    `,
    answerTextPaddingM: css`
      padding: 12px 2px;
    `,
    answerWrapper: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      color: ${({ theme }) => theme.colors.palette.deepBrown};
      ${desktop`
        width: 250px;
        max-height: 386px;
        &:hover,
        &:focus {
          color: ${({ theme }) => theme.colors.quizAnswerColorHover};
          transition: background-color 0.5s ease, color 0.5s ease, scale 0.5s;
        }
      `}
      ${mobile`
        max-height: 241px;
        width: 147px;
        flex-direction: column;
        margin: 12px;
        ${({ $multiline }) =>
          $multiline &&
          css`
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
          `}
      `}
    `,
    assetWrapper: css`
      overflow: hidden;
      border-radius: 16px;
      // added mask-image bc border radius was not persisting on hover in safari
      ${desktop`
        mask-image: radial-gradient(white, black);
        background-color: ${({ theme }) => theme.colors.palette.white};
        width: 100%;
      `}
    `,
    backButton: css``,
    closeButton: css``,
    defaultRegistrationFieldsColumn: css`
      & > div {
        display: flex;
        flex-direction: column;
        position: relative;
        column-gap: 10px;
        row-gap: 24px;

        ${mobile`
          row-gap: 8px;
        `}

        > div:last-of-type,
        > div:first-of-type {
          border-radius: ${({ theme }) => theme.borderRadiusInput};
          border: none;
        }

        > div {
          border-radius: ${({ theme }) => theme.borderRadiusInput};
          height: ${({ showZip }) => (showZip ? '48px' : '72px')};

          ${mobile`
            height: ${({ showZip }) => (showZip ? '56px' : '72px')};
          `}

          > fieldset {
            padding: 0;
            ${mobile`
              padding-left: 10px;
            `}

            &:focus-within {
              padding: 0;
            }

            label {
              color: ${({ theme }) => theme.colors.registrationFieldsLabel};
            }
          }

          border: none;

          &.has_error {
            border: 1px solid ${({ theme }) => theme.colors.error} !important;
          }
        }
      }
    `,
    quizDropdownButtonBorder: css`
      border: ${({ theme }) => theme.quiz.quizBorder} solid
        ${({ theme }) => theme.colors.borderLight};
      border-radius: 16px;
      background-color: ${({ theme }) => theme.colors.borderLight};
    `,
    dropdownButtonBorder: css`
      border: ${({ theme }) => theme.quiz.quizBorder} solid
        ${({ theme }) => theme.colors.borderLight};
    `,
    navBarWrapper: 'padding: 24px 32px;',
    editButton: css``,
    errorPosition: css`
      margin-top: -25px;
      padding: 30px 6px 8px 6px;
    `,
    /* Main wrapper for the HDYH dropdown */
    HowDidYouHearDropdownWrapper: css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `,
    quizDropdownContentContainer: css`
      padding: 0 12px 0 16px;
      ${mobile`
        padding: 0;
      `}
    `,
    TieredQuizDropdownContentWrapper: css`
      padding: 0px 1px;
      ${mobile`
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      `}
      button {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        ${desktop`
          margin: 1px 0px;
        `}
      }
    `,
    hdyhQuizDropdownButton: css`
      ${desktop`
        border-top: 3px solid ${({ theme }) => theme.colors.quizDropdownBorder};
      `}
    `,
    fieldWrapper: css`
      position: relative;

      ${mobile`
        #topSize,
        #bottomSize,
        #braSize,
        #referrer {
          ${({ theme: { colors, borderRadiusInput }, showZip }) => `
            height: ${showZip ? '56px' : '72px'};
            background-color: ${colors.palette.white};
            border-radius: ${borderRadiusInput};
          `}

          :focus {
            border-color: ${({ theme }) => theme.colors.focusColor};
          }

          label {
            padding-left: 8px;
          }

          &:has(option:checked:not([value=""])) ~ label {
            padding: 0;
          }

        }

        #bottomSize.has_error,
        #topSize.has_error,
        #braSize.has_error,
        #referrer.has_error {
          border-color: ${({ theme }) => theme.colors.error};
        }
      `}

      ${desktop`
        #referrer:has(option:checked:not([value=""])) ~ label {
          padding-top: 8px;
        }

        label[for='referrer'] {
          text-align: left;
          padding-top: 0;
        }
      `}
    `,
    builderNoAssetAnswer: css`
      ${({ theme: { typography } }) =>
        typography.variants.quizAnswer.textStyle};
      min-height: fit-content;
      width: fit-content;
      padding: 20px 40px;
      border-radius: 16px;
      ${desktop`
        &:hover,
        &:focus {
            color: ${({ theme }) => theme.colors.textLight};
          }
        }
      `}
      ${mobile`
        margin: ${({ theme }) => theme.sizes.xs}px;
        padding: 20px;
      `}
    `,
    noAssetAnswer: css`
      ${({ theme: { typography } }) =>
        typography.variants.quizAnswer.textStyle};
      min-height: fit-content;
      width: fit-content;
      padding: 20px 40px;
      border-radius: 16px;
      ${desktop`
        &:hover,
        &:focus {
            color: ${({ theme }) => theme.colors.textLight};
          }
        }
      `}
      ${mobile`
        margin: ${({ theme }) => theme.sizes.xs}px;
        padding: 20px;
      `}
    `,
    modalDialog: 'min-width: 610px;',
    sizingPageBottomForm: 'width: 530px;',
    quizBirthdayABTestStyles: {
      formFieldWrapper: css``,
      zipCodeAndBirthdayWrapper: css``,
    },
    panelStyle: css`
      min-height: 600px;
    `,
    multiSelectAnswerButton: css`
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      border-radius: 20px;
      flex-flow: column nowrap;
      width: 247px;
      margin: 12px;
      align-items: center;
      max-height: none;

      picture {
        overflow: hidden;
        border-radius: 20px;
      }

      img {
        transition: scale 0.5s;
      }

      &:hover,
      &:focus {
        background-color: ${({ theme }) => theme.colors.palette.deepBrown};
        color: ${({ theme }) => theme.colors.palette.white};

        img {
          scale: 1.1;
        }
      }

      ${mobile`
        flex-flow: column nowrap;
        width: 147px;
        justify-content: center;
      `}
    `,
    multiSelectAnswerText: css`
      color: ${({ theme }) => theme.colors.palette.white};
    `,
    multiSelectAnswerTextFocused: css`
      color: ${({ theme }) => theme.colors.palette.white};
      background-color: transparent;
    `,
    progressBar: css`
      border: 1px solid ${({ theme }) => theme.colors.palette.deepBrown};
      height: 12px;
      width: 311px;
      border-radius: 16px;
      display: flex;
      justify-content: flex-start;
      ${desktop`
        margin-top: ${({ theme }) => theme.sizes.sm}px;
      `}
      ${mobile`
        margin-top: 20px;
      `}
      span:first-child {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
      span:only-child {
        border-radius: 16px;
      }
    `,
    barStyle: css`
      height: 100%;
      background: ${({ $filled, theme }) =>
        $filled ? theme.colors.palette.deepBrown : 'transparent'};
      ${({ $lastBar }) =>
        $lastBar &&
        css`
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        `}
    `,
    questionStyle: css`
      font-weight: 700;
      ${mobile`
        font-size: 18px;
        padding: 0 12px;
        margin: ${({ theme }) => theme.sizes.sm}px;
      `}
      ${desktop`
        font-size: 32px;
      `}
    `,
    questionTitleStyle: css`
      font-weight: 700;
      ${mobile`
        font-size: 18px;
        padding: 0 12px;
        margin: ${({ theme }) => theme.sizes.sm}px;
      `}
      ${desktop`
        font-size: 32px;
      `}
    `,
    questionSubtitle: css``,
    quizBorder: '3px',
    quizTieredDropdownMobileLeftPadding: '22px',
    quizLayoutWrapper: css``,
    quizLayout: css``,
    quizNavLogo: css`
      svg {
        width: 100%;
        height: auto;
        max-width: 52px;

        ${mobile`
          height: 40px;
          max-width: 65px;
        `}
      }
    `,
    quizNextButton: css`
      height: 56px;
      width: 235px;
      margin-top: 40px;
      border-radius: 16px;
      ${mobile`
        padding: 18px 0px;
        align-self: center;
        width: 100%;
      `}
      ${({ theme: { colors } }) => `
        color: ${colors.palette.white};
        background-color: ${colors.quizButtonBackground};
      `}
    `,
    quizPageContainer: css`
      ${desktop`
        min-width: 730px;
        padding: 0px 40px;
      `}
      ${mobile`
        margin-bottom: 32px;
        height: 100%;
        flex-flow: column nowrap;
      `}
    `,
    quizPageRegistrationWrapper: css`
      min-width: unset;
      padding: 0;
      margin: 0;
      ${mobile`
            height: 100%;
            overflow-y: auto;
          `}
      width: 100%;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
    `,
    quizSizingRegPadding: css`
      padding: 56px 0px;
      padding-top: 8px;
      ${mobile`
        padding: 56px 16px;
        padding-top: 8px;
      `}
    `,
    quizSizingHeader: css`
      ${mobile`
        padding: ${sizes.md}px;
        padding-top: 0;
      `}
    `,
    quizSizingBlock: css`
      ${({ theme: { sizes, colors }, $selected }) => `
        padding: ${sizes.sm}px;
        border-radius: ${sizes.xxs}px;
        border: 1px solid ${colors.palette.gray};
        ${
          $selected
            ? `border: 3px solid ${colors.palette.deepBrown};`
            : `margin: 2px;`
        };
        :hover {
          border: 1px solid ${colors.palette.deepBrown};
        }
      `}
    `,
    quizSizingInnerWrapper: css`
      ${mobile`
        height: 70vh;
      `}
    `,
    quizSizingZipCodeWrapperStyles: css`
      fieldset {
        border-radius: 0;
      }

      :first-child {
        fieldset {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          ${mobile`
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          `}
        }
      }

      :not(:first-child) {
        border-radius: 0;
      }
    `,
    quizSizingZipCodeErrorWrapperStyles: css`
      background-color: ${({ theme: { colors } }) => colors.error};
    `,
    quizSizingZipCodeErrorTextStyles: css`
      color: ${({ theme: { colors } }) => colors.palette.white};
    `,
    regButtonMargin: '56px 0',
    regFormFields: css``,
    registrationFormWrapper: css`
      display: flex;
      flex-flow: column nowrap;
      width: 323px;
      ${desktop`
        width: 450px;
      `}
    `,
    registrationForm: '',
    quizSizingReferrerButton: `
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    `,
    quizLocationReferrerButton: '',
    regPageHeight: '100%',
    registrationPage: css`
      justify-content: center;
    `,
    skippedRegistration: css`
      margin-top: 32px;
      ${mobile`
        margin-top: 0;
      `}
    `,
    skippedRegSelect: css`
      & ~ label {
        color: ${({ theme }) => theme.colors.quizDropdownLabel};
      }

      ${mobile`
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;
      `}
    `,
    smSpacer: css`
      height: ${({ theme: { sizes } }) => sizes.sm}px;
    `,
    mdSpacer: css`
      height: ${({ theme: { sizes } }) => sizes.md}px;
    `,
    termsAndPrivacy: css`
      a {
        padding: 0 12px;
      }
      span {
        border-color: ${({ theme }) => theme.colors.linkTextDeemphasized};
      }
    `,
    zipCodeInput: css`
      fieldset {
        height: 100%;
        input {
          line-height: 18px;
          font-size: 14px;
        }
        label[data-label-position='inside'] {
          line-height: 13.73px;
          color: ${({ theme }) => theme.colors.quizDropdownLabel};
        }
      }

      ${desktop`
        ${({ theme: { sizes, colors, quiz } }) => `
          border-top: ${quiz.quizBorder} solid ${colors.palette.gray};
          border-top-left-radius: ${sizes.sm}px;
          border-top-right-radius: ${sizes.sm}px;
        `}
      `}
    `,
  },
  sweepstakeForm: {},
  countdownStyles: {
    postreg: {
      text: css``,
    },
  },
  marketingCards: {
    variants: {
      default: {
        marketingCardsHeader: css``,
        cardsHeaderTitle: css``,
        cardsContainer: css`
          @media (max-width: 959px) {
            gap: 30px;
            grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
          }
        `,
        cardVariant: 'VIPHomepage',
      },
      giftGuide: {
        marketingCardsHeader: css`
          height: 50px;
          width: 100%;
          margin: 0;

          ${mobile`
            display: none;
          `}
        `,
        cardsHeaderTitle: css`
          color: ${({ theme: { colors } }) => colors.palette.white};

          ${mobile`
            display: none;
          `}
        `,
        cardsContainer: css`
          grid-template-areas:
            'card_1 card_2'
            'card_3 card_4';

          grid-gap: 20px;
          grid-template-columns: repeat(2, 1fr);
          margin: 0 22px;

          ${desktop`
            grid-template-areas: 'card_1 card_2 card_3 card_4';
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 24px;
            grid-row-gap: 0px;
            max-width: 1042px;
          `}
        `,
        cardVariant: 'giftGuide',
      },
    },
  },
  marketingCard: {
    variants: {
      default: {
        card: css``,
        cardTitle: css``,
        cardbody: css`
          min-height: 150px;
          padding: 21px 0 21px 12px;
        `,
        cardSubtitle: css``,
        cardImage: css`
          max-width: 100%;
        `,
      },
      VIPHomepage: {
        card: css`
          overflow: hidden;
          border-radius: 16px;
          box-shadow: 0px 0px 28px -5px rgb(0 0 0 / 15%);
          @media (max-width: 959px) {
            border-radius: 0;
          }
        `,
        cardTitle: css`
          font-size: 28px;
          line-height: 1;
        `,
        cardbody: css`
          min-height: 150px;
          padding: 21px 0 21px 12px;
          min-height: 136px;
          min-width: 200px;
          @media (max-width: 959px) {
            padding: 12px;
          }
        `,
      },
      giftGuide: {
        card: css`
          background-color: ${({ theme: { colors } }) => colors.palette.white};

          grid-area: ${({ index }) => `card_${index + 1}`};
          min-width: 332px;
        `,
        cardBody: css`
          min-height: 150px;
          text-align: center;
          padding: 20px 0;
          min-height: 0;
        `,
        cardSubtitle: css`
          ${({ theme: { typography } }) => typography.values.heading4small};
          line-height: ${26 / 28};
          font-family: ${({ theme: { fontStacks } }) =>
            fontStacks.condensedTradeGothicFamily};
          letter-spacing: -0.02em;

          color: ${({ theme: { colors } }) => colors.focusText};
          padding: 0 32px;
        `,
        cardTitle: css`
          ${({ theme: { typography } }) => typography.values.heading2small};
          line-height: ${42 / 28};
          font-weight: 800;
          font-family: ${({ theme: { fontStacks } }) =>
            fontStacks.condensedTradeGothicFamily};
          letter-spacing: 0.01em;
          text-transform: uppercase;

          color: ${({ theme: { colors } }) => colors.focusText};
          padding: 0 32px;
        `,
        cardImage: css`
          max-width: 100%;
        `,
      },
    },
  },
  activeLineItemMiniCart: css`
    &.active {
      background: ${({ theme: { colors } }) =>
        colors.palette.featherLightPurple};
    }
  `,
  productRatingWrapper: css`
    margin-bottom: 8px;
  `,
  preOrderCallout: css`
    ${({ theme }) => theme.typography.values.Header12med}
    padding: 2px 8px;
    margin-bottom: 10px;
    border-radius: 8px;
    background: ${({ theme: { colors } }) => colors.backgroundEmphasizedLight};
    width: fit-content;
    color: ${({ theme: { colors } }) => colors.textEmphasized};
    ${mobile`
      margin-top: 4px;
    `}
    text-transform: none;
  `,
  pdpMarketingCallout: css`
    margin-bottom: 10px;
  `,
  floatingFilterButton: css`
    background-color: ${({ theme }) => theme.colors.palette.black};
  `,
  tabsNode: {
    activeTabLabel: css`
      font-weight: 600;
    `,
    bannerAriaHiddenWrapper: css`
      ${mobile`
        margin: 0px;
      `}
    `,
    bannerCopy: css`
      margin: 0px;
      padding: 0px;
    `,
    buttonText: css``,
    carousel: css``,
    countdown: css`
      ${mobile`
        padding-top: 10px;
      `}
    `,
    countdownDigits: css``,
    countdownWrapper: css`
      background: ${({ theme }) => theme.colors.palette.lightestSoftSilk};
      height: 44px;
    `,
    image: css`
      margin-bottom: 12px;
    `,
    scrolledTabsWrapper: css`
      border-radius: 0px;

      ${desktop`
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        padding-top: ${({ theme }) => theme.sizes.sm}px;
        padding-right: 0px;
        padding-bottom: ${({ theme }) => theme.sizes.sm}px;
        padding-left: 64px;
      `}
    `,
    scrolledTabsSelectorWrapper: css`
      border-radius: 0px;

      ${mobile`
        background: ${({ theme }) => theme.colors.white};
      `}
    `,
    scrolledTabsSelectors: css`
      border-radius: ${({ theme }) => theme.sizes.xxxs}px;
      margin-right: 16px;
    `,
    styledTabs: css`
      ${mobile`
        padding-bottom: 10px;
      `}
    `,
    postRegTabs: {
      floating: {
        navigation: {},
        countdown: {},
      },
    },
  },
  fitGuideHeader: css`
    ${({ theme }) => theme.typography.values.Header32};
    margin-top: 20px;
    margin-bottom: 18px;
    ${mobile`
    ${({ theme }) => theme.typography.values.Header22};
    margin-top: 40px;
    margin-bottom: 16px;
  `}
  `,
  fitGuideTabButton: css`
    margin: 0 12px;
    padding: 5px 0;
    display: flex;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
    ${({ theme }) => theme.typography.values.Header14};
    &.active {
      border-bottom-color: ${({ theme }) => theme.colors.textDefault};
      transition: border-bottom-color 500ms ease-in-out;
    }
  `,
  fitGuideDropdownMenuListItem: css`
    &.active {
      color: ${({ theme }) => theme.colors.unfocusedAction};
    }
  `,
  sizePreferencesDropdownList: css`
    &&& {
      max-height: 360px;
      overflow-y: scroll;
      z-index: 999;
      border-radius: 3px;
      padding-top: 0;
      top: 46px;
    }
    color: ${({ theme: { colors } }) => colors.focusText};
  `,
  sizePreferencesDropdownListItem: css``,
  pdpScrollOffset: css`
    ${mobile`
      padding-top: 63px;
   `}
  `,
  pauseBillingStyling: {
    wrapperBorder: css`
      border: 3px solid ${({ theme: { colors } }) => colors.palette.purple};
    `,
    iconTextColor: css`
      color: ${({ theme: { colors } }) => colors.palette.purple};
    `,
    pauseBillingButton: css`
      ${desktop`
      margin: 0px 10px 0 0;
      `}
      ${mobile`margin: 25px auto 0;`}
      background: ${({ theme }) => theme.colors.palette.white};
      padding: 15px 15px;
      height: auto;
      width: 75%;
      border: 3px solid ${({ theme }) => theme.colors.palette.black};
      &:active {
        background: ${({ theme }) => theme.colors.palette.white};
        border: 3px solid ${({ theme }) => theme.colors.palette.black};
      }
      &:hover {
        background: ${({ theme }) => theme.colors.palette.white};
        border: 3px solid ${({ theme }) => theme.colors.palette.black};
      }
      > span {
        color: ${({ theme }) => theme.colors.palette.black};
        font-size: 14px;
      }
    `,
  },
  storeLocator: {
    announcement: css`
      color: ${({ theme: { colors } }) => colors.palette.purple};
      font-weight: 400;
      font-size: 14px;
    `,
    address: css`
      font-weight: 500;
    `,
    phone: css`
      font-weight: 500;
    `,
    locatorTop: css`
      background: ${({ theme: { colors } }) =>
        colors.palette.featherLightPurple};
    `,
    storeName: css`
      font-weight: 600;
    `,
    locatorGridMobile: css`
      background: ${({ theme: { colors } }) =>
        colors.palette.featherLightPurple};
    `,
    locatorTopLeadMobile: css`
      text-transform: uppercase;
      font-weight: 900;
      font-size: 20px;
    `,
    locatorButtonMobile: css`
      min-width: 240px;
    `,
  },
  VariantVipContact: {
    contactButtonStyle: css`
      padding: 2px 10px;
      border: 3px solid #000;
      border-radius: 12px;
      margin-bottom: 10px;
      width: 70%;
    `,
    ContactLightTextAnytime: {
      fontSize: css`
        ${desktop`
          font-size:12px;
        `}
      `,
    },
  },
  hypeDriver: {
    formStyle: css`
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 16px;
      width: 500px;
      margin: 0 auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      color: ${({ theme }) => theme.colors.textDefault};
      ${mobile`
        width: 100%;
     `}
    `,
    textInputStyle: css`
      height: 48px;
      margin-bottom: 18px;
      fieldset {
        height: 50px;
        border-radius: 16px;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border};
        border-bottom: none;
      }
      ${mobile`
        font-size: 16px;
      `}
      [disabled] {
        color: ${({ theme }) => theme.colors.unfocusedLabel};
      }
    `,
    buttonStyle: css`
      margin-top: ${({ theme }) => theme.sizes.lg}px;
      width: 100%;
      border-radius: 16px;
    `,
  },
  loyaltyCountDownStyle: {
    markup: css`
      background: ${({ theme }) => theme.colors.palette.featherLightPurple};
      height: 40px;
    `,
    wrapperStyling: css`
      height: 86px;
      border: 1px solid ${({ theme }) => theme.colors.palette.purple};
    `,
    numericWrapperStyling: css`
      line-height: 35px;
    `,
    abbreviationStyling: css`
      line-height: 20px;
    `,
  },
  cashRewardGrid: {
    assetTextSize: css`
      font-size: 28px;
      padding: 15px;
      line-height: normal;
    `,
    buttonStyling: css`
      padding: 14px 5px;
      border-radius: 14px;
    `,
    buttonActive: css`
      background: ${({ theme }) => theme.colors.backgroundDark};
      color: ${({ theme }) => theme.colors.backgroundLight};
      opacity: 1;
      &:hover {
        background: ${({ theme }) => theme.colors.backgroundDark};
        > span {
          color: ${({ theme }) => theme.colors.backgroundLight};
        }
      }
    `,
    buttonDisable: css`
      background: ${({ theme }) => theme.colors.backgroundDark};
      > span {
        color: ${({ theme }) => theme.colors.backgroundLight};
      }
    `,
    lockIconStyling: css`
      top: 30%;
    `,
    customLockIcon: css`
      ${mobile`
        top: 60%;
      `}
    `,
    modalBackground: css`
      background: ${({ theme: { colors } }) => colors.palette.white};
      color: ${({ theme: { colors } }) => colors.palette.trueBlack};
    `,
    modalBorder: css`
      border: 4px solid ${({ theme }) => theme.colors.palette.white};
      border-radius: 8px;
      background: ${({ theme: { colors } }) => colors.palette.white};
    `,
    modalHeaderFontSize: css`
      font-size: 48px;
      ${mobile`
        font-size: 38px;
        margin: 15px auto 10px;
      `}
    `,
    modalSubHeaderStyling: css`
      margin: 15px auto;
    `,
    modalMessage: css`
      color: ${({ theme: { colors } }) => colors.palette.trueBlack};
      font-family: ${({ theme: { fontStacks } }) =>
        fontStacks.standardGtPressuraFamily};
      ${mobile`
        padding: 0 15px;
      `}
    `,
    modalRedeemButton: css`
      cursor: pointer;
      font-size: 16px;
      ${({ theme: { colors } }) => `
        background-color: ${colors.loyaltyActivePrimaryButton};
      `}

      ${({ isDisabled, hasPromo }) =>
        (isDisabled || hasPromo) &&
        css`
          ${({ theme: { colors } }) => `
            background-color: ${colors.loyaltyDisablePrimaryButton};
          `}
          cursor: not-allowed;
        `}

       ${({ isRedeemed }) =>
        isRedeemed &&
        css`
          font-size: 15px;
        `}

      &:hover {
        ${({ theme: { colors } }) => `
          background-color: ${colors.palette.darkPurple};
        `}
      }

      &:focus {
        ${({ theme: { colors } }) => `
          background-color: ${colors.palette.darkPurple};
        `}
      }
      color: ${({ theme }) => theme.colors.white};
      width: 100%;
      margin: 21px auto;
      text-transform: uppercase;

      font-weight: 700;
      text-align: center;
      padding: 14px;
      border-radius: 16px;
      cursor: pointer;
    `,
  },
  miniCart: {
    itemCount: css`
      color: ${({ theme: { colors } }) => colors.textFadedAlternate};
    `,
    itemQuality: {
      label: css``,
      name: css``,
    },
    embroidery: {
      label: css``,
      name: css``,
    },
    timer: {
      wrapper: css`
        background: ${({ theme: { colors } }) =>
          colors.backgroundCalloutAlternate};
      `,
      text: css`
        color: ${({ theme }) => theme.colors.backgroundLight};
      `,
      border: css`
        border: 3px solid ${({ theme }) => theme.colors.backgroundLight};
      `,
    },
    promo: css`
      font-weight: 600;
    `,
  },
  memberCreditCallout: {
    memberCreditCalloutWrapperMinicart: css`
      margin-top: 10px;

      ${mobile`
        margin-top: 6px;
      `}
    `,
    memberCreditCalloutCopy: css`
      font-size: 11px;
      font-weight: 600;
      line-height: ${13 / 11};

      color: ${({ theme }) => theme.colors.palette.trueBlack};
      background-color: ${({ theme }) =>
        theme.colors.palette.featherLightPurple};
      border-radius: 16px;

      margin-top: 6px;
      padding: 6px 8px;
    `,
  },
  byo: {
    pdpCtaTooltipTextWrapper: css`
      font-weight: 400;

      margin-top: 12px;
    `,
    minicartPromptCopy: css`
      font-size: 14px;
      font-weight: 600;
      line-height: ${20 / 17};
    `,
  },
  search: {
    featuredProductListItem: css`
      ${({ isHighlighted, theme }) =>
        isHighlighted && {
          outline: `2px solid ${transparentize(
            0.25,
            theme.colors.backgroundDark
          )}`,
        }};
    `,
    text: css`
      font-weight: 500;
    `,
  },
  promoMessage: {
    /**
     *  This overrides an !important coming from the CMS Asset tool for prioritized promos.
     *  The defined Asset for the prioritized promo, under use of featureNames.PRIORITIZED_PROMOS,
     *  has an HTML input text editor for the promo label entry. In some cases, the user could apply
     *  a paragraph (p) HTML element to the label entry, which comes with default styles applied
     *  per the HTML default styles spec, and an !important applied to that default style. The
     *  !important below overrides the CMS driven HTML input explained above.
     */
    overrideMarkupStylesFromCms: css`
      & > p {
        display: inline-block;
        margin-bottom: 0px !important;
      }
    `,
  },
  builderFooter: {
    footerLink: {
      bold: css`
        font-weight: 700;
      `,
      label: css`
        font-weight: 400;
      `,
      link: css`
        padding: 4px 0px;
        display: inline-block;
        color: ${({ theme }) => theme.colors.palette.white};

        &:hover span {
          text-decoration: underline;
        }

        ${mobile`
          padding: 12px 46px;
        `}
      `,
      oneTrust: css`
        color: ${({ theme }) => theme.colors.palette.white};
        padding: 4px 0;

        &:hover {
          text-decoration: underline;
        }
      `,
    },
  },
  builderHeader: {
    customColors: {
      sale: css`
        color: ${({ theme }) => theme.colors.palette.burntOrange};
      `,
    },
    metaNav: css`
      background-color: ${({ theme }) => theme.colors.palette.lightestSoftSilk};
    `,
    metaNavItem: {
      hover: css`
        background-color: ${({ theme }) =>
          theme.colors.palette.lighterSoftSilk};
      `,
    },
    countryMenuItem: css`
      &:hover {
        background-color: ${({ theme }) =>
          theme.colors.palette.lightestSoftSilk};
      }
    `,
    tertiaryNavItem: css`
      color: ${({ theme }) => theme.colors.palette.trueBlack};

      ${mobile`
        color: ${({ theme }) => theme.colors.palette.white};
        background-color: ${({ theme }) => theme.colors.palette.deepBrown};
      `}
    `,
    navLink: {
      bold: css`
        font-weight: 700;
      `,
      wrapper: css`
        &:hover {
          background-color: ${({ theme }) =>
            theme.colors.palette.lightestSoftSilk};
        }
      `,
      label: css`
        font-weight: 450;
      `,
      link: css`
        padding: 8px 10px;

        ${mobile`
          padding: 12px 46px;
        `}
      `,
      callout: {
        default: css`
          font-weight: 600;

          background-color: ${({ theme }) =>
            theme.colors.palette.featherLightPurple};
          padding: 4px 6px;
        `,
        customColors: {
          priority1: css`
            background-color: ${({ theme }) => theme.colors.palette.softSilk};
          `,
          priority2: css`
            color: ${({ theme }) => theme.colors.palette.white};
            background-color: ${({ theme }) =>
              theme.colors.palette.burntOrange};
          `,
        },
      },
    },
    accountNavLink: {
      wrapper: css`
        &:hover {
          background-color: ${({ theme }) =>
            theme.colors.palette.lightestSoftSilk};
        }
      `,
      link: css`
        font-weight: 450;
        color: ${({ theme }) => theme.colors.palette.trueBlack};
      `,
    },
    fallbackHeader: {
      wrapper: css`
        padding: 8px 27px;
        width: fit-content;

        ${mobile`
          padding: 12px;
          margin: 0 auto;
        `}
      `,
    },
    stickyContainer: {
      contents: css`
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.palette.lightestMidnight};
      `,
    },
  },
  expansionPanel: {
    panelHeader: {
      primary: css`
        background-color: ${({ theme }) =>
          theme.colors.palette.lighterSoftSilk};
        border-bottom: 2px solid
          ${({ theme }) => theme.colors.palette.lighterNakedRose};

        padding: 16px 32px 16px 16px;
      `,
      secondary: css`
        background-color: ${({ theme }) => theme.colors.palette.white};
        border-bottom: 2px solid
          ${({ theme }) => theme.colors.palette.lightestNakedRose};

        padding: 16px 32px 16px 32px;
      `,
      footer: css`
        background-color: ${({ theme }) => theme.colors.palette.black};
        border-bottom: 1px solid ${({ theme }) => theme.colors.palette.shadow90};

        padding: 16px 32px 16px 16px;

        svg {
          fill: white;
          font-size: 12px;
        }
      `,
    },
    panelLabel: {
      primary: css`
        color: ${({ theme }) => theme.colors.palette.black};
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
      `,
      secondary: css`
        color: ${({ theme }) => theme.colors.palette.lightDeepBrown};
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      `,
      footer: css`
        color: ${({ theme }) => theme.colors.palette.white};
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      `,
    },
    panelIcon: css`
      color: ${({ theme }) => theme.colors.palette.midnight};
    `,
    panelWrapper: {
      footer: css`
        background: ${({ theme }) => theme.colors.palette.black};
      `,
    },
  },
  listColumn: {
    header: css`
      font-size: 16px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.palette.deepBrown};

      padding: 0px 10px 10px 10px;
    `,
    separator: css`
      border-top: 2px solid
        ${({ theme }) => theme.colors.palette.lightNakedRose};
    `,
    wrapper: {
      footer: css`
        padding-bottom: 0;
      `,
    },
    unorderedList: {
      footer: css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.palette.shadow90};
        span {
          font-size: 12px;
          text-transform: uppercase;
        }
      `,
    },
  },
  errorBackground: css`
    background: repeating-linear-gradient(
      45deg,
      #fce1df,
      #fce1df 10px,
      #f3baba 10px,
      #f3baba 20px
    );
  `,
  sizeSelectionExpansionPanel: {
    sizeSelectionHeader: {
      header: css`
        color: ${({ theme }) => theme.colors.palette.deepBrown};
        padding: ${({ theme }) => theme.sizes.md}px;

        ${({ open, theme }) =>
          open &&
          css`
            padding-bottom: ${open ? theme.sizes.sm : theme.sizes.md}px;
          `}
      `,
      sizeHeader: css`
        color: ${({ theme }) => theme.colors.palette.purple};
      `,
      editSizeButton: css`
        color: ${({ theme }) => theme.colors.palette.purple};
      `,
    },
    sizeSelectionContent: {
      content: css`
        padding: ${({ theme }) => theme.sizes.md}px 0px;
        padding-top: unset;

        ${desktop`
        ${({ theme }) => `
          padding: ${theme.sizes.md}px;
        `}
      `}
      `,
      sizeButton: css`
        ${({ theme: { sizes, colors }, $selected }) => `
        padding: ${sizes.sm}px;

        border-radius: ${sizes.xxs}px;
        border: 1px solid ${colors.palette.gray};
        background-color: ${({ theme }) =>
          theme.colors.palette.lightestMidnight};

        ${
          $selected
            ? `border: 3px solid ${colors.palette.deepBrown};`
            : `margin: 2px;`
        };
        :hover {
          border: 1px solid ${colors.palette.deepBrown};
        }
      `}
      `,
    },
    collapsibleWrapper: css`
      color: ${({ theme }) => theme.colors.palette.deepBrown};
      background-color: ${({ theme }) => theme.colors.palette.white};

      border: 3px solid ${({ theme }) => theme.colors.palette.gray};

      &:first-child {
        border-radius: 16px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        border-top: 3px solid ${({ theme }) => theme.colors.palette.gray};
        border-bottom: 1px solid ${({ theme }) => theme.colors.palette.gray};
      }

      &:nth-child(2) {
        border-bottom: 0px solid ${({ theme }) => theme.colors.palette.gray};
        border-top: 0px solid ${({ theme }) => theme.colors.palette.gray};
      }

      &:last-child {
        border-radius: 16px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        border-bottom: 3px solid ${({ theme }) => theme.colors.palette.gray};

        ${({ showBra }) =>
          showBra &&
          css`
            border-top: 1px solid ${({ theme }) => theme.colors.palette.gray};
          `}

        ${({ showBra }) =>
          !showBra &&
          css`
            border-top: none;
          `}
      }
    `,
  },
  iconButtonModal: {
    header: css`
      font-size: 32px;
      font-weight: 700;
    `,
    subheader: css`
      font-size: 16px;
      font-weight: 700;
    `,
    copy: css`
      font-size: 16px;
    `,
  },
  communicationPreferencesToggle: css``,
  promoPickerPopup: {
    desktopHeaderFontSize: 78,
    mobileHeaderFontSize: 50,
    desktopSubHeaderFontSize: 27,
    mobileSubHeaderFontSize: 20,
    header: css`
      line-height: ${72.5 / 78};
      font-weight: 900;

      ${mobile`
        line-height: ${44 / 50};
      `}
    `,
    subHeader: css`
      line-height: ${29 / 27};
      letter-spacing: 0.02em;
      font-weight: 600;

      ${mobile`
        line-height: ${24 / 20};
      `}
    `,
    signupSubmitButtonText: css`
      text-transform: uppercase;
      font-weight: 500;

      ${mobile`
        font-size: 14px;
      `}
    `,
    signInButton: css`
      margin-bottom: 65px;
    `,
    closeButton: css`
      color: ${({ theme }) => theme.colors.textDefault};
    `,
    loginHeading: css`
      font-size: 42px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: ${42 / 42};
    `,
    sizingGrid: css`
      grid-template-columns: repeat(4, 1fr);
      gap: 12px;

      & button {
        background: ${({ theme }) => theme.colors.palette.lightestMidnight};
        padding: 8px 12px;

        & span {
          font-weight: 600;
        }
      }

      ${desktop`
        grid-template-columns: repeat(6, 1fr);
        width: auto;
        gap: 8px;

        & button {
          padding: 16px;
        }
      `}
    `,
  },
};
