import { css } from 'styled-components';

import { desktop, mobile } from '../../../techstyle-shared/react-components';

import { sizes } from './sizes';

export const themeTokens = {
  accountConfirmation: {
    backgroundOpacity: '5%',
    backgroundUrl: ``,
    backgroundSize: '500px',
    header: css`
      color: ${({ theme: { colors } }) => colors.textConfirmation};
      font-family: ${({ theme: { fontStacks } }) =>
        fontStacks.condensedTradeGothicFamily};
      text-transform: uppercase;
    `,
    subheader: css`
      color: ${({ theme: { colors } }) => colors.textConfirmation};
      margin-top: ${({ theme: { sizes } }) => sizes.sm}px;
      font-size: 20px;
      font-weight: 600;
      font-style: italic;
      text-align: center;
    `,
  },
  activatingMembershipLogo: css`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: auto;
      width: 324px;
      fill: ${({ theme }) => theme.colors.palette.white};
    }
  `,
  backgroundConfirmationOpacity: '5%',
  backgroundConfirmationUrl: `url('/static/LogoScrollFab.png')`,
  borderRadius: `${sizes.xxxs}px`,
  borderRadiusCard: `${sizes.xxs}px`,
  borderRadiusCart: `${sizes.xxxs}px`,
  borderRadiusForm: '6px',
  borderRadiusInput: '6px',
  borderRadiusInset: `${sizes.xxxs}px`,
  borderRadiusTextArea: `${sizes.xxs}px`,
  borderRadiusRound: '999999999px',
  borderRadiusModal: '4px',
  quizSizingBorderRadius: '6px',
  displayTextTab: css`
    span {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      ${mobile`
        font-size: 32px;
        line-height: 40px;
      `}
      color: ${({ theme }) => theme.colors.palette.black};
      border-bottom: 4px solid transparent;
      &:hover {
        border-color: ${({ theme }) => theme.colors.palette.black};
      }
    }
    border: unset;
    border-radius: unset;
    padding: 6px 24px;
    ${mobile`
      padding: 4px 20px;
    `}
  `,
  displayTextTabSelected: css`
    span {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      ${mobile`
        font-size: 32px;
        line-height: 40px;
      `}
      color: ${({ theme }) => theme.colors.alertRed};
      border-bottom: 4px solid ${({ theme }) => theme.colors.alertRed};
    }
    border: unset;
    border-radius: unset;
    padding: 6px 24px;
    ${mobile`
      padding: 4px 20px;
    `}
  `,
  formInputFields: css`
    border: 1px solid ${({ theme }) => theme.colors.borderLight};
    border-radius: 6px;
    width: 100%;

    & > div:first-of-type fieldset {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    & > div:last-of-type fieldset {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  `,
  heroNodeMemberBox: css`
    margin-top: -8px;
  `,
  miniCartOverflowBoxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.25)',
  memberBox: {
    sectionWrapper: css`
      ${mobile`
        background-color: ${({ theme: { colors } }) => colors.palette.stone80}
      `}
    `,
    wrapper: css`
      border: 2px solid ${({ theme }) => theme.colors.backgroundDark};
      background: ${({ theme }) => theme.colors.palette.white};
      border-radius: ${({ theme }) => theme.sizes.sm}px;
      z-index: 2;
      position: relative;
      ${desktop`
        display: flex;
        height: 65px;
        align-items: center;
      `}
      ${mobile`
        width: 100%;
      `}
    `,
    cta: {
      text: css`
        span {
          font-weight: 700;
        }
      `,
      wrapper: css``,
    },
    bottomWrapper: css`
      background-color: ${({ theme: { colors } }) => colors.palette.stone80};
    `,
    tidbit: {
      label: css`
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
      `,
      value: css`
        font-size: 12px;
      `,
    },
  },
  fullWidthCarousel: css`
    background: ${({ theme }) => theme.colors.palette.white};
  `,
  carouselButtonAccessibilityStyles: css`
    &:focus {
      box-shadow: 0 0 0 5px #000;
    }

    &:focus-visible {
      box-shadow: 0 0 0 5px #000;
    }
  `,
  memberInfo: {
    desktop: {
      header: css`
        font-size: 14px;
        font-weight: 500;
      `,
      subheader: css`
        font-weight: 400;
      `,
      credits: css`
        font-weight: 400;
      `,
      cta: css`
        padding: 8px 14px;
      `,
      trial: {
        cta: css``,
      },
      wrapper: css`
        padding: 8px 6px 8px 16px;
      `,
    },
    mobile: {
      vipContentSpacer: css`
        margin: 4px 0 0 0;
      `,
      greeting: css`
        font-size: 14px;
      `,
      credits: css`
        font-weight: 400;
      `,
      header: css`
        font-weight: 600;
      `,
      cta: css`
        border-radius: 32px;
        span {
          font-weight: 700;
        }
      `,
      lead: {
        text: css`
          font-weight: 500;
        `,
        cta: css`
          background: ${({ theme: { colors } }) => colors.palette.gunmetal};
          span {
            font-weight: 500;
          }
        `,
      },
    },
  },
  navTabsListWithFlyoutMenu: {
    label: {
      selected: css`
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      `,
      unselected: css`
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
      `,
    },
  },
  profileSectionBorderRadius: '8px',
  accountShipping: {
    modalContentBorderRadius: css`
      ${mobile`
        border-radius: 0 0 0 0;
      `}
    `,
    buttonResize: css`
      width: calc(100% - 5px);
      margin: 0 auto;
      height: 45px;
    `,
    addressModalButtonResize: css`
      padding: 10px;
      flex: 0 0 max(110px, 25%);
    `,
  },
  profileGreetingBox: {
    borderBottom: css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.palette.gunmetal15};
      width: 50%;
      margin: 0 auto;
      ${mobile`
        width: 100%;
      `}
    `,
    memberSince: {
      text: css`
        color: ${({ theme: { colors } }) => colors.palette.gunmetal};
      `,
      startDate: css`
        color: ${({ theme: { colors } }) => colors.palette.gunmetal};
        font-weight: 700;
      `,
    },
  },
  pdpStyles: {
    swatchSelector: {
      noOptionsWrapper: css`
        font-family: ${({ theme }) => theme.fonts.primary};
        font-size: 14px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.palette.gunmetal45};
        border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal15};
        border-radius: 4px;
        padding: 7px 16px;
      `,
      swatchLabelOOSMessage: css`
        color: ${({ theme }) => theme.colors.palette.red500};
        font-weight: 600;
      `,
    },
    promoMessage: {
      wrapper: css`
        background-color: ${({ theme }) => theme.colors.palette.lavender300};
        padding: 6px 8px 6px 8px;
        display: inline-block;
      `,
      copy: css`
        color: ${({ theme }) => theme.colors.palette.gunmetal};
        font-weight: 700;
        font-size: 14px;
      `,
      strikethroughCopy: css`
        font-weight: 700;
        font-size: 14px;
      `,
    },
    wishlist: {
      ctaWrapper: css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.palette.black};
        padding: 0 6px 8px 6px;
      `,
    },
    detailsSection: {
      accordionLabel: css`
        font-size: 16px;
      `,
    },
    fitGuarantee: {
      header: css`
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      `,
      copy: css`
        color: ${({ theme }) => theme.colors.palette.gunmetal65};
      `,
    },
    quantitySelectors: {
      indicator: {
        label: css`
          font-weight: 400;
          color: ${({ theme }) => theme.colors.palette.gunmetal90};
        `,
        value: css`
          font-weight: 700;
          color: ${({ theme }) => theme.colors.palette.gunmetal90};
        `,
      },
      baseButton: css`
        border-top: 2px solid ${({ theme }) => theme.colors.palette.gunmetal25};
        border-bottom: 2px solid
          ${({ theme }) => theme.colors.palette.gunmetal25};

        &:hover {
          background-color: ${({ theme }) => theme.colors.palette.stone80};
        }
      `,
    },
    afterpayStyles: css`
      afterpay-placement {
        --logo-badge-width: 75px;
        color: ${({ theme }) => theme.colors.palette.gunmetal50};
        margin: 0;
      }
    `,
    ctaWrapper: css`
      ${mobile`
        padding: 0 18px;
      `}
    `,
    vipCtaWrapper: css`
      display: flex;
      flex-direction: column;
      padding: 16px;
      background: ${({ theme }) => theme.colors.palette.stone70};
      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal25};
      border-radius: 2px;
    `,
    guestCtaWrapper: css`
      font-weight: 600;
      padding: 16px;
    `,
    vipAddToBag: {
      button: css``,
      label: {
        promoPrice: css`
          font-weight: 600;
          font-size: 18px;
          color: ${({ theme }) => theme.colors.palette.white};
        `,
      },
    },
    empAddToBag: {
      button: css`
        ${({ disabled }) =>
          disabled &&
          css`
            cursor: not-allowed;
            background: ${({ theme }) => theme.colors.palette.gunmetal20};
            border: none;
            color: ${({ theme }) => theme.colors.palette.gunmetal40};

            &:hover,
            &:focus,
            &:active {
              background: ${({ theme }) => theme.colors.palette.gunmetal20};
            }
          `}
      `,
    },
    guestAddToBag: {
      label: css``,
      button: css``,
    },
    priceWrapper: css`
      ${mobile`
      padding: 0 24px;
      `};
    `,
    productNameMargin: css`
      margin: 0;
    `,
    upsellPromos: css`
      color: ${({ theme }) => theme.colors.unfocusedLabel};
      ${({ theme }) => theme.typography.values.paragraph1};
      font-weight: 700;
    `,
    upsellCarousel: {
      carouselHeading: css`
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        text-transform: uppercase;
        line-height: ${({ theme }) => theme.sizes.m};
        color: ${({ theme }) => theme.colors.palette.black};
        padding-bottom: 0;

        text-align: center;
      `,
      content: css`
        display: grid;
        grid-template-areas: 'image carousel carousel carousel';
        grid-template-columns: repeat(4, 1fr);
        max-height: 368px;

        ${mobile`
          display: flex;
          flex-direction: column;
          max-height: unset;
          gap: 16px;
        `}
      `,
      fullWidthCarousel: css`
        margin-bottom: 24px;
      `,
      productsWrapper: css`
        grid-area: carousel;

        ${mobile`
          grid-area: grid;
          display: grid;
          grid-gap: ${({ theme }) => theme.spacing.sizes.pixels.s}px;
          margin: 0 ${({ theme }) => theme.spacing.sizes.pixels.s}px;
          grid-template-columns: repeat(2, 1fr);
        `}
      `,
      imageWrapper: css`
        grid-area: image;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-top: 20px;

        ${mobile`
          padding-top: 0;
        `}
      `,
      carouselCellStyles: css`
        margin-right: ${({ theme }) => theme.spacing.sizes.pixels.s}px;
        width: 165px;
      `,
      imageCaption: css`
        font-family: ${({ theme }) => theme.fontStacks.baseFamily};
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: ${({ theme }) => theme.sizes.m};
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.palette.gunmetal65};
        margin-bottom: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
      `,
      mobileCaption: css`
        font-family: ${({ theme }) => theme.fontStacks.baseFamily};
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.28;
        text-align: center;
        color: ${({ theme }) => theme.colors.palette.black};
        margin-bottom: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
      `,
    },
    mobileHeaderTitle: css`
      flex: 1;
      margin-right: 5px;
      & > h1 span {
        font-size: 18px;
      }
      & > div {
        margin-top: 3px;
        margin-bottom: 0;
      }
    `,
    mobileHeaderCallout: css`
      font-size: 12px;
    `,
    enhancedMemberModel: {
      button: {
        base: css`
          height: 32px;
          padding: 4px 22px;
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          gap: 12px;
        `,
        chevron: css`
          width: 14px;
          height: 8px;
          color: ${({ theme }) => theme.colors.palette.black};
          transform: rotate(
            ${({ $direction }) => ($direction === 'UP' ? 180 : 0)}deg
          );
        `,
        labelWrapper: css`
          color: ${({ theme }) => theme.colors.palette.black};
          font-weight: 600;
          font-size: 14px;
          text-transform: uppercase;
          line-height: calc(24 / 16);
        `,
        wrapper: css`
          cursor: pointer;
          position: absolute;
          top: 11px;
          left: 8px;
          z-index: 1;

          ${mobile`
            bottom: 32px;
            left: 30%;
            top: unset;
         `}
        `,
      },
      content: {
        dropdown: {
          base: css`
            position: relative;
            width: 100%;
          `,
          list: css`
            top: 40px;
            position: absolute;
          `,
        },
        wrapper: css`
          position: relative;
          max-height: 650px;
          width: 428px;
          overflow-y: auto;
          scroll-behvaior: smooth;
          z-index: 3;
          border-radius: 8px;
          box-shadow: 0px 2px 8px rgba(8, 90, 103, 0.08),
            0px 8px 16px rgba(0, 0, 0, 0.08);
          background-color: ${({ theme }) => theme.colors.palette.white};

          ${mobile`
            width: 100%;
            max-height: unset;
          `}
        `,
        selectionLabel: {
          wrapper: css`
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            width: 100%;
            height: 42px;
            position: sticky;
            top: 0;

            background: ${({ theme }) => theme.colors.palette.white};
            border-bottom: 1px solid
              ${({ theme }) => theme.colors.palette.gunmetal30};
          `,
          label: css`
            font-weight: 600;
            font-size: 16px;
            line-height: calc(18 / 14);
            text-transform: uppercase;
            ${mobile`
              font-size: 14px;
            `}
          `,
        },
        modal: {
          wrapper: css`
            top: auto;
          `,
          dialog: css`
            @media (max-width: ${({ theme }) =>
                theme.breakpoints.tablet.maxWidth}px) {
              width: 100%;
              height: 60vh;
            }
          `,
        },
      },
      item: {
        wrapper: css`
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;

          padding: 24px 37px 16px 51px;
          gap: 12px;

          border-bottom: 1px solid
            ${({ theme }) => theme.colors.palette.gunmetal30};
          background: ${({ $isActive, theme }) =>
            theme.colors.palette[$isActive ? 'stone80' : 'white']};

          ${mobile`
            padding: 16px 37px 16px 29px;
          `}
        `,
        dimensions: css`
          display: grid;
          margin-top: 8px;
          grid-template-columns: repeat(2, minmax(100px, 1fr));
          grid-template-rows: repeat(2, 1fr);
          grid-gap: 4px 18px;
          font-size: 14px;
          font-weight: 400;
          b {
            font-weight: 700;
          }
          color: ${({ theme }) => theme.colors.palette.gunmetal90};

          ${mobile`
            margin-top: 12px;
          `}
        `,
        imageWrapper: css`
          picture,
          picture img {
            height: 100%;
            width: 100%;
          }
          && {
            width: 75px;
            height: 112px;
          }
          box-sizing: content-box;
          border: ${({ $isActive, theme }) =>
            $isActive && `1px solid ${theme.colors.palette.black}`};
          margin-bottom: 0;
        `,
        heightLabel: css`
          display: flex;
          flex-direction: row;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.palette.gunmetal90}
          font-size: 16px;
          b {
            font-weight: 700;
          }
          ${mobile`
            font-size: 14px;
          `}
        `,
        info: {
          wrapper: css`
            display: flex;
            flex-direction: column;
            gap: 2px;
          `,
          nameLabel: css`
            flex-direction: row;
            gap: 8px;
          `,
          activeIcon: css`
            color: ${({ theme }) => theme.colors.success};
            font-size: 16px;
          `,
        },
      },
      indicatorWrapper: css`
        width: 100%;
        height: 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        padding-top: 12px;
        font-size: 14px;
        font-weight: 600;
      `,
    },
  },
  selectorStyles: {
    inputSizeStyle: css`
      font-weight: 600;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal25};
      background: ${({ theme }) => theme.colors.palette.white};
      margin: 0px;
      width: 100%;
      height: 40px;

      input:checked + && {
        font-weight: 700;
        border: 2px solid ${({ theme }) => theme.colors.sublabelBlack};
        background: ${({ theme }) => theme.colors.palette.white};
      }

      input:focus + && {
        outline: none;
        box-shadow: unset;
      }

      ${({ isSoldOut }) =>
        isSoldOut &&
        css`
          &:before {
            background: linear-gradient(
              to right bottom,
              ${({ theme }) => theme.colors.palette.gunmetal15},
              ${({ theme }) => theme.colors.palette.gunmetal15} 48%,
              ${({ theme }) => theme.colors.unfocusedSublabel} 49%,
              ${({ theme }) => theme.colors.unfocusedSublabel} 51%,
              ${({ theme }) => theme.colors.palette.gunmetal15} 52%,
              ${({ theme }) => theme.colors.palette.gunmetal15}
            );
          }
          input:checked + && {
            &:before {
              background: linear-gradient(
                to right bottom,
                ${({ theme }) => theme.colors.palette.gunmetal15},
                ${({ theme }) => theme.colors.palette.gunmetal15} 48%,
                ${({ theme }) => theme.colors.palette.gunmetal80},
                ${({ theme }) => theme.colors.palette.gunmetal80},
                ${({ theme }) => theme.colors.palette.gunmetal15} 52%,
                ${({ theme }) => theme.colors.palette.gunmetal15}
              );
            }
            border: 2px solid ${({ theme }) => theme.colors.palette.gunmetal80};
          }
        `}
    `,
    itemSizeLabelStyle: css`
      ${({ theme }) => theme.typography.values.paragraph2};
      font-weight: 600;
      text-align: center;
      position: absolute;
      right: 0;
      left: 0;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.palette.black};
      ${({ isSoldOut, isChecked }) =>
        isSoldOut &&
        css`
          opacity: 60%;
        `}

      ${({ isSoldOut, isChecked }) =>
        isSoldOut &&
        isChecked &&
        css`
          color: ${({ theme }) => theme.colors.palette.black};
        `}
    `,
    itemSoldOutStyle: css`
      background: ${({ theme }) => theme.colors.palette.gunmetal15};
      border-width: 2px;
      &:before {
        background: none;
      }
    `,
    selectedLabelTextStyle: css`
      color: ${({ theme }) => theme.colors.palette.gunmetal90};
      font-size: 14px;
      font-weight: 700;
      margin: 6px 0;
      text-transform: uppercase;
    `,
    selectorLabelStyle: css`
      ${({ theme }) => theme.typography.values.paragraph1};
      color: ${({ theme }) => theme.colors.palette.gunmetal90};
      font-weight: 400;
    `,
    fitSelectorValue: css`
      font-weight: 700;
      color: ${({ theme }) => theme.colors.palette.gunmetal90};
    `,
    sizeGuideMargin: '0',
  },
  // pdp reviews section
  reviewSummaryStyleOverrides: {
    cellStyle: css`
      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal15};
      box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.colors.palette.gunmetal15},
        inset 0 1px 0 0 ${({ theme }) => theme.colors.palette.gunmetal15},
        inset 1px 0 0 0 ${({ theme }) => theme.colors.palette.gunmetal15},
        inset -1px 0 0 0 ${({ theme }) => theme.colors.palette.gunmetal15};
    `,
    recommendPercentageStyle: css`
      ${mobile`
        ${({ theme }) => theme.typography.values.paragraph1};
        color: ${({ theme }) => theme.colors.textEmphasized};
        margin-top: 16px;
      `}
    `,
    percentageValueStyle: css`
      font-weight: bold;
    `,
    fitTypeStyle: css`
      ${desktop`
        font-size: 14px;
      `}
    `,
    overallFitStyle: css`
      ${desktop`
        font-size: 16px;
        font-weight: 800;
      `}
    `,
    reviewTitleStyle: css`
      font-size: 20px !important;
      text-transform: uppercase;
    `,
  },
  reviewsSummaryWrapper: css`
    background-color: ${({ theme }) => theme.colors.palette.gunmetal10};
    box-shadow: inset 0 1px 0 0
      ${({ theme }) => theme.colors.palette.gunmetal15};
    margin: 32px auto 0;
    padding: 48px 0 32px;
    ${mobile`
      margin: 0 auto;
      background-color: ${({ theme }) => theme.colors.palette.white};
      padding: 0px;
    `}
  `,
  moreReviewsButton: css`
    ${({ theme }) => theme.typography.values.paragraph1};
    width: 343px;
    max-width: 95%;
    border-radius: 4px;
    height: 48px;
    margin: 32px 0 32px 0;
    align-self: center;
    text-transform: uppercase;

    ${({ theme }) => css`
      background-color: ${theme.colors.sublabelBlack};
      border: 1px solid ${theme.colors.sublabelBlack};
    `};

    ${mobile`
      margin: 16px 0 16px 0;
    `}
  `,
  recentReviewsStyle: {
    blockStyle: css`
      &:first-of-type {
        border-radius: 3px 3px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 3px 3px;
      }
    `,
    productInfoStyle: css`
      text-transform: uppercase;
      font-weight: 400;
      color: ${({ theme: { colors } }) => colors.palette.gunmetal90};
      span {
        &:last-child {
          font-weight: 700;
        }
      }
    `,
  },
  reviewsFiltersStyles: {
    applyButtonStyle: css`
      border-radius: 4px;
      font-weight: 700;
      ${({ theme }) => theme.typography.values.paragraph2};
    `,
    clearAllButtonStyle: css`
      span {
        color: ${({ theme }) => theme.colors.palette.gunmetal65};
      }
    `,
    filterAndSortButton: css`
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      text-transform: uppercase;
      line-height: 1.5;
      border: 0;
      margin: 0;
      padding: 0;
      background: transparent;
      color: inherit;
      display: flex;
      align-items: center;
      height: 24px;
      font-family: ${({ theme }) => theme.fontStacks.baseFamily};
    `,
  },
  overallRatingStyles: {
    barContainerStyle: css`
      &:hover {
        background: ${({ theme }) => theme.colors.palette.stone80};
      }
      &:focus {
        background: ${({ theme }) => theme.colors.palette.stone};
      }
    `,
    barFillStyle: css`
      background-color: ${({ theme }) => theme.colors.sublabelBlack};
    `,
    barStyle: css`
      background-color: ${({ theme }) => theme.colors.palette.gunmetal15};
    `,
    ratingCountStyle: css`
      color: ${({ theme }) => theme.colors.palette.black};
    `,
    ratingNumberStyle: css`
      color: ${({ theme }) => theme.colors.sublabelBlack};
    `,
    starIconStyle: css`
      color: ${({ theme }) => theme.colors.sublabelBlack};
    `,
  },
  backToTopButton: css`
    color: ${({ theme }) => theme.colors.palette.black};
    background-color: ${({ theme }) => theme.colors.palette.white};
  `,
  headNavLogo: css`
    width: 135px;
    height: auto;
    max-width: ${({ width }) => width || '245px'};

    ${mobile`
      max-width: 145px;
    `}
  `,
  anniversaryLogo: css`
    height: auto;
    width: 222px;

    ${mobile`
      width: 180px;
    `}
  `,
  favorites: {
    gridWrapper: css`
      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal20};
      border-radius: ${({ theme }) => `${theme.sizes.sm}px`};
    `,
    addToCartCta: css`
      & > span {
        ${({ theme }) => theme.typography.values.Body14};
        font-weight: 600;
      }
    `,
  },
  reviewsModal: {
    header: {
      count: css`
        font-size: 14px;
        ${desktop`
          text-decoration: underline;
        `}
      `,
      text: css`
        font-size: 14px;
        ${desktop`
          font-size: 16px;
        `}
      `,
    },
  },
  reviewStyles: {
    radioBarContainerStyle: `
      column-gap: 16px;
    `,
    radioBarStyle: `
      border-radius: 0;
      height: 12px;
    `,
    radioLabelStyle: css`
      ${({ theme }) => theme.typography.values.paragraph1};
      font-weight: 600;
    `,
    ctaButtonContainerStyle: css`
      ${mobile`
        flex-direction: column;
      `}
    `,
    ctaButtonStyle: css`
      flex: 1;
    `,
  },
  account: {
    pageHeadingSize: '24px',
    myVipStyles: {
      bannerBackground: css`
        background: ${({ theme }) => theme.colors.palette.gunmetal90};
      `,
      rewardSummaryStyle: `
        width: 75%;
        border-radius: 8px;
      `,
      cancelContent: css`
        color: ${({ theme }) => theme.colors.palette.gunmetal65};
      `,
      contactChatStyle: css`
        color: ${({ theme }) => theme.colors.focusText};
      `,
      itemContentTextStyle: css`
        font-size: 16px;
        color: ${({ theme }) => theme.colors.textEmphasizedAlternate};
      `,
      itemContentTextEmphasizedStyle: css`
        color: ${({ theme }) => theme.colors.textEmphasizedAlternate};
        font-weight: 700;
      `,
      vipTreatment: {
        mainContainer: css`
          padding-bottom: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
          border-bottom: 1px solid
            ${({ theme }) => theme.colors.palette.gunmetal25};
          font-family: ${({ theme }) => theme.fontStacks.baseFamily};
          text-transform: capitalize;
          width: 235px;
          cursor: pointer;
          display: flex;
          ${mobile`
            width: 100%;
            padding: 15px 25px;
          `}
        `,
        sections: css`
          padding: ${({ theme }) => theme.spacing.sizes.pixels.xxs}px;
          border-bottom: 1px solid
            ${({ theme }) => theme.colors.palette.gunmetal25};
          font-family: ${({ theme }) => theme.fontStacks.baseFamily};
          display: flex;
          ${mobile`
          margin: 0 15px;
          `}
        `,
        subTitle: css`
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          ${mobile`
            text-decoration: underline;
          `}
        `,
        title: css`
          font-weight: 700;
          font-size: 14px;
        `,
        sectionTitle: css`
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 5px;
        `,
        sectionDescription: css`
          font-size: 12px;
          font-weight: 400;
        `,
        learnMoreButton: css`
          span {
            font-weight: 600;
            font-size: 14px;
          }
          border-radius: 4px;
          margin: 12px 50px;
          height: 34px;
          min-width: 126px;
          ${mobile`
            margin: 12px 35%;
          `}
        `,
      },
    },
    myVipModalStyles: {
      modalContentBorderRadius: css`
        ${mobile`
          border-radius: 0 0 0 0;
        `}
      `,
      buttonResize: css`
        width: calc(100% - 5px);
        margin: 0 auto;
        height: 45px;
      `,
      OrdersModalButtonResize: css`
        padding: 10px;
        flex: 0 0 max(110px, 25%);
      `,
      modalContentWidth: css`
        width: 60%;
        margin: 0 auto;
        ${mobile`

      `}
      `,
      overlayColor: css`
        overlaycolor: ${({ theme }) => theme.colors.palette.black60};
      `,
      closeButton: css`
        cursor: pointer;
        position: absolute;
        display: flex;
        width: 56px;
        height: 56px;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        padding: 8px;
        border-width: 0;
        color: ${({ theme }) => theme.colors.palette.gunmetal50};
        line-height: 0;
        ${desktop`
          top: 14px;
          right: 14px;
        `}
      `,
    },
    payments: {
      cardHoldersName: css`
        font-size: 18px;
      `,
      paymentMethod: {
        infoLabel: css`
          font-size: 18px;
          font-weight: 600;
        `,
      },
    },
  },
  footerStyles: {
    columnsStyle: (columnClass, sectionContainerClass) => {
      // On mobile we need to target the first-child Column and its
      // first-child SectionsContainer. This has to occur in the styles
      // of the top-level Columns element to avoid affecting all Column and
      // SectionsContainer components.
      return css`
        ${mobile`
          & > ${columnClass}:first-child > ${sectionContainerClass}:first-child {
            border-top: 1px solid ${({ theme }) => theme.colors.focusText};
          }
       `}
      `;
    },
    dividerStyle: css`
      width: 100%;
      margin: ${({ theme }) => theme.sizes.xxxl}px 0 0;
      border: none;
      border-top: 1px solid ${({ theme }) => theme.colors.focusText};

      ${mobile`
        display: none;
      `}
    `,
    freeShippingDetailsStyle: css`
      padding-top: 32px;
    `,
    sectionStyle: css`
      ${mobile`
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.focusText};
      `}
    `,
    sectionTitleStyle: css`
      text-transform: uppercase;
      margin: 0;

      color: ${({ theme }) => theme.colors.textLight};
      ${({ theme }) => theme.typography.values.caption};
      font-weight: 700;

      ${mobile`
        font-weight: 600;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
      `}

      &&& > * {
        ${({ theme }) => theme.typography.values.caption}
        font-weight: inherit;
        font-size: 14px;
      }
    `,
    sectionMenuLinkStyle: css`
      display: inline-block;
      font-weight: 400;
      ${({ theme }) => theme.typography.values.paragraph1};

      ${({ $isLinkHeader }) =>
        $isLinkHeader &&
        css`
          &:link:hover [data-nav-item-label] {
            text-decoration: underline;
          }
        `}

      ${desktop`
        &:link:hover [data-nav-item-label] {
          text-decoration: underline;
        }
      `}

      ${mobile`
        font-weight: 600;
        ${({ theme }) => theme.typography.values.caption};
        :link {
          display: flex;
          align-items: center;
        }
      `}
    `,
    adaWidgetButtonCopy: css`
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: ${({ theme }) => theme.colors.palette.gunmetal};

      display: inline-block;
      padding-bottom: 3px;

      border-bottom: 1px solid transparent;
      transition: border-bottom 300ms linear;

      ${desktop`
        &:hover {
          border-bottom: 1px solid ${({ theme }) => theme.colors.palette.black};
        }
      `}
    `,
    socialIconWrapperStyle: css`
      padding-top: ${({ theme }) => theme.sizes.md}px;
      padding-bottom: ${({ theme }) => theme.sizes.md}px;
    `,
  },
  gridCellStyles: {
    defaultPriceStyle: css`
      ${({ theme }) => theme.typography.values.heading5};
      color: ${({ theme }) => theme.colors.palette.gunmetal65};
      font-size: 14px;
      font-weight: normal;
    `,
    priceStyles: css`
      font-size: 14px;
      font-weight: 600;

      color: ${({ theme }) => theme.colors.palette.gunmetal40};
    `,
    vipPriceStyles: css`
      font-size: 14px;
      font-weight: 600;

      color: ${({ theme }) => theme.colors.palette.gunmetal};
    `,
    defaultPromoPriceStyle: css`
      color: ${({ theme }) => theme.colors.palette.gunmetal};
      font-weight: 600;
      font-size: 14px;
    `,
    moreColorsLink: css`
      ${({ theme }) => theme.typography.values.paragraph1};
      color: ${({ theme }) => theme.colors.palette.gunmetal65};
      font-weight: 600;
    `,
    priceContent: css`
      line-height: 1.4;
      font-size: 14px;
    `,
    productCellWrapper: css`
      font-size: 14px;
      position: relative;
      text-align: left;
      min-width: 100%;
    `,
    productCalloutWrapper: css``,
    productName: css`
      ${({ theme }) => theme.typography.values.paragraph2};
      color: ${({ theme }) => theme.colors.palette.gunmetal};
      font-weight: 500;
      line-height: 1.4;
      font-size: 14px;
      margin-bottom: 6px;
    `,
    promoMessage: css`
      ${({ theme }) => theme.typography.values.paragraph1};
      color: ${({ theme }) => theme.colors.palette.gunmetal70};
      hyphens: auto;
      overflow-wrap: break-word;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 6px;
      display: inline-block;
      ${mobile`
        margin-bottom: 6px;
      `}
    `,
    gridColorSwatchWrapper: css`
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 4px;
    `,
    gridSwatchInputStyle: css`
      width: 32px;
      height: 32px;
      ${mobile`
         width: 26px;
         height: 26px;
         padding-right: 6px;
      `}
    `,
    calloutText: css`
      color: ${({ theme }) => theme.colors.palette.teal120};
      font-weight: 400;
    `,
    upsellCarousel: {
      productImageWrapper: css`
        margin-bottom: 8px;
      `,
      productName: css`
        margin-bottom: 6px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.palette.gunmetal};
      `,
      promoMessage: css`
        font-size: 16px;
      `,
      promoPrice: css`
        color: ${({ theme }) => theme.colors.palette.gunmetal};
        font-size: 16px;
        font-weight: 600;
      `,
    },
    swatches: {
      swatchLabel: css`
        font-weight: 400;
        color: ${({ theme }) => theme.colors.palette.black};
      `,
      swatchColorLabel: css`
        font-size: 14px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.palette.black};
      `,
    },
  },
  gridFilterStyles: {
    listingFilterStyle: css`
      font-family: ${({ theme }) => theme.fontStacks.navBarFamily};
    `,
    nestedHeadingStyle: css`
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.palette.gunmetal65};
    `,
    filterFieldLabelStyle: css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.focusText};
    `,
    filterCountStyle: css`
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.palette.gunmetal65};
    `,
    inputStyle: css`
      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal25};
      padding: 4px;
    `,
    inputCheckedStyle: css`
      border-color: ${({ theme }) => theme.colors.focusText};
      background: ${({ theme }) => theme.colors.focusText};
    `,
    tagStyle: css`
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.palette.stone80};
      font-weight: 600;
      color: ${({ theme }) => theme.colors.focusText};
    `,
    deselectIconStyle: css`
      color: ${({ theme }) => theme.colors.palette.gunmetal45};
    `,
    circleIconStyle: css`
      border: solid 1px ${({ theme }) => theme.colors.palette.black};
    `,
    noFiltersAppliedStyle: css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.focusText};
    `,
    headerStyle: css`
      font-weight: 600;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.focusText};
    `,
    gridLabelStyle: css`
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.palette.black};
    `,
    gridInputStyle: css`
      border-radius: 4px;
      border: 1.5px solid ${({ theme }) => theme.colors.palette.black};
    `,
    swatchInputStyle: css`
      background-color: ${({ theme }) => theme.colors.palette.stone80};
    `,
    applyFiltersButtonStyle: css`
      background-color: ${({ theme }) => theme.colors.palette.black};
      border-radius: 4px;
      min-height: 48px;
      text-transform: uppercase;
    `,
    clearAllButtonStyle: css`
      color: ${({ theme }) => theme.colors.palette.gunmetal65};
    `,
    filterFieldButtonSvgStyle: css`
      font-size: 20px;
    `,
  },
  gridStyles: {
    categoryDropdownLinkStyle: css`
      font-size: 16px;
      text-align: center;
      color: ${({ theme }) => theme.colors.textDefault};
      box-shadow: none;
      padding: 19px;
      padding-left: 8px;

      &[data-active-category='true'] {
        border-radius: 4px;
        background-color: #eff0f0;
        color: inherit;
      }
    `,
    activeCategoryItemStyle: css`
      color: ${({ theme }) => theme.colors.textEmphasized};
      font-weight: 600;
    `,
    categoryItemStyle: css`
      ${({ theme }) => theme.typography.values.paragraph1};
      color: ${({ theme }) => theme.colors.palette.gunmetal80};
      font-weight: 400;
    `,
    categoryTree: css`
      ${({ theme }) => theme.typography.values.paragraph1};
      color: ${({ theme }) => theme.colors.linkText};
      font-weight: 550;
      margin-bottom: 32px;
    `,
    topLevelCategoryHeading: css`
      ${({ theme }) => theme.typography.values.paragraph2};
      color: ${({ theme }) => theme.colors.focusText};
      font-weight: 600;
      margin-bottom: 9px;
    `,
    gridCtaButtonStyle: css`
      ${({ theme: { colors, sizes } }) => `
        background: ${colors.palette.black};
        color: ${colors.palette.white};
        padding: ${sizes.sm}px 56px;
        border-radius: ${sizes.xxs}px;
      `}

      ${({ theme: { colors, fontStacks, sizes }, $isCarouselGrid }) =>
        $isCarouselGrid &&
        `
          background: ${colors.palette.white};
          color: ${colors.palette.black};
          padding: ${sizes.xxs}px 46px;
          border-radius: ${sizes.xxxs}px;
          border: 1px solid ${colors.palette.black};

          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        `}
    `,
    gridCarouselLabelStyle: css`
      text-transform: uppercase;
      font-weight: 800;
      font-size: ${({ theme: { sizes } }) => sizes.lg}px;
      line-height: ${({ theme: { sizes } }) => sizes.xxl}px;
      color: ${({ theme }) => theme.colors.textEmphasized};

      ${mobile`
        font-style: normal;
        font-size: 24px;
        margin: ${({ theme: { sizes } }) => sizes.sm}px;
        display: block;
      `}
    `,
    gridCarouselViewAllCta: css`
      border: none;
      color: ${({ theme }) => theme.colors.textEmphasized};
      font-weight: 800;
      font-size: ${({ theme: { sizes } }) => sizes.md}px;
      line-height: 24px;
      text-underline-offset: ${({ theme: { sizes } }) => sizes.xxxs}px;
      text-decoration: underline;
      background: transparent;
    `,
    sortLabel: css`
      font-weight: 600;
    `,
    filterDrawerButton: css`
      font-weight: 600;
    `,
  },
  gridHeaderStyle: css`
    font-size: 24px;
    font-weight: bold;
    flex-grow: 1;
    ${mobile`
      display: none
    `};
  `,
  mobileGridHeaderStyle: css`
    ${mobile`
      font-size: 24px;
    `}
  `,
  mobileProductListingFilterStyles: {
    gridInput: {
      border: '1.5px solid black',
    },
  },
  billMeNow: {
    BorderRadius: css`
      border-radius: 3px;
    `,
    paymentListRadioStyle: css`
      input:checked + && {
        background: ${({ theme }) => theme.colors.focusText};
        color: ${({ theme }) => theme.colors.textLight};
        border: 0.88px solid ${({ theme }) => theme.colors.focusText};
      }
    `,
    confirmationWrapper: css`
      padding: ${({ theme }) => theme.spacing.sizes.pixels.xl}px
        ${({ theme }) => theme.spacing.sizes.pixels.xxl}px;
      ${mobile`
        padding: 36px 15px;
      `}
    `,
    confirmationPayment: {
      titleBoldness: css`
        font-weight: 600;
      `,
      amntDivPadding: css`
        padding: 16px;
      `,
      bmnBtn: css`
        background: ${({ theme }) => theme.colors.focusText};
        padding: 0;
      `,
      paymentMethodImg: css`
        height: 21px;
        width: 33px;
      `,
    },
    modal: {
      title: css`
        text-transform: uppercase;
      `,
    },
  },
  onlineCancelStyling: {
    ribbonContainer: css`
      background: ${({ theme }) => theme.colors.palette.gunmetal15};
    `,
    pauseBanner: css`
      background: ${({ theme }) => theme.colors.palette.gunmetal15};
    `,
    offerCallOut: css`
      background: ${({ theme }) => theme.colors.palette.gunmetal25};
      color: ${({ theme }) => theme.colors.textDefault};
    `,
    offerCta: css`
      text-transform: uppercase;
      font-size: 14px;
      background: ${({ theme }) => theme.colors.palette.black};
      color: ${({ theme }) => theme.colors.textLight};
      border: none;
      border-radius: 3px;
      padding: 10px 30px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        filter: brightness(0.9) contrast(1.1);
      }
    `,
    areYouSureHeaderStyling: css`
      padding-top: 12px;
    `,
    cancelConfirmBtnStyle: css`
      width: 40%;
      font-size: 18px;

      color: ${({ theme }) => theme.colors.palette.white};
      background-color: ${({ theme }) => theme.colors.palette.gunmetal};

      &:hover {
        background-color: ${({ theme }) => theme.colors.palette.black};
      }

      ${({ disabled }) =>
        disabled &&
        css`
          color: ${({ theme }) => theme.colors.palette.gunmetal40};
          background-color: ${({ theme }) => theme.colors.palette.gunmetal20};

          &:hover {
            color: ${({ theme }) => theme.colors.palette.gunmetal40};
            background-color: ${({ theme }) => theme.colors.palette.gunmetal20};
          }
        `}
    `,
    ctaNeutral: css`
      color: ${({ theme }) => theme.colors.palette.black};
      background-color: ${({ theme }) => theme.colors.palette.stone};

      &:hover {
        background-color: ${({ theme }) => theme.colors.palette.stone110};
      }

      font-size: 14px;
    `,
    ctaActive: css`
      color: ${({ theme }) => theme.colors.palette.white};
      background-color: ${({ theme }) => theme.colors.palette.stone120};

      &:hover {
        background-color: ${({ theme }) => theme.colors.palette.stone120};
      }
    `,
    ctaDisable: css`
      color: ${({ theme }) => theme.colors.palette.black};
      background-color: ${({ theme }) => theme.colors.palette.stone};

      > span {
        color: ${({ theme }) => theme.colors.backgroundDark};
      }
    `,
    ctaDisableHover: css`
      color: ${({ theme }) => theme.colors.palette.black};
      background-color: ${({ theme }) => theme.colors.palette.stone110};
    `,
    wrapperActive: css`
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
    `,
    wrapperNeutral: css`
      color: ${({ theme }) => theme.colors.backgroundDark};
    `,
    borderRadiusSize: css`
      border-radius: 5px;
    `,
    iconDisable: css`
      color: ${({ theme }) => theme.colors.palette.gunmetal65};
    `,
    iconActive: css`
      color: ${({ theme }) => theme.colors.palette.black};
    `,
    checkBoxImportant: css`
      background: ${({ theme }) => theme.colors.palette.black} !important;
      color: ${({ theme }) => theme.colors.textLight} !important;
    `,
    stillHaveQuestions: {
      contactButtons: css`
        color: ${({ theme }) => theme.colors.palette.black};
        background-color: ${({ theme }) => theme.colors.palette.stone};
        border: none;

        &:hover {
          background-color: ${({ theme }) => theme.colors.palette.stone110};
        }
      `,
    },
  },
  activateTrial: {
    submitButton: css`
      background: ${({ theme }) => theme.colors.alertRed};
      border: ${({ theme }) => theme.colors.alertRed};
      &:hover {
        background: ${({ theme }) => theme.colors.alertRed};
      }
    `,
  },
  quiz: {
    answers: css`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 24px;
      ${mobile`
        height: calc(100% - 94px);
        max-height: 70vh;
        width: 100%;
        padding: 16px 24px 24px 24px;
        flex-flow: column nowrap;
        justify-content: flex-start;
        margin: 0;
      `}
    `,
    answerButton: css`
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      border-radius: 8px;
      ${desktop`
        height: 147px;
        max-width: 324px;
        margin: 8px;
      `}
      ${mobile`
        max-height: 137px;
        flex-flow: row-reverse nowrap;
        width: 100%;
        margin: 8px 0;
        `}
    `,
    answerRadio: css`
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      border-radius: 8px;
      ${desktop`
      height: 147px;
      max-width: 324px;
      margin: 8px;
    `}
      ${mobile`
      min-height: 74px;
      max-height: 137px;
      flex-flow: row-reverse nowrap;
      width: 100%;
      margin: 8px 0;
      `}
    `,
    answerFocused: css`
      outline-color: ${({ theme }) => theme.colors.palette.gunmetal};
    `,
    answerButtonWrapper: css``,
    answerTextPaddingM: css``,
    answerWrapper: css``,
    assetWrapper: css``,
    backButton: css`
      color: ${({ theme }) => theme.colors.closeButton};
      ${mobile`
        font-size: 24px;
      `}
    `,
    closeButton: css`
      color: ${({ theme }) => theme.colors.closeButton};
    `,
    defaultRegistrationFieldsColumn: css`
      & > div {
        display: flex;
        flex-direction: column;
        position: relative;
        column-gap: 10px;
        row-gap: 24px;

        ${mobile`
          row-gap: 8px;
        `}

        > div:last-of-type,
        > div:first-of-type {
          border-radius: ${({ theme }) => theme.borderRadiusInput};
          border: 1px solid ${({ theme }) => theme.colors.focusBorder};
        }

        > div {
          border: 1px solid ${({ theme }) => theme.colors.focusBorder};
          border-radius: ${({ theme }) => theme.borderRadiusInput};
          height: ${({ showZip }) => (showZip ? '48px' : '72px')};

          ${mobile`
            height: ${({ showZip }) => (showZip ? '56px' : '72px')};
          `}

          > fieldset {
            padding: 0;

            &:focus-within {
              padding: 0;
            }

            label {
              color: ${({ theme }) => theme.colors.registrationFieldsLabel};
            }
          }
        }

        > div:last-of-type.has_error {
          border-top-width: 1px;
        }

        > div:first-of-type.has_error {
          border-bottom-width: 1px;
        }
      }
    `,
    editButton: css`
      text-decoration: underline;
    `,
    errorPosition: css`
      margin-top: -20px;
      padding: 25px 6px 8px 6px;
    `,
    TieredQuizDropdownContentWrapper: css`
      padding-left: 12px;
      ${({ $isDropdownOpen }) =>
        $isDropdownOpen &&
        css`
          border-bottom-left-radius: 0px !important; /* This will be removed when we have a better solution for the dropdown (in HDYH round 2 work) */
          border-bottom-right-radius: 0px !important; /* This will be removed when we have a better solution for the dropdown (in HDYH round 2 work) */
        `}

      ${mobile`
        border-top: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      `}
    `,
    /* Main wrapper for the HDYH dropdown */
    HowDidYouHearDropdownWrapper: css`
      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal25};
      ${({ $isDropdownOpen }) =>
        $isDropdownOpen &&
        css`
          border-bottom-left-radius: 0px !important; /* This will be removed when we have a better solution for the dropdown (in HDYH round 2 work) */
          border-bottom-right-radius: 0px !important; /* This will be removed when we have a better solution for the dropdown (in HDYH round 2 work) */
        `}
      ${desktop`
        #hdyh-quiz-dropdown-native-select {
          height: 46px;
        }
      `}
    `,
    quizDropdownContentContainer: css``,
    fieldWrapper: css`
      position: relative;

      ${mobile`
        #topSize,
        #bottomSize,
        #braletteSize,
        #lingerieSize,
        #bandSize,
        #cupSize,
        #braSize,
        #referrer /* Note: If HDYH-tier-list wins, this 'referrer' will be removed. Right now it doesn't do anything that I can see anyway. */
        {
          ${({ theme: { colors, borderRadiusInput }, showZip }) => `
            height: ${showZip ? '56px' : '72px'};
            border-top: 1px solid ${colors.borderLight};
            border-left: 1px solid ${colors.borderLight};
            border-right: 1px solid ${colors.borderLight};
            border-bottom: 1px solid ${colors.borderLight};
            background-color: ${colors.palette.white};
            border-radius: ${borderRadiusInput};
          `}

          :focus {
            border-color: ${({ theme }) => theme.colors.focusColor};
          }

          &:has(option[value=""]:not(:checked)) ~ label {
            top: 0;
            left: 0;
          }

          label {
            padding-left: 8px;
          }
        }

        #bottomSize.has_error,
        #topSize.has_error,
        #braSize.has_error,
        #referrer.has_error {
          border-color: ${({ theme }) => theme.colors.error};
        }
      `}
    `,
    modalDialog: css`
      min-width: 610px;
      max-width: 832px;
    `,
    overlayStyle: css`
      background-color: transparent !important;
    `,
    sizingPageBottomForm: css`
      #sizing_location_field_wrapper {
        width: 100%;
        display: flex;
        gap: 10px;

        & > div {
          flex: 1;
          border-radius: ${({ theme }) => theme.borderRadiusInput};
          max-height: 72px;

          ${({ showZip }) =>
            !showZip &&
            css`
              border-bottom: none;
            `};

          ${mobile`
            margin-bottom: 0;
          `}
        }

        & > span {
          position: relative;
          max-height: 72px;
          margin-bottom: 16px;

          & > fieldset {
            position: absolute;
            bottom: 0;

            & > select {
              height: ${({ showZip }) => (showZip ? '48px' : '46px')};
            }
          }
        }
      }
    `,
    quizBirthdayABTestStyles: {
      formFieldWrapper: css`
        flex-direction: column;
        padding-bottom: ${({ theme }) => theme.spacing.sizes.pixels.l}px;
      `,
      zipCodeAndBirthdayWrapper: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: ${({ theme }) => theme.spacing.sizes.pixels.m}px;

        > :first-child {
          flex: 1;
        }

        > :last-child {
          flex: 2;
        }
      `,
    },
    navBarWrapper: 'padding: 16px;',
    builderNoAssetAnswer: css`
      display: flex;
      justify-content: center;
      align-items: center;
      ${({ theme: { typography } }) => typography.variants.quizAnswer.textStyle}
      width: 100%;
      span {
        padding: 0;
      }
      ${mobile`
        height: unset;
        margin: 0;
        min-height: 74px;
        max-height: 137px;
      `}
      ${desktop`
        &:hover {
          color: ${({ theme: { colors } }) => colors.quizTextDark};
        }
      `}
    `,
    noAssetAnswer: css`
      display: flex;
      justify-content: center;
      align-items: center;
      ${({ theme: { typography } }) => typography.variants.quizAnswer.textStyle}
      width: 100%;
      span {
        padding: 0;
      }
      ${mobile`
        height: unset;
        margin: 0;
        min-height: 74px;
        max-height: 137px;
      `}
      ${desktop`
        height: 147px;
        &:hover {
          color: ${({ theme: { colors } }) => colors.quizTextDark};
        }
      `}
    `,
    panelStyle: css`
      ${mobile`
        height: 100%;
      `}
    `,
    multiSelectAnswerButton: css``,
    multiSelectAnswerText: css``,
    multiSelectAnswerTextFocused: css``,
    progressBar: css`
      width: 100%;
      height: 4px;
      display: flex;
      background-color: ${({ theme }) => theme.colors.palette.white};
    `,
    barStyle: css`
      margin: 0 1px;
      ${({ $filled }) =>
        $filled
          ? css`
              background-color: ${({ theme }) => theme.colors.palette.black};
            `
          : css`
              background-color: ${({ theme }) => theme.colors.demureBorder};
            `}
    `,
    questionStyle: css`
      ${({ theme: { typography } }) => typography.values.heading6};
      flex: 1;
      line-height: 1.25;
      padding: 16px 0 4px 0;
      ${mobile`
        padding: 16px 24px 4px 24px;
        margin-top: 16px;
      `}
    `,
    questionTitleStyle: css`
      ${({ theme: { typography } }) => typography.values.heading6};
      flex: 1;
      line-height: 1.25;
      padding: 16px 0 4px 0;
      ${mobile`
        padding: 16px 24px 4px 24px;
        margin-top: 0px;
      `}
    `,
    questionSubtitle: css`
      ${mobile`
        padding: 0 24px;
      `}
      ${({ theme: { typography } }) => typography.values.paragraph1};
    `,
    quizDropdownButtonBorder: css``,
    dropdownButtonBorder: css`
      border: ${({ theme }) => theme.quiz.quizBorder} solid
        ${({ theme }) => theme.colors.focusBorder};
    `,
    quizSizingReferrerButton: css`
      &&& {
        flex: 1;
        min-height: unset;
        height: ${({ showZip }) => (!showZip ? '72px' : '48px')};
        padding: 0 8px 8px 8px;
      }
    `,
    quizLocationReferrerButton: css`
      ${mobile`
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-radius: ${({ theme }) => theme.borderRadiusInput};
        border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
      `}
    `,
    quizBorder: '1px',
    quizTieredDropdownMobileLeftPadding: '0px',
    quizLayoutWrapper: css`
      ${mobile`
        height: ${({ $hasHeader }) =>
          $hasHeader ? 'calc(100% - 74px)' : '100%'}};
        flex-flow: column nowrap;
      `}
    `,
    quizLayout: css`
      // cant use vh on mobile bc there is a bug making it flow over the max vh
      ${mobile`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        ${({ didSkip }) =>
          didSkip &&
          `
          overflow-y: auto;
        `}
      `}
    `,
    quizNavLogo: css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      ${({ theme: { typography } }) => typography.values.paragraph1};
      color: ${({ theme }) => theme.colors.textLightAlternate};
      svg {
        height: 24px;
        width: auto;
      }
      ${desktop`
        gap: 16px;

        svg {
          height: 28px;
        }
      `}
    `,
    quizNextButton: css`
      align-self: center;
      max-width: 158px;
      width: 100%;
    `,
    quizPageContainer: css`
      ${desktop`
        min-width: 730px;
        padding: 0 24px;
      `}
      ${mobile`
        margin-bottom: 24px;
        height: 100%;
        flex-flow: column nowrap;
      `}
    `,
    quizPageRegistrationWrapper: css`
      min-width: unset;
      padding: 0;
      margin: 0;
      ${mobile`
            height: 100%;
            overflow-y: auto;
          `}
      width: 100%;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
    `,
    quizSizingRegPadding: css`
      ${desktop`
        padding: 32px 0 0 0;
      `}
    `,
    quizSizingHeader: css`
      ${mobile`
        padding: ${sizes.md}px;
      `}
    `,
    quizSizingBlock: css`
      width: 89px;
      height: 35px;
      background: ${({ theme }) => theme.colors.palette.gunmetal30};
      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal30};
      border-radius: 3px;
      ${({ $selected }) =>
        $selected &&
        css`
          border-color: ${({ theme: { colors } }) => colors.palette.black};
        `}
    `,
    quizSizingInnerWrapper: css`
      ${mobile`
        height: 100%;
      `}
    `,
    quizSizingZipCodeWrapperStyles: css`
      &&& {
        border-radius: ${({ theme }) => theme.borderRadiusInput};

        fieldset {
          max-height: 46px;
          border-radius: ${({ theme }) => theme.borderRadiusInput};
        }
      }
    `,
    quizSizingZipCodeErrorWrapperStyles: css``,
    quizSizingZipCodeErrorTextStyles: css`
      color: ${({ theme: { colors } }) => colors.error};
    `,
    regButtonMargin: '24px 0 24px 0',
    regFormFields: css`
      > div {
        height: 65px;
      }

      > div:first-of-type {
        ${({ theme: { colors, quiz } }) => `
          border-bottom: ${quiz.quizBorder} solid ${colors.palette.gunmetal25};
          &.has_error {
            border-color: ${colors.error};
          }
        `}
      }
      > div:last-of-type {
        ${({ theme: { colors, quiz } }) => `
          border-top: ${quiz.quizBorder} solid ${colors.palette.gunmetal25};
          &.has_error {
            border-color: ${colors.error};
          }
        `}
      }
    `,
    registrationFormWrapper: css`
      display: flex;
      flex-flow: column nowrap;
      margin: 32px auto 0 auto;
      width: 95%;
      max-width: 265px;

      ${mobile`
        width: 100%;
        max-width: 100%;
        margin-top: 16px;
      `}
    `,
    registrationForm: css`
      ${mobile`
        ${({ didSkip }) =>
          !didSkip &&
          `
            width: 100%;
            padding: 0 32px;
          `}
      `}
    `,
    regPageHeight: '100%',
    registrationPage: css`
      ${desktop`
        flex: 1 1 50%;
        max-width: 320px;
        justify-content: space-between;
        ${({ didSkip }) =>
          didSkip
            ? `
                width: 100%;
                max-width: unset;
                > div > form {
                  width: 100%;
                  padding: 0 32px;
                }

                h2 {
                  margin-top: 16px;
                }
              `
            : `
            h2 {
              margin-top: 32px;
            }
          `}

        ${({ $hasDesktopImage }) =>
          $hasDesktopImage &&
          css`
            min-height: 586px;
          `}
      `}
      ${mobile`
        padding-top: 24px;
      `}
    `,
    skippedRegistration: css`
      margin-top: 32px;
    `,
    smSpacer: css`
      height: ${({ theme: { sizes } }) => sizes.lg}px;
    `,
    mdSpacer: css`
      height: 32px;
    `,
    termsAndPrivacy: css`
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      width: 100%;
      border-top: 2px solid ${({ theme }) => theme.colors.borderLight};
      a {
        ${({ theme: { typography } }) => typography.values.caption};
        font-weight: 600;
        padding: 12px 0;
      }
    `,
    zipCodeInput: css`
      ${desktop`
        margin: 0;
        flex: 0 1 50%;
        max-height: 48px;
        border-radius: ${({ theme }) => theme.borderRadiusInput};
        border: ${({ theme }) => theme.quiz.quizBorder} solid
          ${({ theme }) => theme.colors.focusBorder};
      `}
    `,
  },
  sweepstakeForm: {
    textFieldBorder: '1px',
    regFormFields: css`
      > div:first-of-type {
        ${({ theme: { colors, sweepstakeForm } }) => `
          border-bottom: ${sweepstakeForm.textFieldBorder} solid ${colors.palette.gunmetal25};
          &.has_error {
            border-color: ${colors.error};
          }
        `}
      }
      > div:last-of-type {
        ${({ theme: { colors, sweepstakeForm } }) => `
          border-top: ${sweepstakeForm.textFieldBorder} solid ${colors.palette.gunmetal25};
          &.has_error {
            border-color: ${colors.error};
          }
        `}
      }

      label[data-label-position='floating'] {
        margin: 6px 8px 0 8px;
      }

      label[data-label-position='inside'] {
        font-size: 12px;
        margin-top: 6px;
      }

      input {
        font-size: 16px;
        padding: 6px 8px;
      }
    `,
    successPanel: {
      title: css`
        ${({ theme }) => theme.typography.values.heading3};
        margin: ${({ theme: { sizes } }) => sizes.xxs}px 0;
        font-weight: 700;
        text-align: center;
      `,
      postTitleText: css`
        ${({ theme }) => theme.typography.values.paragraph2};
        margin: ${({ theme: { sizes } }) => sizes.xxs}px 0;
        font-style: normal;
        font-weight: 400;
        text-align: center;
      `,
    },
  },
  countdownStyles: {
    postreg: {
      text: css`
        font-size: 18px;
      `,
    },
    sweepstake: {
      timer: {
        label: css`
          color: ${({ theme }) => theme.colors.palette.white};
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 1;
          padding-top: 24px;
        `,
        value: css`
          background: transparent;
          font-weight: 700;
          color: ${({ theme }) => theme.colors.palette.white};

          font-size: 75px;
          line-height: 1;
          ${mobile`
            font-size: 30px;
          `}
        `,
      },
      block: css`
        padding: 24px 8px 12px;
        height: 132px;
        max-width: 97px;
        background: ${({ theme }) => theme.colors.palette.black};
        border-radius: 4px;
        justify-content: center;

        box-shadows: 0px 4px 4px rgb(0 0 0 / 15%);

        ${mobile`
          height: 92px;
          max-width: 68px;
        `}
      `,
      blockList: css`
        margin: auto;
        ${mobile`
          max-width: 300px;
        `}

        display: grid;
        grid-template-areas: 'days hrs min sec';
        grid-column-gap: 15px;
        grid-template-columns: repeat(4, 1fr);

        ${mobile`
          grid-column-gap: 10px;
        `}
      `,
    },
  },
  marketingCards: {
    variants: {
      default: {
        cardsHeaderTitle: css``,
        cardsContainer: css``,
        cardVariant: 'VIPHomepage',
      },
      fableticsCares: {
        marketingCardsHeader: css`
          ${mobile`
            display: none;
          `}
        `,
        cardsHeaderTitle: css`
          color: ${({ theme: { colors } }) => colors.palette.stone};

          ${mobile`
            display: none;
          `}
        `,
        cardsContainer: css`
          grid-template-areas:
            'card_1 card_2'
            'card_3 card_4';

          grid-gap: 20px;
          grid-template-columns: repeat(2, 1fr);
          margin: 0 22px;

          ${desktop`
            grid-template-areas: 'card_1 card_2 card_3 card_4';
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 54px;
            grid-row-gap: 0px;
            max-width: 1042px;
          `}
        `,
        cardVariant: 'fableticsCares',
      },
      giftGuide: {
        marketingCardsHeader: css`
          height: 50px;
          width: 100%;
          margin: 0;

          ${mobile`
            display: none;
          `}
        `,
        cardsHeaderTitle: css`
          color: ${({ theme: { colors } }) => colors.palette.white};

          ${mobile`
            display: none;
          `}
        `,
        cardsContainer: css`
          grid-template-areas:
            'card_1 card_2'
            'card_3 card_4';

          grid-gap: 20px;
          grid-template-columns: repeat(2, 1fr);
          margin: 0 22px;

          ${desktop`
            grid-template-areas: 'card_1 card_2 card_3 card_4';
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 24px;
            grid-row-gap: 0px;
            max-width: 1042px;
          `}
        `,
        cardVariant: 'giftGuide',
      },
    },
  },
  marketingCard: {
    variants: {
      default: {
        card: css``,
        cardTitle: css``,
        cardbody: css`
          min-height: 150px;
          padding: 21px 0 21px 12px;
        `,
        cardSubtitle: css``,
        cardImage: css`
          max-width: 100%;
        `,
      },
      VIPHomepage: {},
      fableticsCares: {
        card: css`
          grid-area: ${({ index }) => `card_${index + 1}`};
        `,
        cardBody: css`
          min-height: 150px;
          text-align: center;
          padding: 8px 0;
          min-height: 0;
        `,
        cardSubtitle: {},
        cardTitle: css`
          ${({ theme: { typography } }) => typography.values.heading3small};
          font-family: ${({ theme: { fontStacks } }) =>
            fontStacks.condensedTradeGothicFamily};
          color: ${({ theme: { colors } }) => colors.palette.stone};
          font-weight: 800;
          line-height: ${23 / 24};

          ${mobile`
          font-size: 22px;
          line-height: ${23 / 22};
        `}
          text-transform: uppercase;
        `,
        cardImage: css`
          max-width: 100%;
        `,
      },
      giftGuide: {
        card: css`
          background-color: ${({ theme: { colors } }) => colors.palette.white};

          grid-area: ${({ index }) => `card_${index + 1}`};
          min-width: 332px;
        `,
        cardBody: css`
          min-height: 150px;
          padding: 20px 0;
          min-height: 0;
        `,
        cardSubtitle: css`
          ${({ theme: { typography } }) => typography.values.heading4small}
          font-family: ${({ theme: { fontStacks } }) =>
            fontStacks.vipHomepageFamily};
          font-style: normal;
          font-weight: 500;
          text-transform: capitalize;

          color: ${({ theme: { colors } }) => colors.focusText};
          padding: 0 32px;
        `,
        cardTitle: css`
          ${({ theme: { typography } }) => typography.values.heading2small};
          line-height: ${42 / 28};
          font-weight: 800;
          font-family: ${({ theme: { fontStacks } }) =>
            fontStacks.condensedTradeGothicFamily};
          text-transform: uppercase;

          color: ${({ theme: { colors } }) => colors.focusText};
          padding: 0 32px;
        `,
        cardImage: css`
          max-width: 100%;
        `,
      },
    },
  },
  activeLineItemMiniCart: css`
    &.active {
      background: ${({ theme: { colors } }) => colors.demureBorder};
    }
  `,
  productRatingWrapper: css`
    margin: 8px 0;
  `,
  preOrderCallout: css`
    ${({ theme }) => theme.typography.values.Body12med}
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 600;
    background: ${({ theme: { colors } }) => colors.backgroundDark};
    width: fit-content;
    color: ${({ theme: { colors } }) => colors.textLight};
    text-transform: none;
  `,
  pdpMarketingCallout: css``,
  floatingFilterButton: css`
    background-color: ${({ theme }) => theme.colors.backgroundDark};
  `,
  tabsNode: {
    activeTabLabel: css`
      text-decoration: underline;
      text-underline-offset: 2px;
    `,
    bannerAriaHiddenWrapper: css``,
    bannerCopy: css``,
    buttonText: css`
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      white-space: nowrap;
    `,
    carousel: css`
      ${mobile`
        margin-bottom: 0px;
      `}
    `,
    countdown: css``,
    countdownDigits: css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.textDefault};
    `,
    countdownWrapper: css`
      ${mobile`
        margin-top: 0px;
     `}
    `,
    image: css`
      background: ${({ theme }) => theme.colors.backgroundLight};
      box-shadow: 0 0 16px -4px rgb(0 0 0 / 60%);
      margin-bottom: 8px;
    `,
    scrolledTabsWrapper: css`
      ${desktop`
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        padding: ${({ theme }) => theme.sizes.sm}px;
        width: 100%;
        justify-content: center;
      `}
    `,
    scrolledTabsSelectorWrapper: css`
      background: ${({ theme }) => theme.colors.tabBackground};
      padding: 6px;
    `,
    scrolledTabsSelectors: css`
      border-radius: ${({ theme }) => theme.borderRadius};
      margin: 0 4px;
    `,
    styledTabs: css``,
    postRegTabs: {
      floating: {
        navigation: {
          navigationWrapper: css`
            overflow-y: hidden;
            padding: 0 15px;
            background-color: ${({ theme }) => theme.colors.palette.white};
          `,
          styledNavigation: css`
            padding: 10px 0;
            overflow-x: auto;
            overflow-y: hidden;
            height: 100%;
          `,
          tabsWrapper: css`
            display: flex;
            flex-direction: row;
          `,
          countdownWrapper: css`
            position: fixed;
            display: block;
            width: 152px;
            right: 0;
            top: 480px;
          `,
          styledButton: css`
            height: 40px;
            min-width: 100px;
            border-radius: 20px;
            margin: 5px 15px 5px 0;
            border: ${({ theme }) => `1px solid ${theme.colors.palette.black}`};
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
          `,
          textContainer: css`
            position: relative;
            justify-content: center;
          `,
          styledText: css`
            position: relative;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            margin-left: 0;
            margin-top: 0;
          `,
        },
        countdown: {
          countdownTimerWrapper: css`
            position: fixed;
            right: 0;
            bottom: 100px;
            background-color: ${({ theme }) => theme.colors.palette.black};
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
          `,
          timerHeader: css`
            color: ${({ theme }) => theme.colors.palette.white};
            font-size: 6px;
            margin-right: 0;
          `,
          timerLabel: css`
            margin-top: 5px;
          `,
          countdownWrapper: css`
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            width: auto;
            height: auto;
          `,
          timerSeparator: css`
            color: ${({ theme }) => theme.colors.palette.white};
            margin: -8px 4px 0;
          `,
          time: css`
            color: ${({ theme }) => theme.colors.palette.white};
          `,
        },
      },
    },
  },
  fitGuideHeader: css`
    margin-top: 20px;
    margin-bottom: 18px;
    ${mobile`
    margin-top: 40px;
    margin-bottom: 16px;
  `}
  `,
  fitGuideTabButton: css`
    margin: 0 12px;
    padding: 5px 0;
    display: flex;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
    &.active {
      border-bottom-color: ${({ theme }) => theme.colors.textDefault};
      transition: border-bottom-color 500ms ease-in-out;
    }
  `,
  fitGuideDropdownMenuListItem: css`
    &.active {
      color: ${({ theme }) => theme.colors.unfocusedAction};
    }
  `,
  sizePreferencesDropdownList: css`
    &&& {
      max-height: 360px;
      overflow-y: scroll;
      z-index: 999;
      border-radius: 3px;
      padding-top: 0;
      top: 46px;
    }
    border-color: ${({ theme }) => theme.colors.palette.gunmetal25};
    color: ${({ theme: { colors } }) => colors.focusText};
  `,
  sizePreferencesDropdownListItem: css`
    border-color: ${({ theme }) => theme.colors.palette.stone110};
  `,
  hydrowDiscountModal: {
    modalWidth: 633,
    modalHeight: 386,
    modalStyles: css`
      width: ${({ theme }) => theme.hydrowDiscountModal.modalWidth}px;
      height: ${({ theme }) => theme.hydrowDiscountModal.modalHeight}px;
      position: relative;

      color: ${({ theme }) => theme.colors.palette.white};
    `,
    modalWrapperStyles: css`
      border-radius: 0px 0px ${({ theme }) => theme.sizes.xxs}px
        ${({ theme }) => theme.sizes.xxs}px;
    `,
    header: {
      headerHeight: 88,
      background: css`
        ${({ theme }) => theme.colors.palette.black}
      `,
    },
  },
  pdpScrollOffset: css``,
  pauseBillingStyling: {
    wrapperBorder: css`
      border: 3px solid ${({ theme: { colors } }) => colors.palette.gunmetal30};
    `,
    iconTextColor: css`
      color: #008000;
    `,
    pauseBillingButton: css`
      ${desktop`
      margin: 10px 10px 0 0;
      `}
      ${mobile`margin: 25px auto 0;`}
      background: ${({ theme }) => theme.colors.palette.white};
      padding: 5px 10px;
      height: auto;
      width: 75%;
      border: 2px solid ${({ theme }) => theme.colors.palette.gunmetal30};
      &:active {
        background: ${({ theme }) => theme.colors.palette.white};
        border: 2px solid ${({ theme }) => theme.colors.palette.black};
      }
      &:hover {
        background: ${({ theme }) => theme.colors.palette.white};
        border: 2px solid ${({ theme }) => theme.colors.palette.black};
      }
      > span {
        color: ${({ theme }) => theme.colors.palette.black};
      }
    `,
  },
  storeLocator: {
    announcement: css`
      color: ${({ theme }) => theme.colors.palette.red500};
    `,
    address: css``,
    phone: css``,
    locatorTop: css``,
    storeName: css``,
    locatorGridMobile: css``,
    locatorTopLeadMobile: css``,
    locatorButtonMobile: css``,
  },
  VariantVipContact: {
    contactButtonStyle: css`
      padding: 0 10px;
      border: 1px solid #000;
      border-radius: 5%;
      margin-bottom: 5px;
      width: 65%;
    `,
    ContactLightTextAnytime: {
      fontSize: css``,
    },
  },
  hypeDriver: {
    formStyle: css`
      background-color: ${({ theme }) => theme.colors.palette.white};
      border-radius: 8px;
      width: 500px;
      margin: 0 auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      color: ${({ theme }) => theme.colors.textDefault};
      ${mobile`
        width: 100%;
      `}
    `,
    textInputStyle: css`
      height: 48px;
      margin-bottom: 18px;
      fieldset {
        height: 50px;
        border-radius: 6px;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border};
        border-bottom: none;
      }
      ${mobile`
        font-size: 16px;
      `}
    `,
    buttonStyle: css`
      margin-top: ${({ theme }) => theme.sizes.lg}px;
      width: 100%;
      border-radius: 8px;
    `,
  },
  loyaltyCountDownStyle: {
    markup: css`
      background: ${({ theme }) => theme.colors.palette.lavender300};
      height: 20px;
      top: 2px;
    `,
    wrapperStyling: css`
      height: 80px;
      border: 1px solid ${({ theme }) => theme.colors.unfocusedSublabel};
    `,
    numericWrapperStyling: css`
      line-height: 20px;
    `,
    abbreviationStyling: css`
      line-height: 39px;
    `,
  },
  cashRewardGrid: {
    assetTextSize: css`
      font-size: 54px;
      line-height: 90px;
      padding: 15px;
    `,
    buttonStyling: css`
      padding: 10px 18px 10px 18px;
      border-radius: 4px;
    `,
    buttonActive: css`
      background: ${({ theme }) => theme.colors.backgroundDark};
      color: ${({ theme }) => theme.colors.backgroundLight};
      opacity: 1;
      &:hover {
        background: ${({ theme }) => theme.colors.backgroundDark};
        > span {
          color: ${({ theme }) => theme.colors.backgroundLight};
        }
      }
    `,
    buttonDisable: css`
      background: ${({ theme }) => theme.colors.unfocusedSublabel};
      > span {
        color: ${({ theme }) => theme.colors.backgroundLight};
      }
    `,
    lockIconStyling: css``,
    customLockIcon: css``,
    modalBackground: css`
      background: ${({ theme: { colors } }) => colors.palette.black};
    `,
    modalBorder: css`
      border: 2px solid ${({ theme }) => theme.colors.palette.white};
      border-radius: 4px;
      background: ${({ theme: { colors } }) => colors.palette.black};
    `,
    modalHeaderFontSize: css`
      font-size: 60px;
      ${mobile`
        font-size: 42px;
        margin: 15px auto 10px;
      `}
    `,
    modalSubHeaderStyling: css``,
    modalMessage: css`
      color: ${({ theme: { colors } }) => colors.palette.white};
      font-family: ${({ theme: { fontStacks } }) =>
        fontStacks.standardGtPressuraFamily};
      ${mobile`
        padding: 0 15px;
      `}
    `,
    modalRedeemButton: css`
      cursor: pointer;
      font-size: 20px;
      ${({ theme: { colors } }) => `
        background-color: ${colors.loyaltyActivePrimaryButton};
      `}

      ${({ isDisabled, hasPromo }) =>
        (isDisabled || hasPromo) &&
        css`
          ${({ theme: { colors } }) => `
            background-color: ${colors.loyaltyDisablePrimaryButton};
          `}
          cursor: not-allowed;
        `}

      ${({ isRedeemed }) =>
        isRedeemed &&
        css`
          font-size: 15px;
        `}

      &:hover {
        ${({ theme: { colors } }) => `
          background-color: ${colors.loyaltyDisablePrimaryButton};
        `}
      }

      &:focus {
        ${({ theme: { colors } }) => `
          background-color: ${colors.loyaltyDisablePrimaryButton};
        `}
      }
      color: ${({ theme: { colors } }) => colors.palette.black};
      width: 100%;
      margin: 21px auto;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      padding: 12px;
      border-radius: 4px;
    `,
  },
  miniCart: {
    itemCount: css`
      color: ${({ theme: { colors } }) => colors.palette.gunmetal90};
      font-weight: 400;
    `,
    itemQuality: {
      label: css`
        font-weight: 400;
        color: ${({ theme }) => theme.colors.palette.gunmetal90};
      `,
      name: css`
        font-weight: 700;
      `,
    },
    embroidery: {
      label: css`
        font-weight: 400;
        color: ${({ theme }) => theme.colors.palette.gunmetal90};
      `,
      name: css`
        font-weight: 700;
      `,
    },
    timer: {
      wrapper: css`
        background: ${({ theme }) => theme.colors.palette.stone};
      `,
      text: css`
        color: ${({ theme }) => theme.colors.palette.black};
      `,
      border: css`
        border: 3px solid ${({ theme }) => theme.colors.palette.black};
      `,
    },
    promo: css`
      font-weight: 700;
    `,
  },
  memberCreditCallout: {
    memberCreditCalloutWrapperMinicart: css`
      margin-top: 8px;
    `,
    memberCreditCalloutCopy: css`
      font-size: 12px;
      font-weight: 600;
      line-height: ${20 / 12};
      background-color: ${({ theme }) => theme.colors.palette.stone};
      border-radius: 4px;
      padding: 0px 8px;

      ${mobile`
        padding: 2px 8px;
      `}
    `,
  },
  byo: {
    pdpCtaTooltipTextWrapper: css`
      font-weight: 600;

      margin-top: 8px;
    `,
    minicartPromptCopy: css`
      font-size: 14px;
      font-weight: 700;
      line-height: ${24 / 17};
    `,
  },
  search: {
    featuredProductListItem: css`
      ${({ isHighlighted, theme }) =>
        isHighlighted && {
          outline: `2px solid ${theme.colors.palette.stone110}`,
        }};
    `,
    text: css`
      font-weight: 400;
    `,
  },
  promoMessage: {
    /**
     *  This overrides an !important coming from the CMS Asset tool for prioritized promos.
     *  The defined Asset for the prioritized promo, under use of featureNames.PRIORITIZED_PROMOS,
     *  has an HTML input text editor for the promo label entry. In some cases, the user could apply
     *  a paragraph (p) HTML element to the label entry, which comes with default styles applied
     *  per the HTML default styles spec, and an !important applied to that default style. The
     *  !important below overrides the CMS driven HTML input explained above.
     */
    overrideMarkupStylesFromCms: css`
      & > p {
        display: inline-block;
        margin-bottom: 0px !important;
      }
    `,
  },
  builderFooter: {
    footerLink: {
      bold: css`
        font-weight: 700;
      `,
      label: css``,
      link: css`
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: ${({ theme }) => theme.colors.palette.gunmetal};

        display: inline-block;
        padding-bottom: 3px;

        border-bottom: 1px solid transparent;
        transition: border-bottom 300ms linear;

        ${desktop`
          &:hover {
            border-bottom: 1px solid ${({ theme }) =>
              theme.colors.palette.black};
          }
        `}
      `,
      oneTrust: css`
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: ${({ theme }) => theme.colors.palette.gunmetal};

        display: inline-block;
        padding-bottom: 3px;

        border-bottom: 1px solid transparent;
        transition: border-bottom 300ms linear;

        ${desktop`
          &:hover {
            border-bottom: 1px solid ${({ theme }) =>
              theme.colors.palette.black};
          }
        `}
      `,
    },
  },
  builderHeader: {
    customColors: {
      sale: css`
        color: ${({ theme }) => theme.colors.palette.lavender700};
      `,
    },
    metaNav: css`
      background-color: ${({ theme }) => theme.colors.palette.stone80};
    `,
    metaNavItem: {
      hover: css`
        background-color: ${({ theme }) => theme.colors.palette.stone90};
      `,
    },
    countryMenuItem: css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.palette.stone80};
      }
    `,
    tertiaryNavItem: css`
      color: ${({ theme }) => theme.colors.palette.black};

      ${mobile`
        color: ${({ theme }) => theme.colors.palette.gunmetal90};
        background-color: ${({ theme }) => theme.colors.palette.stone90};
      `}
    `,
    navLink: {
      bold: css`
        font-weight: 700;
      `,
      wrapper: css`
        &:hover {
          background-color: ${({ theme }) => theme.colors.palette.stone90};
        }
      `,
      label: css`
        font-weight: 400;
      `,
      link: css`
        padding: 8px 10px;

        ${mobile`
          padding: 12px 46px;
        `}
      `,
      callout: {
        default: css`
          font-weight: 700;

          background-color: ${({ theme }) => theme.colors.palette.lavender300};
          padding: 3px 6px;
        `,
        customColors: {
          priority1: css`
            background-color: ${({ theme }) => theme.colors.palette.teal};
          `,
          priority2: css`
            color: ${({ theme }) => theme.colors.palette.white};
            background-color: ${({ theme }) =>
              theme.colors.palette.lavender700};
          `,
        },
      },
    },
    accountNavLink: {
      wrapper: css`
        &:hover {
          background-color: ${({ theme }) => theme.colors.palette.stone70};
        }
      `,
      link: css`
        font-weight: 400;
      `,
    },
    fallbackHeader: {
      wrapper: css`
        padding: 10px 32px;
        width: fit-content;

        ${mobile`
          padding: 24px;
          margin: 0 auto;
        `}
      `,
    },
    stickyContainer: {
      contents: css`
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.palette.gunmetal20};
      `,
    },
  },
  expansionPanel: {
    panelHeader: {
      primary: css`
        background-color: ${({ theme }) => theme.colors.palette.stone70};
        border-bottom: 2px solid ${({ theme }) => theme.colors.palette.stone};

        padding: 15px 32px 15px 16px;

        p {
          line-height 1;
        }
      `,
      secondary: css`
        background-color: ${({ theme }) => theme.colors.palette.white};
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.palette.gunmetal25};

        padding: 15px 32px 15px 32px;
      `,
      footer: css`
        background-color: ${({ theme }) => theme.colors.palette.black};
        border-bottom: 1px solid ${({ theme }) =>
          theme.colors.palette.gunmetal90};
        align-items: center;

        padding: 15px 32px 15px 16px;

        p {
          line-height 1;
        }
        svg {
          fill: white;
          font-size: 12px;
        }
      `,
    },
    panelLabel: {
      primary: css`
        color: ${({ theme }) => theme.colors.palette.black};
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
      `,
      secondary: css`
        color: ${({ theme }) => theme.colors.palette.gunmetal90};
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      `,
      footer: css`
        color: ${({ theme }) => theme.colors.palette.white};
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      `,
    },
    panelIcon: css`
      color: ${({ theme }) => theme.colors.palette.gunmetal65};
    `,
    panelWrapper: {
      footer: css`
        background: ${({ theme }) => theme.colors.palette.black};
      `,
    },
  },
  listColumn: {
    header: css`
      font-size: 16px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.palette.gunmetal90};

      padding: 0px 10px 10px 10px;
    `,
    separator: css`
      border-top: 1px solid ${({ theme }) => theme.colors.palette.stone};
    `,
    wrapper: {
      footer: css`
        padding-bottom: 0;
      `,
    },
    unorderedList: {
      footer: css``,
    },
  },
  errorBackground: css`
    background: repeating-linear-gradient(
      45deg,
      #fce1df,
      #fce1df 10px,
      #f3baba 10px,
      #f3baba 20px
    );
  `,
  sizeSelectionExpansionPanel: {
    sizeSelectionHeader: {
      header: css`
        color: ${({ theme }) => theme.colors.palette.black};
        padding: ${({ theme }) => theme.sizes.md}px;

        ${({ open, theme }) =>
          open &&
          css`
            padding-bottom: ${open ? theme.sizes.sm : theme.sizes.md}px;
          `}
      `,
      sizeHeader: css`
        color: ${({ theme }) => theme.colors.palette.gunmetal};
      `,
      editSizeButton: css`
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.palette.black};
      `,
    },
    sizeSelectionContent: {
      content: css`
        padding: ${({ theme }) => theme.sizes.md}px 0px;
        padding-top: unset;

        ${desktop`
        ${({ theme }) => `
          padding: ${theme.sizes.md}px;
        `}
      `}
      `,
      sizeButton: css`
        height: 35px;
        width: 134px;

        background: ${({ theme }) => theme.colors.palette.gunmetal30};
        border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal30};
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.palette.gunmetal15};

        ${({ $selected }) =>
          $selected &&
          css`
            border-color: ${({ theme: { colors } }) => colors.palette.black};
          `}

        ${desktop`
        width: 89px;
      `}
      `,
    },
    collapsibleWrapper: css`
      color: ${({ theme }) => theme.colors.palette.black};
      background-color: ${({ theme }) => theme.colors.palette.white};

      border: 1px solid ${({ theme }) => theme.colors.palette.gunmetal20};

      &:first-child {
        border-radius: 6px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        border-top: 1px solid ${({ theme }) => theme.colors.palette.gunmetal20};
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.palette.gunmetal20};
      }

      &:nth-child(2) {
        border-bottom: 0px solid
          ${({ theme }) => theme.colors.palette.gunmetal20};
        border-top: 0px solid ${({ theme }) => theme.colors.palette.gunmetal20};
      }

      &:last-child {
        border-radius: 6px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        border-bottom: 1px solid
          ${({ theme }) => theme.colors.palette.gunmetal20};

        ${({ showBra }) =>
          showBra &&
          css`
            border-top: 1px solid
              ${({ theme }) => theme.colors.palette.gunmetal20};
          `}

        ${({ showBra }) =>
          !showBra &&
          css`
            border-top: none;
          `}
      }
    `,
  },
  iconButtonModal: {
    header: css`
      font-size: 32px;
      font-weight: 700;
    `,
    subheader: css`
      font-size: 16px;
      font-weight: 700;
    `,
    copy: css`
      font-size: 16px;
    `,
  },
  communicationPreferencesToggle: css`
    [data-hidden-input]:checked + && {
      &::before {
        background: ${({ theme }) => theme.colors.palette.gunmetal45};
      }
      &::after {
        background: ${({ theme }) => theme.colors.palette.gunmetal};
      }
    }
  `,
  promoPickerPopup: {
    desktopHeaderFontSize: 60,
    mobileHeaderFontSize: 50,
    desktopSubHeaderFontSize: 24,
    mobileSubHeaderFontSize: 20,
    header: css`
      line-height: ${72.5 / 78};
      font-weight: 700;

      ${mobile`
        line-height: ${72.5 / 60};
      `}
    `,
    subHeader: css`
      line-height: ${29 / 27};
      letter-spacing: -0.02em;
      font-weight: 500;

      ${mobile`
        line-height: ${29 / 24};
      `}
    `,
    signupSubmitButtonText: css``,
    signInButton: css``,
    closeButton: css``,
    loginHeading: css``,
  },
};
